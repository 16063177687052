
import { Notifications, NotificationType } from './notifications'; 

export class MyAccountNotices {
    private static container: Element;

    public static initialize() {
        this.container = document.getElementById("my-account-content");

        if (!this.container) return;

        this.init();
    }

    private static init() {
        this.container.addEventListener("click", e => {
            let target = e.target as Element;
            let isChangeStatus = target.getAttribute("data-change-status");

            if (isChangeStatus) {
                e.preventDefault();

                let url = target.getAttribute("data-url");
                let id = target.getAttribute("data-id");
                let iEle = target.querySelector("i");

                if (iEle) iEle.classList.remove("hidden");
                target.setAttribute("disabled", "disabled");

                let sp = new URLSearchParams();

                sp.append("idAviso", id);

                let fetchReq: RequestInit = {
                    credentials: "same-origin",
                    method: "POST",
                    body: sp
                };

                fetch(url, fetchReq).then<ChangeStatusResponse>(response => {
                    return response.json();
                }).then(data => {
                    if (data.message) {
                        let item = target.closest(".property-item");
                        let transitionEnd = () => {
                            item.removeEventListener("transitionend", transitionEnd);
                            item.remove();
                        };

                        item.addEventListener("transitionend", () => transitionEnd());
                        item.classList.add("fade-out");

                        Notifications.show(data.message, NotificationType.Info);
                    } else {
                        if (iEle) iEle.classList.add("hidden");
                        target.removeAttribute("disabled");

                        Notifications.show(data.error, NotificationType.Danger);
                    }
                }).catch(reason => {
                    if (iEle) iEle.classList.add("hidden");
                    target.removeAttribute("disabled");

                    Notifications.show(reason, NotificationType.Danger);
                });
            }
        });
    }

}

interface ChangeStatusResponse {
    message: string;
    error: string;
}
