
export class CommonHelper {
    static format(format: string, args: any) {
        return format.replace(/{(\d+)}/g, (match, number) => typeof args[number] != "undefined"
            ? args[number]
            : match);
    }

    static guid() {
        return this.S4() + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + this.S4() + this.S4();
    }

    private static S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    static isInternetExplorer(): boolean {
        return !!window.navigator.userAgent.match(/Trident\/7\./);
    }

    static async getImageDimensions(file) {
        let img = new Image();
        img.src = URL.createObjectURL(file);
        await img.decode();
        let width = img.width;
        let height = img.height;
        return {
            width,
            height,
        }
    }
}