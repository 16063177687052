
export class TextArea {
    static container: HTMLElement;

    public static initialize(selector?: string) {
        this.container = selector ? document.getElementById(selector) : document.body;
        this.resizeTextArea();
    }

    public static resizeTextArea() {
        let textAreaArray = document.getElementsByTagName("textarea");
        const lineHeight = 27;

        for (const textAreaItem of Array.from(textAreaArray)) {
            let height = textAreaItem.scrollHeight;

            let lines = Math.round((height - 10) / lineHeight);
            if (height > 60) {
                textAreaItem.style.height = `${(lines * 29)+10}px`;
            } else {
                textAreaItem.style.height = '34px';
            }
            textAreaItem.style.overflowY = "hidden";
        }
    }
}

