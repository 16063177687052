
export class BoardTutorial {

    private get tutorialStepStatusEle(): HTMLElement {
        return document.querySelector("[data-step-status]") as HTMLElement;
    }

    private get tutorialPrevEle(): HTMLElement {
        return document.querySelector("[data-step-prev]") as HTMLElement;
    }

    private get tutorialNextEle(): HTMLElement {
        return document.querySelector("[data-step-next]") as HTMLElement;
    }

    private get tutorialStepCloseEle(): HTMLElement {
        return document.querySelector("[data-step-close]") as HTMLElement;
    }

    get tutorialCurrentStep(): number {
        let current = document.querySelector("[data-step].step-show") as HTMLElement;

        if (current) return parseInt(current.getAttribute("data-step"));

        return null;
    }

    set tutorialCurrentStep(value: number) {
        let steps = document.querySelectorAll("[data-step]") as NodeListOf<HTMLElement>;
        let currentIndex = -1;

        for (var i = 0; i < steps.length; i++) {
            let step = steps.item(i);
            let stepValue = parseInt(step.getAttribute("data-step"));
            let statusEle = this.tutorialStepStatusEle.querySelector(`[data-status="${stepValue}"]`) as HTMLElement;

            if (!statusEle) {
                statusEle = document.createElement("div") as HTMLElement;

                statusEle.setAttribute("data-status", stepValue.toString());
                statusEle.classList.add("tutorial-step");

                this.tutorialStepStatusEle.appendChild(statusEle);
            }

            step.classList.remove("step-show");
            statusEle.classList.remove("tutorial-step-current");

            if (stepValue == value) {
                currentIndex = value;

                step.classList.add("step-show");
                statusEle.classList.add("tutorial-step-current");
            }
        }

        if (currentIndex == 1) {
            this.tutorialPrevEle.style.display = "none";
            this.tutorialNextEle.style.display = "block";
            this.tutorialStepCloseEle.style.display = "none";
        } else if (currentIndex == steps.length) {
            this.tutorialPrevEle.style.display = "block";
            this.tutorialNextEle.style.display = "none";
            this.tutorialStepCloseEle.style.display = "block";
        } else {
            this.tutorialPrevEle.style.display = "block";
            this.tutorialNextEle.style.display = "block";
            this.tutorialStepCloseEle.style.display = "none";
        }
    }

    private get countTutorialSteps(): number {
        let steps = document.querySelectorAll("[data-step]");

        return steps.length;
    }

    init() {
        this.tutorialPrevEle.addEventListener("click", e => {
            e.preventDefault();

            if (this.tutorialCurrentStep > 1) {
                this.tutorialCurrentStep--;
            }
        });

        this.tutorialNextEle.addEventListener("click", e => {
            e.preventDefault();

            if (this.tutorialCurrentStep < this.countTutorialSteps) {
                this.tutorialCurrentStep++;
            }
        });

        this.tutorialCurrentStep = 1;
    }
}
