

export class AdvancedSearchButton {
    static initialize() {
        $(".adv-search-btn").on("click", function (e) {
            const me = $(this);
            if (me.is(".active")) {
                me.removeClass("active");
                $(".main-search-container").removeClass("active");
                setTimeout(() => {
                    $("#map-container.homepage-map").removeClass("overflow");
                }, 0);
            }
            else {
                me.addClass("active");
                $(".main-search-container").addClass("active");
                setTimeout(() => {
                    $("#map-container.homepage-map").addClass("overflow");
                }, 400);
            }
            e.preventDefault();
        });
    }
}