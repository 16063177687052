
import { Common } from "./common";
import { TextArea } from "./common/textarea";
import { Carousels } from "./common/carousels";
import { ImageLazyies } from "./common/image-lazies";
import { Map } from "./common/map";


const sortDropDowns = document.querySelectorAll<HTMLSelectElement>("[data-sort-dropdown]");

export class MyAccountChat {
    private static loadPartial(route: string, loader: HTMLElement, container: HTMLElement, loadingError: HTMLElement, updatedView?: HTMLElement, callback?: () => void) {
        if (route == null || route == "" || route.endsWith("null")) {
            return;
        }

        const headers = new Headers();
        headers.append("X-Requested-With", "XMLHttpRequest");

        fetch(route, { headers }).then(res => {
            if (loader != null) {
                loader.style.display = "none";
            }

            if (res.ok) return res.json();
            throw res.statusText;
        }).then(data => {
            if (data.statusCode === 200) {
                container.innerHTML = data.partial;
                MyAccountChat.initialize(data.updateTargetId);
                TextArea.initialize(data.updateTargetId);
                if (data.updateTargetId == "my-chatlist-content") {
                    Carousels.initialize(data.updateTargetId);
                    ImageLazyies.initialize(data.updateTargetId);
                } else {
                    Common.initialize(data.updateTargetId);
                }

                if (updatedView != null) {
                    updatedView.style.display = "block";
                }

                if (callback != null) {
                    callback();
                }
            } else {
                throw "Error en el json";
            }
        }).catch((e) => {
            console.log(e);
            loadingError.style.display = "block";
        });
    }


    public static initNavigation(_document: HTMLElement) {
        let navLinks: NodeListOf<Element> = _document.querySelectorAll('.chat-pagination-nav > a');
        const container = document.querySelector<HTMLElement>("#my-chatlist-content");
        const loadingError = document.querySelector<HTMLElement>("#my-account-error");
        const loader = document.querySelector<HTMLElement>("#my-account-chatlist-loader");
        const listContact = document.querySelector<HTMLElement>("#list-avisos-contactados");

        for (var i = 0; i < navLinks.length; i++) {
            let linkItem = navLinks[i];
            const url = navLinks[i].getAttribute("href");

            linkItem.addEventListener("click", nav => {

                nav.preventDefault();
                const route = url.replace("/Micuenta", "");
                loader.style.display = "block";
                listContact.style.display = "none";

                this.loadPartial(route, loader, container, loadingError);
                //Obtener vista parcial
            });
        }
    }

    public static initLinks(_document: HTMLElement) {
        const linkToProperty = _document.querySelectorAll<HTMLElement>(".link-to-property");
        const container = document.querySelector<HTMLElement>("#chat-view-window");
        const loadingError = document.querySelector<HTMLElement>("#my-account-error");

        for (var i = 0; i < linkToProperty.length; i++) {
            const link = linkToProperty[i];
            const linkUrl = link.getAttribute("href");
            link.addEventListener("click", l => {
                l.preventDefault();
                l.stopPropagation();
                window.location.href = linkUrl;
            });
        }

        const linkAnunciante = _document.querySelectorAll<HTMLElement>("[data-link-anunciante]");

        const callbackfn = function () {
            const backtoListButton = document.querySelector<HTMLElement>(".chat-detail-back-list");
            if (backtoListButton != null) {
                backtoListButton.style.display = "none";
            }

            const container = document.querySelector<HTMLElement>("[data-location-map]");
            if (container && container != null) {
                const map = new Map(container);

                const latValue = container.getAttribute("data-latitude");
                const lngValue = container.getAttribute("data-longitude");

                const lat = parseFloat(latValue.replace(",", "."));
                const lng = parseFloat(lngValue.replace(",", "."));

                map.init();

                map.setMarker(lat, lng);
                map.setView(lat, lng);
                map.enableZoom = true;
            }

            //Link volver
            const backbutton = document.querySelector<HTMLElement>(".chat-detail-back-message");
            backbutton.addEventListener("click", (e) => {
                e.preventDefault();
                e.stopPropagation();
                const CurrentConversacion = document.querySelector<HTMLElement>("#hidden-selected-conversation");
                if (CurrentConversacion != null) {
                    let idCurrentConversacion = CurrentConversacion.getAttribute("idconversacion");
                    const currentcard = document.querySelector<HTMLElement>(`.card-chat-item[idconversacion="${idCurrentConversacion}"]`)

                    //Ocultar pantalla actual
                    const currentDocument = document.querySelector<HTMLElement>('.chat-view-header-anunciante-detail');
                    if (currentDocument != null) 
                        currentDocument.style.display = "none";
                    
                    if (currentcard != null) currentcard.click();                    
                    else console.log("CurrentCard not found");                    
                }
            });
        }


        for (var j = 0; j < linkAnunciante.length; j++) {
            const link = linkAnunciante[j];

            let idAnunciante = link.getAttribute('data-anunciante-id');

            link.addEventListener("click", l => {
                l.preventDefault();
                l.stopPropagation();

                const loader = document.querySelector<HTMLElement>("#partial-view-chat-loader");
                const chatview = document.querySelector<HTMLElement>(".partial-view-message");
                const chatForm = document.querySelector<HTMLElement>("[data-chat-form-container]");
                const backButton = document.querySelector<HTMLElement>(".chat-detail-back-list");

                let route = "MensajesAnuncianteInfo/" + idAnunciante;
                if (chatview != null) {
                    chatview.style.display = "none";
                }
                if (chatForm != null) {
                    chatForm.style.display = "none";
                }
                if (loader != null) {
                    loader.style.display = "block";
                }
                if (backButton != null) {
                    backButton.style.display = "none";
                }


                this.loadPartial(route, loader, container, loadingError, chatview, callbackfn);
                
            });
        }

        const linkInteresado = document.querySelector<HTMLElement>(".chat-interesado-head");

        if (linkInteresado != null) {
            linkInteresado.addEventListener("click", i => {
                i.preventDefault();
                i.stopPropagation();

                let telefono = linkInteresado.getAttribute("data-interesado-tel");
                let email = linkInteresado.getAttribute("data-interesado-mail");
                const loader = document.querySelector<HTMLElement>("#partial-view-chat-loader");

                if (!email || email == null) email = "0";
                if (!telefono || telefono == null) telefono = "0";

                let route = "MensajeInteresadoInfo/" + email + "/" + telefono;
                const chatview = document.querySelector<HTMLElement>(".partial-view-message");
                const chatForm = document.querySelector<HTMLElement>("[data-chat-form-container]");
                const chatbacklink = document.querySelector<HTMLElement>(".chat-detail-back-list");
                chatview.style.display = "none";
                chatForm.style.display = "none";
                chatbacklink.style.display = "none";

                this.loadPartial(route, loader, container, loadingError, chatview, callbackfn);
            });

        }
    }

    public static initCards(_document: HTMLElement) {
        let chatLinks: NodeListOf<Element> = _document.querySelectorAll(`.card-chat-item`);
        const container = document.querySelector<HTMLElement>("#chat-view-window");
        const loadingError = document.querySelector<HTMLElement>("#my-account-error");

        const callbackfn = function () {
            var swapLink = document.querySelector<HTMLElement>(".chat-detail-back-list-link");        
            if (swapLink.hasAttribute("linked")) return;            

            swapLink.addEventListener("click", e => {               
                e.preventDefault();                    
                swapLink.setAttribute("linked", "true");              

                const chatview = document.querySelector<HTMLElement>("#chat-view-window");
                chatview.classList.add("hide-in-mobile");

                const listView = document.querySelector<HTMLElement>("#my-chatlist-content");
                listView.classList.remove("hide-in-mobile");              

                const title = document.querySelector<HTMLElement>("[data-messages-header]");
                if (title != null) 
                    title.classList.remove("hide-mobile");   
            });
        };

        for (var i = 0; i < chatLinks.length; i++) {
            let link = chatLinks[i];
            const link_idConversacion = chatLinks[i].getAttribute("idconversacion");
            const link_idRemitente = chatLinks[i].getAttribute("idremitente");

            const currentview = document.querySelector<HTMLElement>("[data-chat-empty]");
            let updatedView: HTMLElement;
            if (link_idConversacion != null && link_idRemitente != null) {
                link.addEventListener("click", list => { 
                    const title = document.querySelector<HTMLElement>("[data-messages-header]");
                    if (title != null) 
                        title.classList.add("hide-mobile");
                    
                    const orderContainer = document.querySelector<HTMLElement>(".sorting-container")
                    if (orderContainer != null) {
                        orderContainer.classList.add("hide-header");
                    }
                    const orderContainerMobile = document.querySelector<HTMLElement>(".mobile-sorting-container");
                    if (orderContainerMobile != null) {
                        orderContainerMobile.classList.add("hide-header");
                    }
                    //Oculto boton volver atrás si ya existe
                    const backtolistbutton = document.querySelector<HTMLElement>(".chat-detail-back-list");
                    if (backtolistbutton != null) {
                        backtolistbutton.style.display = "none";
                    }

                    list.preventDefault();
                    const hiddenCurrentvalue = document.querySelector<HTMLElement>("#hidden-selected-conversation");
                    if (hiddenCurrentvalue != null) {
                        hiddenCurrentvalue.setAttribute("idconversacion", link_idConversacion);
                    }
                    let loader = document.querySelector<HTMLElement>("#partial-view-chat-loader");

                    const chatview = document.querySelector<HTMLElement>(".partial-view-message");

                    if (currentview != null) {
                        currentview.style.display = "none";
                        loader = document.querySelector<HTMLElement>("#my-account-chat-loader");
                        updatedView = currentview;
                    }
                    if (chatview != null) {
                        chatview.style.display = "none";
                        loader = document.querySelector<HTMLElement>("#partial-view-chat-loader");
                        updatedView = chatview;
                    }

                    //Oculto o no la pantalla de chat para vista anunciante
                    const anuncianteDetailView = document.querySelector<HTMLElement>(".chat-view-header-anunciante-detail");
                    if (anuncianteDetailView && anuncianteDetailView != null) {
                        anuncianteDetailView.style.display = "none";
                    }

                    if (loader == null) {
                        loader = document.querySelector<HTMLElement>("#chat-view-anunciante-loader");
                    }
                    if (loader != null) {
                        loader.style.display = "block";
                    }

                    let route = "MisMensajesView/" + link_idConversacion + "/" + link_idRemitente;
                    //Pasarle por callback algo para ocultar el boton de ordenar (version mobile)


                    this.loadPartial(route, loader, container, loadingError, updatedView, callbackfn);
                    
                    var conversation = document.querySelector<HTMLElement>(`[data-idconversacion='` + link_idConversacion + `']`);
                    var read = conversation.querySelector<HTMLElement>('[data-chat-status] i');
                    read.classList.remove('basico1-icon-mail_black');
                    read.classList.add('basico1-icon-mail_open');

                    const details = conversation.querySelector<HTMLElement>(".card-chat-details");                   
                    details.classList.remove("card-chat-details__bold");

                    //MOBILE
                    const containerList = document.querySelector<HTMLElement>("#my-chatlist-content");
                    const containerChat = document.querySelector<HTMLElement>("#chat-view-window");
                    containerList.classList.remove("show-in-mobile");
                    containerList.classList.add("hide-in-mobile");

                    containerChat.classList.add("show-in-mobile");
                    containerChat.classList.remove("hide-in-mobile");

                    const chatListCards = document.querySelectorAll<HTMLElement>(".card-chat-item");
                    const clickedCard = document.querySelector<HTMLElement>(`.card-chat-item[idconversacion='${link_idConversacion}'][idremitente='${link_idRemitente}']`);

                    for (var ci = 0; ci < chatLinks.length; ci++) 
                        chatListCards[ci].style.backgroundColor = "rgba(0, 0, 0, 0)";
                    
                    if (clickedCard != null) 
                        clickedCard.style.backgroundColor = "#F4F2F2";                    

                    if (Common.isMobile) {
                        const header = document.querySelector<HTMLElement>("[data-messages-header]");
                        header.classList.add("hide-mobile");
                    }
                });

            }
        }
    }

    public static initialize(selector?: string) {
        const _document = document.getElementById(selector);
        this.initNavigation(_document);
        this.initCards(_document);
        this.initLinks(_document);
    }
}