declare var md5: any;
import { CommonHelper } from "common/common-helper";
import { Common } from "../common";

export class GroovinAds {

    public static urlConversion = "https://ads01.groovinads.com/grv/track/px.os?fgimg={0}&idpixel={1}&goalvalue={2}&idtransaction={3}"; 
    public static urlRetargeting = "https://ads01.groovinads.com/grv/track/bhpx.os?idc={0}&sku={1}&idcategory={2}&fgjs=1&uid={3}"; 
    public static stringFormat = (str: string, ...args: string[]) =>
                                  str.replace(/{(\d+)}/g, (match, index) => args[index] || '')

    /**
    * @param fgimg tipo de elemento 0-img / 1-script.
    * @param idpixel se define en el panel de GroovinAds.
    * @param goalvalue falta definir.
    * @param idtransaction falta definir.
    */
    static pushEventConversionsHome(fgimg = 0, idpixel = 0, goalvalue = 0, idtransaction = 0) {
        let src = GroovinAds.stringFormat(GroovinAds.urlConversion, fgimg.toString(), idpixel.toString(), goalvalue.toString(), idtransaction.toString());
        GroovinAds.pushElement(fgimg, src);
    }

    /**
    * @param fgimg tipo de elemento 0-img / 1-script.
    */
    static pushEventRetargetingHome(fgimg = 0) {
        let src = GroovinAds.stringFormat(GroovinAds.urlRetargeting, "75");
        GroovinAds.pushElement(fgimg, src);
    }

    /**
    * @param fgimg tipo de elemento 0-img / 1-script.
    * @param sku Id del inmueble.
    * @param IdCategory TipoPropiedad-Operacion.
    */
    static pushEventRetargetingFicha(fgimg = 0, sku = '', IdCategory = '', uid = '') {
        let src = GroovinAds.stringFormat(GroovinAds.urlRetargeting, "75", sku.toString(), IdCategory.toString(), uid.toString());
        GroovinAds.pushElement(fgimg, src);
    }

    /**
    * @param fgimg tipo de elemento 0-img / 1-script.
    * @param IdCategory TipoPropiedad-Operacion.
    */
    static pushEventRetargetingList(fgimg = 0, IdCategory = '') {
        let src = GroovinAds.stringFormat(GroovinAds.urlRetargeting, "75", '', IdCategory.toString());
        GroovinAds.pushElement(fgimg, src);
    }

    private static pushElement(fgimg = 0, src) {
        fgimg == 0
            ? document.body.appendChild(GroovinAds.createElementScript(src))
            : document.body.appendChild(GroovinAds.createElementImg(src))
    }

    private static createElementImg(src): HTMLImageElement {
        var img = document.createElement("img");
        img.src = src;
        img.id = "groovinads";
        img.height = 1;
        img.width = 1;
        return img
    }

    private static createElementScript(src): HTMLScriptElement {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        script.id = "groovinads";
        script.text = "";
        return script;
    }
}
