import { Common } from "../common";

declare var grecaptcha: any

export class ReCaptcha {
    private static loaded = false;
    private static eventCollection = {};
    private static widgetCollection = {};
    
    static initialize() {
        if (!this.loaded) {
            this.loaded = true;

            let ret = document.createElement("script") as HTMLScriptElement;

            ret.async = true;
            ret.defer = true;
            ret.src = "https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit";

            document.body.appendChild(ret);
        }
    }

    static addWidget(id: string, widgetId: number): void {
        this.widgetCollection[id] = widgetId;
    }

    static on(event: ReCaptchaEvents, id: string, fn: () => void) {
        let key = `${id}_${event}`;
        this.eventCollection[key] = fn;
    }

    static trigger(id: string, event: ReCaptchaEvents): void {
        let key = `${id}_${event}`;
        let fn = this.eventCollection[key];
        
        if (fn) fn();
    }

    get isSubmit(): boolean {
        let ele = document.getElementById(this.id);
        return ele.hasAttribute("data-submit");
    }

    get widgetId(): number {
        return ReCaptcha.widgetCollection[this.id] as number;
    }

    constructor(public id: string) {
    }

    execute(): void {
        grecaptcha.execute(this.widgetId);
    }

    reset(): void {
        grecaptcha.reset(this.widgetId);
    }

    getResponse() {
        return grecaptcha.getResponse(this.widgetId);
    }

    render(): void {
        let item = document.getElementById(this.id);

        let widgetId = grecaptcha.render(item, {
            'callback': () => ReCaptcha.trigger(item.id, ReCaptchaEvents.Success),
            'expired-callback': () => ReCaptcha.trigger(item.id, ReCaptchaEvents.Expired),
            'error-callback': () => ReCaptcha.trigger(item.id, ReCaptchaEvents.Error)
        });

        ReCaptcha.addWidget(item.id, widgetId);
        ReCaptcha.trigger(item.id, ReCaptchaEvents.Load);

        if (this.isSubmit) {
            let parentForm = item.closest("form") as HTMLFormElement;

            let onSubmit = e => {
                e.preventDefault();
                e.stopImmediatePropagation();

                let isValid = Common.validateForm(parentForm);

                if (isValid) {
                    parentForm.removeEventListener("submit", onSubmit);
                    this.execute();
                }
            };

            ReCaptcha.on(ReCaptchaEvents.Success, item.id, () => {
                let isAsync = parentForm.hasAttribute("data-async");

                if (isAsync) {
                    let submit = Common.createEvent("submit");
                    parentForm.dispatchEvent(submit);

                    this.reset();
                    parentForm.addEventListener("submit", onSubmit);
                } else {
                    parentForm.submit();
                }
            });

            // TODO: Ver que hacer si falla el captcha
            ReCaptcha.on(ReCaptchaEvents.Error, item.id, () => {
                console.log("Recaptcha error");
                parentForm.addEventListener("submit", onSubmit);
            });
            
            ReCaptcha.on(ReCaptchaEvents.Expired, item.id, () => {
                console.log("Recaptcha expired");
                parentForm.addEventListener("submit", onSubmit);
            });

            parentForm.addEventListener("submit", onSubmit);
        }
    }
}

export enum ReCaptchaEvents {
    Success,
    Expired,
    Error,
    Load
}

(<any>window).recaptchaLoaded = () => {
    let eles = document.querySelectorAll("[data-recaptcha]");
    
    for (var i = 0; i < eles.length; i++) {
        let item = eles.item(i);  
        let captcha = new ReCaptcha(item.id);
        captcha.render();        
    }
};
