export class AutocompleteInput {

    static initialize() {        
        this.autocomplete("username", "email");
        this.autocomplete("userphone", "phone");
    }

    static getCookieValue(a: string) {
        var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    static getLocalStorageValue(key: string) {
        return localStorage.getItem(key);
    }

    static autocomplete(keyName: string, selectorName) {
        const key = document.body.getAttribute("data-localKey-" + keyName);
        const value = this.getLocalStorageValue(key);
        const eles = document.querySelectorAll(`[data-autocomplete-${selectorName}]`);

        if (!value || value.trim().length === 0) return;

        for (let i = 0; i < eles.length; i++) {
            const input = eles[i] as HTMLInputElement;
            input.value = value;
        }
    }
}