import { Common } from "./common";
import { TextArea } from "./common/textarea";
import { MyAccountData } from "./my-account-data";
import { MyAccountChat } from "./my-account-chat";
import { MyAccountAlerts } from "./my-account-alerts";

export class MyAccount {
    private static container: Element;
    
    public static initialize() {
        this.container = document.getElementById("my-account-content");
        var homeUrl = "/MiCuenta/Avisos";

        var hidden = $("[data-options]");
        var hasPublish = hidden.data("options-haspublish");

        //Si el sistema no permite publicar, ponemos como urlHome -> Favoritos
        if (hasPublish === "False")
            homeUrl = "/MiCuenta/Favoritos";

        if (!this.container) return;

        this.fakeRadioButtonBehavior();
        this.navigateBehavior();
        this.sidebar();

        // TODO: Que pasa si se vuelve a ejecutar?  
        MyAccountData.initialize();
        MyAccountAlerts.initialize();
        TextArea.initialize();     

        //Set home URL
        var pathname = window.location.pathname;

        if (!!pathname) 
        {
            var params = new URLSearchParams(window.location.search);

            if (params.has("section"))
                pathname = "/MiCuenta/" + params.get("section");                

            if (params.has("returnUrl")) 
                pathname += "?returnUrl=" + encodeURIComponent(params.get("returnUrl"));            
        }

        pathname = pathname == "/MiCuenta" ? homeUrl : pathname;
        history.replaceState(pathname, "", pathname);

        this.routingTo(pathname);   
    }
    

    private static sidebar() {
        const trigger = document.querySelectorAll<HTMLElement>("[data-menu-trigger]");
        const menu = document.querySelector("[data-menu-sidebar]");
        const labels = document.querySelectorAll("[data-menu-label]");
        const links = document.querySelectorAll<HTMLElement>("[data-navigate]");
        const icon = document.querySelector<HTMLElement>("[data-menu-close]");

        links.forEach(l => {
            l.addEventListener("click", () => {
                document.body.classList.remove("sb-expanded");
                labels.forEach(l => l.classList.remove("show"));
                icon.classList.remove("close");
            });
        })

        trigger.forEach(t => {
            t.addEventListener("click", () => {              
                icon.classList.toggle("close");
                document.body.classList.toggle("sb-expanded");

                if (!icon.classList.contains("close"))
                    labels.forEach(l => l.classList.remove("show"))
            });
        }) 
     
        menu.addEventListener("animationend", e => labels.forEach(l => l.classList.add("show")));
    }

    private static fakeRadioButtonBehavior() {
        this.container.addEventListener("click", e => {
            let target = e.target as Element;
            let dataGroup = target.getAttribute("data-group");

            if (dataGroup) {
                let groups = document.querySelectorAll(`[data-group=${dataGroup}]`);

                for (var i = 0; i < groups.length; i++) {
                    let group = groups[i];
                    group.classList.remove("active");
                }
                target.classList.add("active");
            }
        });
    }

    public static routingTo(route: string) {
        const loader = document.querySelector<HTMLElement>("#my-account-loader");
        const loadingError = document.querySelector<HTMLElement>("#my-account-error");
        const container = document.querySelector<HTMLElement>("#my-account-content");      

        container.innerHTML = "";
        loadingError.style.display = "none";
        loader.style.display = "block";

        const navElements = this.getNavElementsFrom(route);
        this.clearActiveNav();
        navElements.map(nav => nav.classList.add("active"));
        
        const headers = new Headers();
        headers.append("X-Requested-With", "XMLHttpRequest");

        fetch(route, { headers }).then(res => {
            loader.style.display = "none";

            if (res.ok) return res.json();
            throw res.statusText;
        }).then(data => {
            if (data.statusCode === 200) {

                container.innerHTML = data.partial;             
                this.eventsHandler(data.updateTargetId);

            } else throw "Error en el json";
            
        }).catch((e) => {
            console.log(e);
            loadingError.style.display = "block";
        });
    }

    private static eventsHandler(id: string) {
        Common.initialize(id);
        Common.reloadFormValidator();
        MyAccountChat.initialize(id);    
    }

    private static getNavElementsFrom(route: string): HTMLElement[] {
        const navigates = document.querySelectorAll<HTMLAnchorElement>("[data-navigate]");
        const url = this.getRelativeUrl(route);
        let ret: HTMLElement[] = [];
        
        for (var i = 0; i < navigates.length; i++) {
            const item = navigates.item(i);
            const itemUrl = this.getRelativeUrl(item.href);

            if (url.startsWith(itemUrl)) ret.push(item);
        }
        
        return ret;
    }

    private static getRelativeUrl(url: string): string {
        return url.startsWith("/") ? url : new URL(url).pathname;
    }

    private static clearActiveNav() {
        const navigates = document.querySelectorAll<HTMLAnchorElement>("[data-navigate]");        
        Array.from(navigates)            
            .map(nav => nav.classList.remove("active"));
    }

    public static triggerCurrentNavMenu() {
        this.routingTo(window.location.pathname);
    }
    
    private static navigateBehavior() {
        const menuMobile = document.querySelector<HTMLElement>("[data-menu-mobile]");
        const overlayEle = document.querySelector("body > .overlay") as HTMLElement;

        document.addEventListener("click", e => {       
            const target = e.target as HTMLElement;
            const isNavigate = target.hasAttribute("data-navigate");              

            if (isNavigate) {
                e.preventDefault();
                const href = target.getAttribute("href");

                history.pushState(null, null, href);
                this.routingTo(href);

                window.scrollTo(null, 0);
                if (menuMobile) menuMobile.classList.remove("show");
                if (overlayEle) overlayEle.classList.remove("show");
                document.body.style.overflow = "";
            }
        });
        
        window.addEventListener("popstate", () => this.routingTo(window.location.pathname));
    }     
}
