import { MyAccount } from "./my-account";
import { Popup } from "./common/popup";
import { CustomSelect } from "./common/custom-select";
import { Notifications, NotificationType } from "./notifications";

export class MyAccountAlerts {
    private static container: Element;
    
    private static getHiddenValue(id: string): string {
        let hidden = document.getElementById(id) as HTMLInputElement;

        if (hidden) return hidden.value;

        return null;
    }

    private static getCurrentItemFromChild(ele: HTMLElement): HTMLElement {
        return ele.closest("[data-alert-item]") as HTMLElement;
    }

    private static getAlertName(ele: HTMLElement): string {
        let alertNameEle = this.getCurrentItemFromChild(ele)
            .querySelector("[data-alert-name]");

        if (alertNameEle) return alertNameEle.textContent.trim();

        return null;
    }

    private static setAlertName(ele: HTMLElement, value: string) {
        let alertNameEle = this.getCurrentItemFromChild(ele)
            .querySelector("[data-alert-name]");

        if (alertNameEle) alertNameEle.textContent = value;
    }

    private static showLoading(ele: HTMLElement, value: boolean) {
        let loadingEle = this.getCurrentItemFromChild(ele)
            .querySelector("[data-alert-loader]") as HTMLElement;

        if (loadingEle) loadingEle.style.display = value ? "block" : "none";
    }

    public static initialize(selector?: string) {
        let oldForm: Node;
        this.container = selector ? document.getElementById(selector) : document.body;        
        this.container.addEventListener("click", e => {
            const target = e.target as HTMLElement;
            const deleteAlert = target.getAttribute("data-delete-alert");
            const customSelect = target.classList.contains("custom-select");

            if (customSelect) {
                const form = target.closest<HTMLFormElement>("form");
                oldForm = form?.cloneNode(true);
            }

            if (deleteAlert) {
                e.preventDefault();
                this.deleteAlert(target, deleteAlert);
            }                   
        });

        this.container.addEventListener("change", e => {
            const select = e.target as HTMLSelectElement;
            const form = select.closest<HTMLFormElement>("form");

            if (!form) return;
            if (!form.hasAttribute("data-form-alert")) return;

            const activa = form.querySelector<HTMLInputElement>('input[name="AlertaActiva"]');

            const target = select.options[select.selectedIndex];
            const isUpdate = target.hasAttribute("data-update-alert");
            const isDisabled = target.hasAttribute("data-update-disable");

            if (isDisabled) {
                select.setAttribute("disabled", "disabled");
                const id = form.getAttribute("id");

                this.toggleAlert(id, false, () => {
                    activa.value = "false";

                    target.removeAttribute("data-update-disable");
                    target.setAttribute("data-update-enable", "");
                    target.text = "Desactivada";

                    select.removeAttribute("disabled");
                });
            }

            if (isUpdate) {                
                const url = form.action;
                const newValue = target.getAttribute("data-update-alert");
                const input = form.querySelector<HTMLInputElement>('input[name="IdTipoBusqueda"]');                

                input.value = newValue;
                select.setAttribute("disabled", "disabled");              
              
                fetch( url,
                    {
                        method: "POST",
                        credentials: "same-origin",
                        body: new FormData(form)
                    })
                    .then<AlertResponse>(response => {
                        if (response.ok)
                            return response.json();
                    })
                    .then(res => {
                        if (res && res.success) {
                            Popup.showAlert(res.title, res.message);
                            activa.value = "true";
                            select.removeAttribute("disabled");

                            const option = form.querySelector<HTMLOptionElement>("[data-update-enable]");
                            if (!option) return;

                            option.text = "Desactivar";
                            option.removeAttribute("data-update-enable");
                            option.setAttribute("data-update-disable", "");
                        }
                        else {
                            if (res.limitAlertas)
                                Popup.showAlert(res.title, res.message);

                            const content = form.closest<HTMLElement>("[data-search-item-form]");
                            content.innerHTML = "";
                            content.appendChild(oldForm);

                            const custom = content.querySelector(".custom-select-container");
                            custom.remove();
                            CustomSelect.initialize();
                        }
                    });
            } 
        });
    }

    private static toggleAlert(id: string, value: boolean, cb: () => void): void {
        const data = new FormData();
        const toggleAlertUrl = this.getHiddenValue("toggleAlertUrl");
        data.append("id", id);
        data.append("enable", String(value));

        fetch(toggleAlertUrl,
        {
            method: "POST",
            credentials: "same-origin",
            body: data
        })
        .then<AlertResponse>(response => 
        {
            if (response.ok)
                return response.json();
        })
        .then(res =>
        {
            if (res && res.success) {
                Popup.showAlert(res.title, res.message);
                cb();              
            }              
            else 
            {    
                if (res.limitAlertas)
                    Popup.showAlert(res.title, res.message);
                else
                    Notifications.show(res.message, NotificationType.Danger);				
            }
        });
    }

    private static deleteAlert(ele: HTMLElement, id: string): void {
        const deleteConfirm = () => {
            this.showLoading(ele, true);

            const data = new FormData();
            data.append("id", id);

            const url = this.getHiddenValue("deleteAlertUrl");              

            fetch(url, {
                method: "POST",
                credentials: "same-origin",
                body: data
            }).then<CommonResponse>(response => {
                if (response.ok) return response.json();               
            }).then(res => {
                this.showLoading(ele, false);

                if (res && res.success) {
                    const item = this.getCurrentItemFromChild(ele);
                    item.remove();
                    const list = document.querySelectorAll("[data-alert-item]");
                    if (list.length > 0) return;

                    const show = document.querySelector<HTMLElement>("[data-show-empty]");
                    const hide = document.querySelector<HTMLElement>("[data-hide-empty]");

                    show.classList.remove("hide");
                    hide.classList.add("hide");                                   
                } 
            });
        };

        const name = this.getAlertName(ele);
        const title = this.getHiddenValue("deleteAlertTitle");
        const btnText = this.getHiddenValue("deleteAlertButtonText");

        let description = this.getHiddenValue("deleteAlertDescripcion");
        description = description.replace("{0}", "\"" + name + "\"");
     
        Popup.showConfirm(title, description, btnText, deleteConfirm);
    }
}

class CommonResponse {
    success: boolean;
}

class AlertResponse extends CommonResponse {
    title: string;
    message: string;
    limitAlertas: boolean;
}
