import { Autocomplete } from "./common/autocomplete";

export class AgentTasacion {
    private static idCalle: HTMLInputElement;
    private static direccionCalle: HTMLInputElement;
    private static localidad: HTMLInputElement;

    static initialize() {
        const tasacionPage = document.querySelector("[data-agent-tasacion]");
        if (!tasacionPage) return;
        this.initAutocomplete();
    }

    private static initAutocomplete(): void {
        this.idCalle = document.querySelector("#Direccion_IdCalle") as HTMLInputElement;
        this.direccionCalle = document.querySelector("[data-nombre-calle]") as HTMLInputElement;
        this.localidad = document.querySelector("#Direccion_IdLocalidad") as HTMLInputElement;

        if (!this.direccionCalle) return;

        let autocompleteUrl = this.direccionCalle.getAttribute("data-autocomplete-url");

        new Autocomplete(this.direccionCalle, {
            url: autocompleteUrl,
            autoSelect: false,
            highlight: true,
            dataText: "Nombre",
            dataValue: "Id",
            dataParameters: text => {
                let localidadId = this.localidad.value;

                if (!localidadId || !text) return;

                return {
                    "localidadId": localidadId,
                    "nombreCalle": text
                }
            },
            onSelect: (optionSelected) => {
                this.idCalle.value = optionSelected.id;
            },
            onSelectCustomText: (text: string) => {
                this.idCalle.value = null;
            }
        });
    }
}