
export class ShareUtils {
    static initialize() {
        let shareFacebook = document.querySelector("[data-share-facebook]");

        if (!shareFacebook) return;

        shareFacebook.addEventListener("click", e => {
            FB.ui({
                method: 'share',
                href: window.location.href,
            }, (response) => { });
        });
    }
}
