import { Popup, PopupEvents } from "./common/popup";
import { Footer } from "./common/footer";
import { ImageLazyies } from "./common/image-lazies";

export class MyAccountProperty {
    static tabs: HTMLElement;
    static container: HTMLElement;
    static tableContent: HTMLElement;

    static pausePopup: Popup;
    static deletePopup: Popup;
    static deleteForeverPopup: Popup;

    static currentIdAviso: number = 0;

    static abortController = new AbortController();

    public static initialize() {
        this.container = document.querySelector<HTMLElement>("#my-account-content");           
        const page = document.querySelector<HTMLElement>("[data-myprops-page]");           

        if (!page) return;

        this.loadData();

        this.pausePopup = new Popup("PausePopup");
        this.deletePopup = new Popup("DeletePopup");
        this.deleteForeverPopup = new Popup("DeleteForeverPopup");    

        const deletePopupEventDelegate = (type: PopupEvents, data: any) => {
            if (type == PopupEvents.Show) {
                const error = this.deletePopup.content.querySelector("[data-popup-error]");
                error.classList.remove("show");
            }
        }

        const pausePopupEventDelegate = (type: PopupEvents, data: any) => {
            if (type == PopupEvents.Show) {
                const error = this.pausePopup.content.querySelector("[data-popup-error]");
                error.classList.remove("show");
            }
        }

        const deleteForeverEventDelegate = (type: PopupEvents, data: any) => {
            if (type == PopupEvents.Show) {
                const error = this.deleteForeverPopup.content.querySelector("[data-popup-error]");
                error.classList.remove("show");          
            }          
        }

        this.pausePopup.event((t, e, d) => pausePopupEventDelegate(t, d));
        this.deletePopup.event((t, e, d) => pausePopupEventDelegate(t, d));
        this.deleteForeverPopup.event((t, e, d) => deleteForeverEventDelegate(t, d));

        if (!this.container.hasAttribute("prop-binds")) {
            document.addEventListener("click", (e: Event) => {
                const target = e.target as HTMLElement;

                const callWhenAttr = (attrs: string[], fn: () => void) => {
                    const has = attrs.some(attr => target.hasAttribute(attr));
                    if (!has) return;

                    e.preventDefault();
                    fn();
                };

                const tabs = [
                    "data-tab-publicado",
                    "data-tab-nopublicados",
                    "data-tab-eliminados"
                ];

                callWhenAttr(tabs, () => this.handleTabClick(target as HTMLButtonElement));

                callWhenAttr(["data-menu-pause"], () => this.showConfirm(this.pausePopup, target));
                callWhenAttr(["data-menu-delete"], () => this.showConfirm(this.deletePopup, target));
                callWhenAttr(["data-menu-delete-forever"], () => this.showConfirm(this.deleteForeverPopup, target));

                callWhenAttr(["data-action"], () => this.handleActionClick(target as HTMLButtonElement)); 
                callWhenAttr(["data-change-page"], () => this.handleChangePageClick(target as HTMLButtonElement));
                callWhenAttr(["data-pause"], () => this.handleCommonActionClick(this.pausePopup, "PauseActionUrl", target as HTMLButtonElement, this.currentIdAviso));
                callWhenAttr(["data-delete"], () => this.handleCommonActionClick(this.deletePopup, "DeleteActionUrl", target as HTMLButtonElement, this.currentIdAviso));
                callWhenAttr(["data-delete-forever"], () => this.handleCommonActionClick(
                    this.deleteForeverPopup, "DeleteForeverActionUrl", target as HTMLButtonElement, this.currentIdAviso));

                callWhenAttr(["data-activate"], () => {
                    const idAviso = parseInt(target.getAttribute("data-id"));
                    this.handleActivateClick(target as HTMLButtonElement, idAviso)
                });

                callWhenAttr(["data-restore"], () => {
                    const idAviso = parseInt(target.getAttribute("data-id"));
                    this.handleRestoreClick(target as HTMLButtonElement, idAviso);
                });

                const isBtnAction = target.hasAttribute("data-action");
                const inActionMenu = target.closest("[data-action-menu]") !== null;
                if (!isBtnAction && !inActionMenu) this.hideActionMenus();
            })
        }

        this.container.setAttribute("prop-binds", "false");            
    }

    private static loadData() {
        this.tabs = this.container.querySelector<HTMLElement>("[data-tabs]");
        this.tableContent = this.container.querySelector<HTMLElement>("[data-table-content]");

        const activeBox = this.tabs.querySelector<HTMLElement>(".active");
        const stepBox = activeBox.querySelector(".step-box");
        const loader = this.tableContent.querySelector<HTMLElement>("[data-loader-box]");
        let fetchUrl = stepBox.getAttribute("data-fetch-url");

        if (loader?.classList.contains("show")) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }

        if (loader)
            loader.classList.add("show");

        const sp = new URLSearchParams(window.location.search);
        const page = sp.get("page");

        if (page) {
            const newUrl = new URL(fetchUrl, window.location.origin);
            newUrl.searchParams.set("page", page);
            fetchUrl = newUrl.toString();
        }

        try {
            fetch(fetchUrl, { signal: this.abortController.signal })
                .then(res => res.text())
                .then(data => {
                    this.tableContent.innerHTML = data;                   
                    ImageLazyies.initialize('my-account-content');
                    const publicados = this.tableContent.querySelector<HTMLInputElement>("#PublicadosCount");
                    const noPublicados = this.tableContent.querySelector<HTMLInputElement>("#NoPublicadosCount");
                    const eliminados = this.tableContent.querySelector<HTMLInputElement>("#EliminadosCount");

                    this.setTotalItems(publicados.value, noPublicados.value, eliminados.value);
                    Footer.adjustFooter();
                })            
        } catch {}
    }

    private static handleChangePageClick (target: HTMLButtonElement) {
        const page = parseInt(target.getAttribute("data-change-page"));

        this.setPage(page);
        this.loadData();
    };

    private static setPage (value?: number) {
        const url = value ? `?page=${value}` : null;
        const state = window.history.state;

        window.history.pushState(state, null, url);
    };

    private static setTotalItems(publicados: string, noPublicados: string, eliminados: string) {
        const setCount = (id: string, value: string) => {
            const tabEle = this.tabs.querySelector<HTMLElement>(`[data-${id}]`);
            const count = tabEle.querySelector<HTMLElement>("[data-count]");

            count.textContent = value;
        };

        setCount("tab-publicado", publicados);
        setCount("tab-nopublicados", noPublicados);
        setCount("tab-eliminados", eliminados);
    };


    private static handleActivateClick(target: HTMLButtonElement, idAviso: number) {
        const urlHidden = this.container.querySelector<HTMLInputElement>("#ActivateActionUrl");
        const url = urlHidden.value;

        this.hideActionMenus();

        const formData = new FormData();
        formData.append("idAviso", idAviso.toString());

        fetch(url, {
            method: "POST",
            body: formData
        }).then(res => {
            if (res.ok) this.loadData();
        });
    };

    private static handleTabClick(target: HTMLButtonElement) {  

        const tab = target.getAttributeNames()
            .find(x => x.startsWith("data-tab"));

        this.setActiveTab(tab)

        const dataUrl = target.getAttribute("data-url");
        window.history.pushState({ activeTab: tab }, null, dataUrl);
        this.loadData();
    };

    private static getActiveTab() {
        const li = this.tabs.querySelector<HTMLElement>(".active");
        const stepBox = li.querySelector(".step-box");

        return stepBox.getAttributeNames()
            .find(x => x.startsWith("data-tab"))
    };

    private static setActiveTab(id: string) {
        const activeBox = this.tabs.querySelector<HTMLElement>(".active");
        const stepBox = activeBox.querySelector(".step-box");
      
        activeBox.classList.remove("active");
        stepBox.removeAttribute("disabled");
       
        const tab = this.tabs.querySelector<HTMLElement>(`[${id}]`);

        tab.setAttribute("disabled", "");
        tab.parentElement.classList.add("active"); // El li padre
    };

    private static hideActionMenus() {
        const actionMenu = this.container.querySelectorAll<HTMLElement>("[data-action-menu]");
        actionMenu.forEach(ele => ele.classList.remove("show"));
    };

    private static handleActionClick(target: HTMLButtonElement) {
        this.hideActionMenus();

        const actionMenu = target.parentElement.querySelector<HTMLElement>("[data-action-menu]");
        actionMenu.classList.add("show");
    };

    private static showConfirm(popup: Popup, target: HTMLElement) {
        const row = target.closest("[data-row]");
        const addressEle = row.querySelector<HTMLElement>("[data-address]");
        const titleEle = popup.content.querySelector<HTMLInputElement>(`#${popup.id}Title`);

        const address = addressEle.textContent
            .replace("-", "") // En los casos donde no hay direccion
            .trim();
        const title = titleEle.value
            .replace("{0}", address);

        this.currentIdAviso = parseInt(target.getAttribute("data-id"));

        this.hideActionMenus();
        popup.title = title;
        popup.show();
    };

    private static handleCommonActionClick (popup: Popup, idUrl: string, target: HTMLButtonElement, idAviso: number) {
        const urlHidden = this.container.querySelector<HTMLInputElement>(`#${idUrl}`);
        const url = urlHidden.value;

        target.classList.add("btn-loading");

        const formData = new FormData();
        formData.append("idAviso", idAviso.toString());

        fetch(url, {
            method: "POST",
            body: formData
        }).then(res => {            
            target.classList.remove("btn-loading");

            if (res.ok) {
                popup.close();
                this.loadData();
            } else {
                const error = popup.content.querySelector("[data-popup-error]");
                error.classList.add("show");
            }
        });
    };

    private static handleRestoreClick (target: HTMLButtonElement, idAviso: number) {
        const urlHidden = this.container.querySelector<HTMLInputElement>("#RestoreActionUrl");
        const url = urlHidden.value;

        this.hideActionMenus();

        const formData = new FormData();
        formData.append("idAviso", idAviso.toString());

        fetch(url, {
            method: "POST",
            body: formData
        }).then(res => {
            if (res.ok) this.loadData();
        });
    };
}