

export class Tooltips {
    static initialize() {
        $(".tooltip.top").tipTip({
            defaultPosition: "top"
        });
        $(".tooltip.bottom").tipTip({
            defaultPosition: "bottom"
        });
        $(".tooltip.left").tipTip({
            defaultPosition: "left"
        });
        $(".tooltip.right").tipTip({
            defaultPosition: "right"
        });

        // Tooltip submit
        $(".tip").each(() => {
            const tipContent = $(this).attr("data-tip-content");
            $(this).append(`<div class="tip-content">${tipContent}</div>`);
        });

    }
}