
export class DynamicTagManager {

    static initialize() {
        let url = document.body.getAttribute("data-dtm-url");

        if (url) {
            new DynamicTagManager(url);
        }
    }

    constructor(private url: string) {
        fetch(url, { method: "GET" }).then(response => {
            if (response.ok) return response.json();
            return [];
        }).then((response: DynamicTagManagerInfo[]) => {
            if (!response) return;

            for (var i = 0; i < response.length; i++) {
                let item = response[i];

                this.track(item);
            }
        });
    }

    private track(info: DynamicTagManagerInfo): void {
        let container = document.querySelectorAll(info.ContainerSelector);

        if (container.length == 0) return;

        // configuration of the observer:
        let config: MutationObserverInit = {
            childList: true,
            subtree: true
        };

        for (var i = 0; i < container.length; i++) {
            let containerItem = container.item(i);
            let observer = this.createObserver(containerItem, info);

            // pass in the target node, as well as the observer options
            observer.observe(containerItem, config);
        }
    }

    private createObserver(ele: Element, info: DynamicTagManagerInfo) {
        let ret = new MutationObserver(mutations => {
            let target = ele.querySelector(info.ElementSelector);

            if (target) {
                ret.disconnect();

                this.bindEvent(target, info);
            }
        });

        return ret;
    }

    private bindEvent(ele: Element, info: DynamicTagManagerInfo) {
        ele.addEventListener("click", () => {
            let parser = new DOMParser();
            let contentParsed = parser.parseFromString(info.Content, "text/html");
            let child = contentParsed.body.firstChild;
            
            document.body.appendChild(child);
        });
    }
    
}

interface DynamicTagManagerInfo {
    ContainerSelector: string;
    Content: string;
    ElementSelector: string;
}
