declare var md5: any;
import { CommonHelper } from "common/common-helper";
import { Common } from "../common";

export class Criteo {

    static pushEventHome() {
        if (!this.account)
            return;

        let w = <any>window;
        w.criteo_q = w.criteo_q || [];
        var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
        w.criteo_q.push(
            { event: "setAccount", account: this.account },
            { event: "setEmail", email: this.email },
            { event: "setSiteType", type: deviceType },
            { event: "viewHome" },
        );
    }

    static pushEventItem(id) {
        if (!this.account)
            return;

        let w = <any>window;
        w.criteo_q = w.criteo_q || [];
        var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
        w.criteo_q.push(
            { event: "setAccount", account: this.account },
            { event: "setEmail", email: this.email },
            { event: "setSiteType", type: deviceType },
            { event: "viewItem", item: id },
        );
    }

    static pushEventList(ids: Array<string>) {
        if (!this.account) return;

        let w = <any>window;
        w.criteo_q = w.criteo_q || [];
        var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
        w.criteo_q.push(
            { event: "setAccount", account: this.account },
            { event: "setEmail", email: this.email },
            { event: "setSiteType", type: deviceType },
            { event: "viewList", item: ids },
        );
    }

    static pushEventTrackTransaction(idAviso, price) {
        if (!this.account)
            return;

        let w = <any>window;
        w.criteo_q = w.criteo_q || [];
        var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
        w.criteo_q.push(
            { event: "setAccount", account: this.account },
            { event: "setEmail", email: this.email },
            { event: "setSiteType", type: deviceType },
            {
                event: "trackTransaction", id: CommonHelper.guid(), item: [
                    { id: idAviso, price: price, quantity: 1 }
                ]
            },
        );
    }

    private static get dataCriteo(): HTMLElement {
        return document.querySelector("[data-criteo]") as HTMLElement;
    }

    private static get account(): string {
        return this.dataCriteo?.getAttribute("data-criteo-account");
    }

    private static get email(): string {
        var localStorageUserName = document.body.getAttribute('data-localKey-username');

        if (localStorageUserName) {
            var userName = Common.getKeyToLocalStorage(localStorageUserName);
            if (userName)
                return md5(userName);
        }

        return '';
    }

    private static getCookieValue(a: string) {
        var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }
}
