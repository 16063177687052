import { simplifyGeometry } from './simplify-geometry/index';

export class MapDrawLayer {
    private canvasLayer: L.Canvas;
    private canDraw: boolean;
    private points: L.LatLng[];
    private lastPoint: L.LatLng;

    drawStyle: L.PathOptions;
    polygonStyle: L.PathOptions;
    onStart: () => void;
    onEnd: (drawPoints: L.LatLng[]) => void;

    constructor(public map: L.Map) {
    }

    init(): void {
        this.points = [];

        this.canvasLayer = L.canvas();
        this.map.addLayer(this.canvasLayer);
        this.enableDraw = true;
    }

    destroy(): void {
        this.points = null;

        this.enableDraw = false;
        this.map.removeLayer(this.canvasLayer);
        this.canvasLayer = null;
    }

    clear(): void {
        this.points = [];

        this.map.removeLayer(this.canvasLayer);
        this.canvasLayer = L.canvas();
        this.map.addLayer(this.canvasLayer);
    }

    private set enableDraw(value: boolean) {
        if (value) {
            this.map.dragging.disable();
            this.map.scrollWheelZoom.disable();
            this.map.doubleClickZoom.disable();

            this.map.on("mousedown", this.mousedown, this);
            this.map.on("mousemove", this.mousemove, this);
            this.map.on("mouseup", this.mouseup, this);
        } else {
            this.map.dragging.enable();
            this.map.scrollWheelZoom.enable();
            this.map.doubleClickZoom.enable();

            this.map.off("mousedown", this.mousedown, this);
            this.map.off("mousemove", this.mousemove, this);
            this.map.off("mouseup", this.mouseup, this);
        }
    }

    private mousedown(e: L.MouseEvent) {
        this.canDraw = true;

        this.lastPoint = e.latlng;
        this.points.push(e.latlng);

        if (this.onStart) this.onStart();
    }

    private mousemove(e: L.MouseEvent) {
        if (!this.canDraw) return;

        this.points.push(e.latlng);

        let style: L.PathOptions = {
            renderer: this.canvasLayer,
            color: '#999',
            weight: 2
        };

        L.Util.setOptions(style, this.drawStyle);

        let line = L.polyline([this.lastPoint, e.latlng], style);

        this.lastPoint = e.latlng;

        line.addTo(this.map);
    }

    private mouseup(e: L.MouseEvent) {
        if (!this.canDraw) return;
        this.canDraw = false;

        // Agrego el ultimo punto para cerrar la figura con el inicio
        this.points.push(this.points[0]);

        // Obtengo una figura concava
        let concaveHull = new ConcaveHull(this.points);
        let latlngs = concaveHull.getLatLngs();

        let clltp = latlngs.map(l => [l.lat, l.lng]);

        // Simplifico la figura concava
        let simpleGeometry = simplifyGeometry(clltp, .0005);
        let polygonLatLngs = simpleGeometry.map(a => L.latLng(a[0], a[1]));

        this.clear();

        // Dibujo el poligono con la figura simplificada
        let style: L.PathOptions = {
            renderer: this.canvasLayer,
            color: '#999',
            weight: 2,
            fillColor: '#000',
            fillOpacity: .1,
            interactive: false
        };

        L.Util.setOptions(style, this.polygonStyle);

        let polygon = L.polygon(polygonLatLngs, style);

        polygon.addTo(this.map);

        if (this.onEnd) {
            this.onEnd(polygonLatLngs);
        }

        this.enableDraw = false;
    }
}
