import { Common } from "./common";
import { Notifications } from "./notifications";

export class AjaxBehaviour {
    private static xhrPool: Array<any> = [];

    static initialize() {

        $(document).ajaxComplete((event, xhr, settings) => {
            var i = AjaxBehaviour.xhrPool.indexOf(xhr);   //  get index for current connection completed
            if (i > -1) this.xhrPool.splice(i, 1); //  removes from list by index

            if (xhr && xhr.responseText) {
                // Parseo
                var response = JSON.parse(xhr.responseText);

                // Si nos mandaron una partial la inserto
                if (response.partial) {

                    $(`#${response.updateTargetId}`).html(response.partial);
                    Common.initialize(response.updateTargetId);
                    Common.reloadFormValidator();                    
                    document.dispatchEvent(new CustomEvent("partialAjaxComplete"));

                } else if (response.redirectToAction) {
                    window.location.href = response.redirectToAction;
                } else if (response.notification) {                     
                    document.dispatchEvent(new CustomEvent("notificationAjaxComplete"));
                    const notificationType = Notifications.getNotificationType(response.notification.Type);
                    Notifications.show(response.notification.Message, notificationType);  
                }
            }
        });

        $(document).ajaxSend((event, jqXHR, ajaxOptions) => {
            this.xhrPool.push(jqXHR);
        });

        // Necesito export funciones globales para poder usarlas desde MVC y usar comportamientos genericos para ajax
        var ajaxGenerics = {
            onBegin: (containerId, loaderId, errorContainerId) => {
                $(`#${containerId}`).hide();
                $(`#${errorContainerId}`).hide();
            },
            onSuccess: (data, status, xhr, containerId) => {
                if (!data.redirectToAction) {
                    $(`#${containerId}`).removeClass("hide").show();
                }
            },
            onFailure: (containerId) => {
                $(`#${containerId}`).removeClass("hide").show();
            }
        };

        (<any>window).ajaxGenerics = ajaxGenerics;

        $("[data-ajax-partial]").each(function () {
            const me = $(this);

            const url = me.data("ajax-partial");
            const loader = me.data("ajax-loader");
            me.html(loader);

            $.getJSON(url).done((data) => {
                me.html(data.partial);
            }).fail(() => {
                me.html("");
            });
        });

    }
    
    public static abortAll() {
        AjaxBehaviour.xhrPool.forEach((jqXHR: JQueryXHR, i: number) => {   //  cycle through list of recorded connection
            jqXHR.abort();  //  aborts connection
            AjaxBehaviour.xhrPool.splice(i, 1); //  removes from list by index
        });
    }
}