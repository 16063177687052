
import { Common } from "../common";
import { EventManager } from "./event-manager";

export class Popup {
    private eventCallback: (type: PopupEvents, e: Event, data: any) => void;
    private eventManager: EventManager;
    
    fetching: boolean;

    static showAlert(title: string, message: string): void {
        let popup = new Popup("genericAlertPopup");
        let para = popup.ele.querySelector("[data-popup-message]")

        popup.title = title;
        para.textContent = message;

        popup.show();
    }

    static showConfirm(title: string, message: string, buttonText: string, confirm: () => void) {
        let popup = new Popup("genericConfirmPopup");
        let para = popup.ele.querySelector("[data-popup-message]")
        let buttonSubmit = popup.ele.querySelector("[data-popup-button-confirm]");

        popup.title = title;
        para.textContent = message;
        buttonSubmit.textContent = buttonText;

        popup.show();

        popup.event((type, e) => {
            if (type == PopupEvents.Submit) {
                confirm && confirm();
                popup.close();
            }
        });
    }

    static showCancelConfirm(title: string, message: string, buttonText: string, confirm: () => void) {
        let popup = new Popup("genericCancelConfirmPopup");
        let para = popup.ele.querySelector("[data-popup-message]")
        let buttonSubmit = popup.ele.querySelector("[data-popup-button-confirm]");

        popup.title = title;
        
        para.textContent = message;
        buttonSubmit.textContent = buttonText;

        popup.show();

        popup.event((type, e) => {
            if (type == PopupEvents.Submit) {
                confirm && confirm();
                popup.close();
            }
        });
    }

    constructor(public id: string) {
        this.eventManager = new EventManager();
    }

    destroy(): void {
        this.close();

        if (this.ele) this.ele.parentElement.removeChild(this.ele);
    }

    event(callback: (type: PopupEvents, e: Event, data?: any) => void): void {
        this.eventCallback = callback;
    }


    show(withOverlay: boolean = true): void {
        if (this.ele) {
            if (this.stickySelector) {
                this.manageSticky();
            }

            if (withOverlay) {
                this.overlayEle.classList.add("show");
            }

            if (this.ele.style.display == "none" || this.ele.style.display == "") {

                this.ele.style.display = "block";

                this.bind();

                this.trigger(PopupEvents.Show, null);

                let eleFocusable = this.ele.querySelector("[autofocus]") as HTMLElement;

                if (eleFocusable) eleFocusable.focus();
            }
        }
    }

    close(): void {
        if (this.ele) {
            this.ele.style.display = "none";
            this.overlayEle.classList.remove("show");

            this.trigger(PopupEvents.Close, null);
            this.unbind();
        }
    }

    get title(): string {
        if (this.titleEle) return this.titleEle.textContent;

        return null;
    }

    set title(value: string) {
        if (this.titleEle) this.titleEle.textContent = value;
    }

    get url(): string {
        if (this.formEle) return this.formEle.action;

        return null;
    }

    set url(value: string) {
        if (this.formEle) this.formEle.action = value;
    }
    
    get isAsync(): boolean {
        if (this.formEle) {
            return this.formEle.getAttribute("data-async") == "true";
        }

        return false;
    }

    set isAsync(value: boolean) {
        if (this.formEle) {
            this.formEle.setAttribute("data-async", value ? "true" : "false");
        }
    }

    get resetForm(): boolean {
        if (this.ele) {
            return this.ele.getAttribute("data-reset-form") == "true";
        }

        return false;
    }

    set resetForm(value: boolean) {
        this.ele.setAttribute("data-reset-form", value ? "true" : "false");        
    }

    get content(): HTMLElement {
        return this.ele.querySelector(".popup-content") as HTMLElement;
    }

    get containerSelector(): string {
        if (this.formEle) {
            return this.formEle.getAttribute("data-container");
        }

        return null;
    }

    set containerSelector(value: string) {
        if (this.formEle) {
            this.formEle.setAttribute("data-container", value);
        }
    }

    get insertionMode(): InsertionMode {
        if (this.formEle) {
            let value = this.formEle.getAttribute("data-insertion");

            switch (value) {
                case "replace": return InsertionMode.Replace;
                case "append": return InsertionMode.Append;
            }
        }

        return null;
    }

    set insertionMode(value: InsertionMode) {
        if (this.formEle) {
            let stringValue = "";

            switch (value) {
                case InsertionMode.Replace:
                    stringValue = "replace";
                    break;
                case InsertionMode.Append:
                    stringValue = "append";
                    break;
            }

            this.formEle.setAttribute("data-insertion", stringValue);
        }
    }

    get stickySelector(): string {
        if (this.ele) {
            return this.ele.getAttribute("data-sticky-selector");
        }

        return null;
    }

    set stickySelector(value: string) {
        if (this.ele) {
            this.ele.setAttribute("data-sticky-selector", value);
        }
    }

    get stickyPosition(): StickyPosition {
        if (this.ele) {
            let value = this.ele.getAttribute("data-sticky-position");

            switch (value) {
                case "bottom": return StickyPosition.Bottom;
                case "left": return StickyPosition.Left;
                case "right": return StickyPosition.Right;
                case "top": return StickyPosition.Top;
            }
        }

        return null;
    }

    set stickyPosition(value: StickyPosition) {
        if (this.ele) {
            let stringValue = "";

            switch (value) {
                case StickyPosition.Bottom:
                    stringValue = "bottom";
                    break;
                case StickyPosition.Left:
                    stringValue = "left";
                    break;
                case StickyPosition.Right:
                    stringValue = "right";
                    break;
                case StickyPosition.Top:
                    stringValue = "top";
                    break;
            }

            this.ele.setAttribute("data-sticky-position", stringValue);
        }
    }

    get showLoadingOnSubmit(): boolean {
        if (this.ele) return this.ele.hasAttribute("data-show-loading-submit");

        return true;
    }

    set showLoadingOnSubmit(value: boolean) {
        if (this.ele) {
            if (value)
                this.ele.setAttribute("data-show-loading-submit", "");
            else
                this.ele.removeAttribute("data-show-loading-submit");
        }
    }

    // Privados

    private bind(): void {
        if (this.formEle) {
            this.eventManager.add(this.formEle, "submit", e => this.submitForm(e));
        } else {
            let buttonSubmit = this.ele.querySelector("button[type=submit]") as HTMLElement;

            if (buttonSubmit) this.eventManager.add(buttonSubmit, "click", e => this.trigger(PopupEvents.Submit, e));
        }

        if (this.stickySelector) {
            this.eventManager.add(document.body, "click", e => {
                if (!this.fetching) this.closeStickyWhen(e)
            })
        }

        this.eventManager.add(this.overlayEle, "click", () => {
            if (!this.fetching) this.close()
        });

        let closes = this.ele.querySelectorAll("[data-popup-close]");

        for (var i = 0; i < closes.length; i++) {
            let item = closes.item(i) as HTMLElement;

            if (item) {
                this.eventManager.add(item, "click", e => {
                    e.preventDefault();

                    if (!this.fetching) this.close();
                });
            }
        }

        if (this.formEle) {
            this.formEle.reset();
            $.validator.unobtrusive.parse(this.formEle);
        }
            
    }

    private unbind(): void {
        this.eventManager.clear();
    }

    protected get ele(): HTMLElement {
        return document.getElementById(this.id);
    }

    protected get formEle(): HTMLFormElement {
        if (this.ele) return this.ele.querySelector("form") as HTMLFormElement;

        return null;
    }

    private get titleEle(): HTMLElement {
        if (this.ele) return this.ele.querySelector(".popup-title") as HTMLElement;

        return null;
    }

    private get overlayEle(): HTMLElement {
        let ele = document.querySelector("body > .overlay") as HTMLElement;

        if (!ele) {
            ele = document.createElement("div");
            ele.classList.add("overlay");

            document.body.appendChild(ele);
        }

        return ele;
    }

    private get arrowEle(): HTMLElement {
        if (this.ele) {
            return this.ele.querySelector(".popup-arrow") as HTMLElement;
        }

        return null;
    }

    private closeStickyWhen(event: Event): void {
        let target = event.target as HTMLElement;
        let stickyParent = target.closest(".popup-sticky");

        if (!stickyParent || stickyParent != this.ele) {
            this.close();
        }
    }

    private submitForm(event: Event): void {
        let triggered = this.formEle.querySelector("[type=submit]") as HTMLElement;
        let isValid = Common.validateForm(this.formEle);

        if (this.showLoadingOnSubmit && triggered && isValid) {
            triggered.classList.add("btn-loading");
        }

        if (!this.isAsync && isValid) {
            this.fetching = true;
            return;
        }

        let hasCallback: boolean = false;
        let retCallback: () => void;

        if (this instanceof LoginPopUp && !!(<LoginPopUp>this).returnCallback)
        {
            hasCallback = true;
            retCallback = (<LoginPopUp>this).returnCallback;
        }

        event.preventDefault();

        if (!isValid) return;

        this.trigger(PopupEvents.BeforeSubmit, event);

        let formData = new FormData(this.formEle);

        this.fetching = true;
        
        fetch(this.url, {
            method: "POST",
            body: formData,
            credentials: "same-origin"
        }).then(async response => {
            if (response.ok) {
                let contentTypeValue = response.headers.get("Content-Type");
                let contentType = this.getContentType(contentTypeValue);
                let data: any;

                switch (contentType) {
                    case ContentType.Html:
                        data = await response.text();

                        if (response.status !== 202)
                            this.managePartialHtml(data);

                        break;
                    case ContentType.Json:
                        data = await response.json();
                        break;
                }
                if (hasCallback) {
                    data.returnCallback = retCallback;
                }

                if (response.status == 201) {
                    if (this.showLoadingOnSubmit && triggered) {
                        triggered.classList.remove("btn-loading");
                    }
                    this.fetching = false;
                    this.trigger(PopupEvents.Fail, null, data);
                } else if (response.status == 202) {
                    document.getElementById('boardCreatePopup').getElementsByClassName("popup-content")[0].innerHTML = data;
                    this.trigger(PopupEvents.Fail, null);
                } else {
                    this.trigger(PopupEvents.Success, null, data);
                }
                
                if (this.formEle && this.resetForm) this.formEle.reset();
            } else {
                this.trigger(PopupEvents.Fail, null);
                if (this.formEle && this.resetForm) this.formEle.reset();
            }

            if (this.showLoadingOnSubmit && triggered) {
                triggered.classList.remove("btn-loading");
            }

            this.fetching = false;
            this.trigger(PopupEvents.Complete, null);
        }).catch(reason => {
            console.log(reason);
            this.trigger(PopupEvents.Fail, null);
        });
    }

    private managePartialHtml(data: string): void {
        if (!this.containerSelector || this.insertionMode == null) return;

        let container = document.querySelector(this.containerSelector);

        if (container) {
            if (this.insertionMode == InsertionMode.Append) {
                let fragment = new DocumentFragment();
                let parser = new DOMParser();
                let doc = parser.parseFromString(data, "text/html");

                for (var i = 0; i < doc.body.childElementCount; i++) {
                    let ele = doc.body.children[i].cloneNode(true);
                    fragment.appendChild(ele);
                }
                
                container.appendChild(fragment);
            } else if (this.insertionMode == InsertionMode.Replace) {
                container.innerHTML = data;
            }
        }
    }

    private manageSticky(): void {
        let stickyEle = document.querySelector(this.stickySelector) as HTMLElement;

        if (stickyEle) {
            let stickyRect = this.getRect(stickyEle);
            let popupRect = this.getRect(this.ele);
            let cssClass = "";
            
            let left = 0;
            let top = 0;

            switch (this.stickyPosition) {
                case StickyPosition.Bottom:
                    cssClass = "popup-sticky-bottom";
                    left = stickyRect.left + (stickyRect.width / 2) - (popupRect.width / 2);
                    top = window.scrollY + stickyRect.top + stickyRect.height;
                    break;
                case StickyPosition.Left:
                    cssClass = "popup-sticky-left";
                    left = stickyRect.left - popupRect.width;
                    top = (stickyRect.top + window.scrollY + stickyRect.height / 2) - popupRect.height / 2;
                    break;
                case StickyPosition.Right:
                    cssClass = "popup-sticky-right";
                    left = stickyRect.left + stickyRect.width;
                    top = (stickyRect.top + window.scrollY + stickyRect.height / 2) - popupRect.height / 2;
                    break;
                case StickyPosition.Top:
                    cssClass = "popup-sticky-top";
                    left = stickyRect.left + (stickyRect.width / 2) - (popupRect.width / 2);
                    top = window.scrollY + stickyRect.top - popupRect.height;
                    break;
            }

            this.ele.classList.remove("popup-sticky-bottom", "popup-sticky-left", "popup-sticky-right", "popup-sticky-top");
            this.ele.classList.add(cssClass);

            this.ele.style.left = `${left}px`;
            this.ele.style.top = `${top}px`;
        }
    }

    private getContentType(contentType: string): ContentType {
        let values = contentType.toLowerCase()
            .split(";");

        for (var i = 0; i < values.length; i++) {
            let value = values[i].trim();

            if (value == "application/json") return ContentType.Json;
            if (value == "text/html" || value == "text/plain") return ContentType.Html;
        }

        return ContentType.Unknown;
    }

    private getRect(ele: HTMLElement): ClientRect {
        let aux = ele.style.display;

        ele.style.display = "block";
        let ret = ele.getBoundingClientRect();
        ele.style.display = aux;

        return ret;
    }

    private trigger(type: PopupEvents, e: Event, data?: any) {
        if (this.eventCallback) {
            this.eventCallback(type, e, data);
        }        
    }
}


export class LoginPopUp extends Popup {
    private _callback: () => void;

    private get returnUrlEle(): HTMLInputElement {
        if (this.ele) return this.ele.querySelector("#ReturnUrlLogin") as HTMLInputElement;

        return null;
    }

    get returnUrl(): string {        
        if (this.returnUrlEle) return this.returnUrlEle.value;

        return null;
    }

    set returnUrl(value: string) {
        if (this.returnUrlEle) this.returnUrlEle.value = value;
    }

    get returnCallback():(() => void) {
        return this._callback;
    }

    set returnCallback(callback: () => void) {
        this._callback = callback;
    }
}

enum ContentType {
    Json,
    Html,
    Unknown
}

export enum PopupEvents {
    Show,
    Close,
    BeforeSubmit,
    Submit,
    Success,
    Fail,
    Complete
}

export enum InsertionMode {
    Replace,
    Append
}

export enum StickyPosition {
    Top,
    Right,
    Bottom,
    Left
}
