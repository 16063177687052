import { Map } from "./common/map";

export class ListingMap {
    private mapContainer: HTMLElement;
    private countMarkersContainer: HTMLElement;
    private map: Map;
    private feedUrl: string;
    private cardUrl: string;
    private canLoadMarkers: boolean = true;
    private drawAreaControl: DrawAreaControl;
    private customArea: L.LatLng[];

    constructor(public container: HTMLElement) {
        this.mapContainer = this.container.querySelector("[data-listing-map-container]") as HTMLElement;
        this.countMarkersContainer = this.container.querySelector("[data-listing-count-markers]") as HTMLElement;
        
        this.feedUrl = this.mapContainer.getAttribute("data-listing-map-url");
        this.cardUrl = this.mapContainer.getAttribute("data-listing-card-url");
    }

    init(): void {
        this.drawAreaControl = new DrawAreaControl({
            position: "bottomright"
        });     

        this.drawAreaControl.drawText = this.mapContainer.getAttribute("data-draw-text");
        this.drawAreaControl.clearText = this.mapContainer.getAttribute("data-clear-draw-text");

        this.drawAreaControl.onClick = eventButton => {
            this.drawAreaControl.visible = eventButton == DrawAreaButtons.Draw ?
                DrawAreaButtons.Clear :
                DrawAreaButtons.Draw;

            this.map.enableDraw = eventButton == DrawAreaButtons.Draw ? true : false;

            if (eventButton == DrawAreaButtons.Draw) {
                this.map.clearMassiveMarkers();
            } else {
                this.map.clearMassiveMarkers();
                this.customArea = null;
                this.loadMarkers();
            }
        };

        this.map = new Map(this.mapContainer);

        this.map.maxZoom = 20;
        this.map.init();

        this.map.addControl(this.drawAreaControl);
        this.map.zoomPosition = "bottomright";
        this.map.enableZoom = true;

        this.map.onDrawEnd(points => {
            this.customArea = points;
            this.loadMarkers(() => this.map.setViewToMarkerBounds());
        });

        this.loadMarkers(() => {
            this.map.setViewByDensity();

            this.map.onMoveEnd(() => {
                if (!this.map.enableDraw) this.loadMarkers();
            });

            this.map.onPopupOpen(() => this.canLoadMarkers = false);
            this.map.onPopupClose(() => this.canLoadMarkers = true);
        });
    }

    private setItemsCount(count: number, totalCount: number) {
        let message = this.countMarkersContainer.getAttribute("data-listing-count-markers");
        
        this.countMarkersContainer.innerHTML = message
            .replace("%count%", `<em>${count.toString()}</em>`)
            .replace("%totalCount%", `<em>${totalCount.toString()}</em>`);
    }

    private loadMarkers(endFn?: () => void): void {
        if (!this.canLoadMarkers) return;

        this.countMarkersContainer.style.display = "none";
        this.map.clearMassiveMarkers();
        
        this.getMarkers().then(response => {
            let markers: L.Marker[] = [];

            for (var i = 0; i < response.items.length; i++) {
                let item = response.items[i];
                let customMarkerIcon = new CustomMarkerIcon({
                    iconAnchor: [70 / 2, 27],
                    popupAnchor: [0, 8]
                });

                customMarkerIcon.noPriceLabel = response.noPriceText;
                customMarkerIcon.data = item;

                let marker = L.marker([item.lat, item.lng], {
                    icon: customMarkerIcon,
                    riseOnHover: true
                });

                marker.once("click", e => this.onMarkerClick(customMarkerIcon, marker, item));
                marker.once("add", () => {
                    let delay = (Math.random() * 500) + 100;
                    setTimeout(() => customMarkerIcon.show(), delay);
                });
                
                markers.push(marker);
            }

            this.map.addMassiveMarkers(markers)

            this.setItemsCount(response.items.length, response.found);
 
            if (endFn) endFn();
        });
    }

    private onMarkerClick(customMarker: CustomMarkerIcon, marker: L.Marker, item: markerData) {
        customMarker.loading = true;
        
        this.getCardFor(item.id).then(content => {
            marker.bindPopup(content);
            marker.openPopup();

            customMarker.loading = false;
            customMarker.visited = true;
        });
    }
    
    private getMarkers(): Promise<MarkersResponse> {
        let bounds = this.customArea || this.map.bounds;
        let area = this.getArea(bounds);
        let content = {
            "area": area,
            "url": window.location.pathname
        };
        
        return fetch(this.feedUrl + window.location.search, {
            method: "POST",
            credentials: "same-origin",
            body: JSON.stringify(content),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then<MarkersResponse>(response => {
            if (!response.ok) {
                throw new Error("Error al intentar obtener los markers del servidor");
            }

            return response.json();
        });
    }

    private getArea(bounds: L.LatLng[]): string {
        return bounds.map(v => `LatLng(${v.lat.toFixed(4)},${v.lng.toFixed(4)})`)
            .join(",");
    }

    private getCardFor(id: number): Promise<string> {
        let url = `${this.cardUrl}/${id.toString()}`;

        return fetch(url, {
            method: "GET",
            credentials: "same-origin"
        }).then<string>(response => {
            if (!response.ok) {
                throw new Error("Error al intentar obtener la card para un aviso");
            }

            return response.text();
        });
    }
}

class MarkersResponse {
    noPriceText: string;
    items: markerData[];
    found: number;
}

class markerData {
    id: number;
    lat: number;
    lng: number;
    price: number;
    symbol: string;
    visited: boolean;
}

class CustomMarkerIcon extends L.DivIcon {
    private millon: number = Math.pow(1000, 2);
    private miles: number = 1000;
    private width: number = 70;
    private height: number = 22;

    private container: HTMLElement;
    private title: HTMLAnchorElement;
    private symbol: HTMLSpanElement;
    private innerLoadingEle: SVGSVGElement;
    private innerLoading: boolean;
    private innerVisited: boolean;

    noPriceLabel: string;
    data: markerData;

    get loadingEle(): SVGSVGElement {
        if (this.innerLoadingEle) return this.innerLoadingEle;

        const svgNamespace = "http://www.w3.org/2000/svg";

        const ret = document.createElementNS(svgNamespace, "svg");
        const circle = document.createElementNS(svgNamespace, "circle")

        ret.classList.add("spinner");
        circle.classList.add("path");

        ret.setAttributeNS(null, "viewBox", "0 0 50 50");
        ret.appendChild(circle);

        circle.setAttributeNS(null, "cx", "25");
        circle.setAttributeNS(null, "cy", "25");
        circle.setAttributeNS(null, "r", "20");
        circle.setAttributeNS(null, "fill", "none");
        circle.setAttributeNS(null, "stroke-width", "5");

        return this.innerLoadingEle = ret;
    }

    set loading(value: boolean) {
        this.innerLoading = value;

        if (value) {
            this.title.style.display = "none";
            this.container.appendChild(this.loadingEle);

            L.DomUtil.addClass(this.container, "map-marker--loading");
        } else {
            this.title.style.display = "block";
            this.container.removeChild(this.loadingEle);

            L.DomUtil.removeClass(this.container, "map-marker--loading");
        }
    }

    get loading(): boolean {
        return this.innerLoading;
    }

    set visited(value: boolean) {
        this.innerVisited = value;

        if (value) {
            L.DomUtil.addClass(this.container, "map-marker--visited");
        } else {
            L.DomUtil.removeClass(this.container, "map-marker--visited");
        }
    }

    get visited(): boolean {
        return this.innerVisited
    }

    show(): void {
        L.DomUtil.addClass(this.container, "show");
    }

    createIcon(oldIcon?: HTMLElement): HTMLElement {
        let icon = super.createIcon(oldIcon);
        this.container = L.DomUtil.create("div", "map-marker", icon);

        L.DomUtil.removeClass(icon, "leaflet-div-icon");
        
        icon.style.width = `${this.width}px`;
        icon.style.height = `${this.height}px`;

        this.title = L.DomUtil.create("a", "map-marker__title", this.container) as HTMLAnchorElement;
        
        if (this.data.symbol && this.data.price) {
            this.symbol = L.DomUtil.create("span", "map-marker__symbol", this.title) as HTMLSpanElement;

            let priceValue = this.getFormattedPrice(this.data.price);
            let textNode = document.createTextNode(priceValue);

            this.symbol.textContent = this.data.symbol;
            this.title.appendChild(textNode);
        } else {
            this.title.textContent = this.noPriceLabel;
        }

        return icon;
    }

    createShadow(oldIcon?: HTMLElement): HTMLElement {
        return super.createShadow(oldIcon);
    }

    private getFormattedPrice(value: number): string {
        let sim = '';

        if (value > this.millon) {
            sim = 'm';
            value /= this.millon;
        }

        if (value > this.miles) {
            sim = 'k';
            value /= this.miles;
        }

        let ret = Math.floor(value);

        return ret + sim;
    }
}

class DrawAreaControl extends L.Control {
    private container: HTMLElement;
    private drawButton: HTMLButtonElement;
    private clearButton: HTMLButtonElement;

    drawText: string;
    clearText: string;
    onClick: (eventButton: DrawAreaButtons) => void;

    onAdd(map: L.Map): HTMLElement {
        this.container = L.DomUtil.create("div", "leaflet-control-draw-container") as HTMLElement;
        this.drawButton = L.DomUtil.create("button", "leaflet-control-draw", this.container) as HTMLButtonElement;
        this.clearButton = L.DomUtil.create("button", "leaflet-control-clear", this.container) as HTMLButtonElement;

        let drawIcon = L.DomUtil.create("i", "basico1-icon-pencil", this.drawButton) as HTMLElement;
        let trashIcon = L.DomUtil.create("i", "basico1-icon-trash", this.clearButton) as HTMLElement;

        this.drawButton.appendChild(drawIcon);
        this.drawButton.appendChild(document.createTextNode(this.drawText));

        this.clearButton.appendChild(trashIcon)
        this.clearButton.appendChild(document.createTextNode(this.clearText));

        this.clearButton.style.display = "none";

        L.DomEvent.on(this.drawButton, "click", this.drawClick.bind(this));
        L.DomEvent.on(this.clearButton, "click", this.clearClick.bind(this));

        L.DomEvent.disableClickPropagation(this.container);

        return this.container;
    }

    onRemove(map: L.Map): void {
        L.DomEvent.off(this.drawButton, "click", this.drawClick);
        L.DomEvent.off(this.clearButton, "click", this.clearClick);

        this.container.remove();
    }

    set visible(button: DrawAreaButtons) {
        this.drawButton.style.display = "none";
        this.clearButton.style.display = "none";

        if (button == DrawAreaButtons.Draw) this.drawButton.style.display = "block";
        if (button == DrawAreaButtons.Clear) this.clearButton.style.display = "block";
    }

    get visible(): DrawAreaButtons {
        return this.drawButton.style.display == "none" ?
            DrawAreaButtons.Clear :
            DrawAreaButtons.Draw;
    }

    private drawClick(e: Event): void {
        if (this.onClick) this.onClick(DrawAreaButtons.Draw);
    }

    private clearClick(e: Event): void {
        if (this.onClick) this.onClick(DrawAreaButtons.Clear);
    }
}

enum DrawAreaButtons {
    Draw,
    Clear
}
