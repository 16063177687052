
export class ImageLazyies {
    static initialize(selector?: string) {
        const container = !selector ?
            document.body :
            document.getElementById(selector);

        const imageLazy = new ImageLazy(),
              lazies = container.querySelectorAll("[data-lazy]");

        imageLazy.add(lazies);
    }
}
