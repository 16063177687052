
export class EventManager {
    private events: EventBind[];

    constructor() {
        this.events = [];
    }

    add(ele: HTMLElement, eventType: string, fn: (e: Event) => void): void {
        this.events.push({
            element: ele,
            eventType: eventType,
            fn: fn
        });

        ele.addEventListener(eventType, fn);
    }

    remove(ele: HTMLElement, eventType: string): boolean {
        for (var i = 0; i < this.events.length; i++) {
            let eb = this.events[i];

            if (eb.element == ele && eb.eventType == eventType) {
                eb.element.removeEventListener(eb.eventType, eb.fn);
                this.events.splice(i, 1);

                return true;
            }
        }

        return false;
    }

    clear(): void {
        for (var i = 0; i < this.events.length; i++) {
            let eb = this.events[i];

            eb.element.removeEventListener(eb.eventType, eb.fn);
        }

        this.events = [];
    }
}

class EventBind {
    element: HTMLElement;
    eventType: string;
    fn: any;
}
