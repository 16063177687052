
export class KeyAllow {
    static initialize(selector: string) {
        const container = selector ? document.querySelector(`#${selector}`) : document.body;
        manageKeyAllow(container as HTMLElement);
    }
}

export const manageKeyAllow = (container: HTMLElement) => {
    const handleKeyDown = (e: KeyboardEvent) => {
        const inputEle = e.target as HTMLInputElement;
        const pattern = inputEle.getAttribute("data-key-allow");
        const regex = new RegExp(pattern);
        const data = (e as any)["data"] as string; // Solo para que typescript no falle
        const key = data || e.key;

        if (key && key.length === 1 && !regex.test(key)) {
            e.preventDefault();
        }
    };

    // Lo de any es para que typescript no reviente, ya que todavia no tiene la definicion del metodo getTargetRanges
    const inputEventProto = InputEvent.prototype as any;
    const isBeforeInputEventAvailable = () => typeof inputEventProto.getTargetRanges === "function";
    // Intento usar beforeinput por sobre keydown. En Android esta implementado y en Firefox no, por eso es uno o el otro
    const eventType = isBeforeInputEventAvailable() ? "beforeinput" : "keydown";

    const elements = container.querySelectorAll<HTMLInputElement>("[data-key-allow]");
    elements.forEach(ele => ele.addEventListener(eventType, handleKeyDown));
};
