

export class ImageBox {
    static initialize() {
        $(".img-box").each(function () {
            const me = $(this);
            // add a photo container
            me.append('<div class="img-box-background"></div>');
            // set up a background image for each tile based on data-background-image attribute
            me.children(".img-box-background").css({ 'background-image': `url(${$(this).attr('data-background-image')})` });
        });
    }
}