
export class Creditos {
    static initialize() {
        // TODO: Este comportamiento es el del archivo Tabs.ts, mas adelante intentar unificar

        const tabLanding = document.querySelector('[data-tab-landing]');
        const tabSimulador = document.querySelector('[data-tab-simulador]');
        const tabRequisitos = document.querySelector('[data-tab-requisitos]');
        const tabPreguntas = document.querySelector('[data-tab-preguntas]');

        const blockSimulador = document.querySelector('[data-block-simulador]');
        const blockLanding = document.querySelector('[data-block-landing]');
        const blockRequisitos = document.querySelector('[data-block-requisitos]');
        const blockPreguntas = document.querySelector('[data-block-preguntas]');

        const btnSimulador = document.querySelector('[data-btn-simulador]');

        //Mobile
        const tabLandingMobile = document.querySelector('[data-tab-landing-mobile]');
        const tabSimuladorMobile = document.querySelector('[data-tab-simulador-mobile]');
        const tabRequisitosMobile = document.querySelector('[data-tab-requisitos-mobile]');
        const tabPreguntasMobile = document.querySelector('[data-tab-preguntas-mobile]');
        //////////////////////

        if (tabSimulador === null) return;

        const handleTabLanding = e => {
            e.preventDefault();

            tabLanding.classList.add('active');
            blockLanding.classList.remove('hide');

            tabSimulador.classList.remove('active');
            tabRequisitos.classList.remove('active');
            tabPreguntas.classList.remove('active');

            blockSimulador.classList.add('hide');
            blockRequisitos.classList.add('hide');
            blockPreguntas.classList.add('hide');

            //mobile
            tabLandingMobile.classList.add('mobile-active');
            tabSimuladorMobile.classList.remove('mobile-active');
            tabRequisitosMobile.classList.remove('mobile-active');
            tabPreguntasMobile.classList.remove('mobile-active');
        };

        const handleTabSimulador = e => {
            e.preventDefault();

            tabSimulador.classList.add('active');
            blockSimulador.classList.remove('hide');

            tabLanding.classList.remove('active');
            tabRequisitos.classList.remove('active');
            tabPreguntas.classList.remove('active');

            blockLanding.classList.add('hide');
            blockRequisitos.classList.add('hide');
            blockPreguntas.classList.add('hide');

            //mobile
            tabSimuladorMobile.classList.add('mobile-active');
            tabLandingMobile.classList.remove('mobile-active');
            tabRequisitosMobile.classList.remove('mobile-active');
            tabPreguntasMobile.classList.remove('mobile-active');
        };

        const handleTabRequisitos = e => {
            e.preventDefault();

            tabRequisitos.classList.add('active');
            blockRequisitos.classList.remove('hide');

            tabSimulador.classList.remove('active');
            tabPreguntas.classList.remove('active');
            tabLanding.classList.remove('active');

            blockSimulador.classList.add('hide');
            blockLanding.classList.add('hide');
            blockPreguntas.classList.add('hide');

            //mobile
            tabRequisitosMobile.classList.add('mobile-active');
            tabSimuladorMobile.classList.remove('mobile-active');
            tabPreguntasMobile.classList.remove('mobile-active');
            tabLandingMobile.classList.remove('mobile-active');
        };

        const handleTabPreguntas = e => {
            e.preventDefault();

            tabPreguntas.classList.add('active');
            blockPreguntas.classList.remove('hide');

            tabSimulador.classList.remove('active');
            tabRequisitos.classList.remove('active');
            tabLanding.classList.remove('active');

            blockSimulador.classList.add('hide');
            blockLanding.classList.add('hide');
            blockRequisitos.classList.add('hide');

            //mobile
            tabPreguntasMobile.classList.add('mobile-active');
            tabSimuladorMobile.classList.remove('mobile-active');
            tabRequisitosMobile.classList.remove('mobile-active');
            tabLandingMobile.classList.remove('mobile-active');
        };

        tabLanding.addEventListener('click', e => handleTabLanding(e));
        tabSimulador.addEventListener('click', e => handleTabSimulador(e));
        tabRequisitos.addEventListener('click', e => handleTabRequisitos(e));
        tabPreguntas.addEventListener('click', e => handleTabPreguntas(e));

        //mobile buttons
        tabLandingMobile.addEventListener('click', e => handleTabLanding(e));
        tabSimuladorMobile.addEventListener('click', e => handleTabSimulador(e));
        tabRequisitosMobile.addEventListener('click', e => handleTabRequisitos(e));
        tabPreguntasMobile.addEventListener('click', e => handleTabPreguntas(e));

        btnSimulador.addEventListener('click', e => handleTabSimulador(e));
    }
}
