
export enum NotificationType {
    Danger,
    Warning,
    Success,
    Info
}

export class Notifications {
    static initialize() {
        let notifications = document.querySelectorAll("[data-notification]");

        for (var i = 0; i < notifications.length; i++) {
            let notification = notifications[i];

            let message = notification.getAttribute("data-notification-message");
            let type = notification.getAttribute("data-notification-type");

            this.show(message, this.getNotificationType(type));

            notification.remove();
        }
    }

    static getNotificationType(type: string): NotificationType {
        switch (type.toLowerCase()) {
            case "danger": return NotificationType.Danger;
            case "success": return NotificationType.Success;
            case "warning": return NotificationType.Warning;
            case "info": return NotificationType.Info;
        }

        return NotificationType.Info;
    }
    
    static show(message: string, type: NotificationType): void {
        let iconCss = this.getIconFromType(type);
        let boxCss = this.getCssFromType(type);

        let fragment = this.createNotification(message, iconCss, boxCss);
        let box = fragment.firstChild;

        box.addEventListener("animationend", (e: AnimationEvent) => {
            if (e.animationName == "notification-hide") {
                document.body.removeChild(box);
            }
        });

        document.body.appendChild(box);
    }

    private static getIconFromType(type: NotificationType): string {
        switch (type) {
            case NotificationType.Danger: return "fa-exclamation-circle";
            case NotificationType.Warning: return "fa-exclamation-triangle";
            case NotificationType.Success: return "fa-check-circle";
            case NotificationType.Info: return "fa-info-circle";
        }

        return "fa fa-info-circle-";
    }

    private static getCssFromType(type: NotificationType): string {
        switch (type) {
            case NotificationType.Danger: return "notification-danger";
            case NotificationType.Warning: return "notification-warning";
            case NotificationType.Success: return "notification-success";
            case NotificationType.Info: return "notification-info";
        }

        return "notification-info";
    }

    private static createNotification(text: string, iconCssClass: string, cssClass: string): DocumentFragment {
        let fragment = document.createDocumentFragment();

        let container = document.createElement("div");
        let para = document.createElement("p");
        let icon = document.createElement("i");

        icon.classList.add("fa");
        icon.classList.add(iconCssClass);

        para.appendChild(icon);
        para.appendChild(document.createTextNode(text));

        container.appendChild(para);

        container.classList.add("notification");
        container.classList.add(cssClass);

        fragment.appendChild(container);

        return fragment;
    }
}
