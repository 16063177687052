import { Animate, Easings } from "./common/animate";
import { Popup } from "./common/popup";
import { Common } from "./common";
import { ReCaptcha, ReCaptchaEvents } from "./common/recaptcha";
import { AutocompleteInput } from "./common/autocomplete-input";
import { EventManager } from "./common/event-manager";
import { MaterialControl } from "./common/material-control";
import { Criteo } from "./common/criteo";
import { Facebook } from "./common/facebook";
import { CommonHelper } from "common/common-helper";
import { GroovinAds } from "./common/groovinads";
import { Thumbnail } from "./common/thumbnail";
import { pushEventoContacto, MedioContacto } from './common/gtm'

declare var utmStorage: any;
declare var Mustache: any;

export class Ficha {
    static commentOptionsPopup: Popup;
    private static contactCaptcha: ReCaptcha;
    private static eventManager: EventManager;
    private static isIgnoradoClient = false;
    private static galleryCarousel: Carousel;
    private static thumbnail: Thumbnail;
    private static discardPopup;

    static initialize() {
        let hasVideo = document.querySelector(".filter-video") != null;

        if (Ficha.isFicha) {
            const ficha = document.getElementById("ficha");
            Criteo.pushEventItem(ficha.getAttribute("data-id-aviso"));
            Facebook.ViewEvent(ficha.getAttribute("data-id-aviso"));

            const datosFicha = document.getElementById("ga-dimension-ficha");

            if (datosFicha) {
                const idCategoria = datosFicha.getAttribute("data-tipo-propiedad") + "-" + datosFicha.getAttribute("data-tipo-operacion");
                GroovinAds.pushEventRetargetingFicha(0, ficha.getAttribute("data-id-aviso"), idCategoria)
            }

            this.eventManager = new EventManager();

            this.shareButtonInitialize();
            this.scrollToContact();
            this.scrollToElement('[data-scroll-to-widget]', '[data-simulador-footer]');
            this.unidadesInitialize();
            this.trackAviso();
            this.viewPhoneLoginLink();
            this.viewPhoneMobile();
            this.setupContact();
            this.contactFormInit();
            this.avisosSimilaresPartial();
            this.getBoardsByPropertyPartial();
            this.handleClickMap();
            this.contactTextPopup();
            this.contactFooter();
            this.setStateIconFavorito();
            this.ignoradoInit();
            this.galleryInit();
            this.thumbnailInit();

            this.commentOptionsPopup = new Popup("commentOptionsPopup");

            if (CommonHelper.isInternetExplorer())
                this.ComptibilityInternetExplorer();

            if (hasVideo)
                this.initializeVideos();
        }

        this.scrollToElement('[data-scroll-to-garantiasWidget]', '[data-simulador-garantias]');
    }

    private static set galleryCurrent(current: number) {
        const ele = document.querySelector("[data-gallery-current]");

        if (ele) ele.textContent = current.toString();
    }

    private static thumbnailInit() {
        const thumbnailEle = document.querySelector("[data-gallery-thumbnail]") as HTMLElement;

        this.thumbnail = new Thumbnail(thumbnailEle);

        this.thumbnail.onClick(index => this.galleryCarousel.move(index));
    }

    private static galleryInit() {
        const openGalleryBtn = document.querySelector("[data-ficha-multimedia-fullscreen]");
        const slickSlider = document.querySelector("[data-slider]");
        const galleryEle = document.querySelector("[data-gallery]");
        const carouselEle = galleryEle.querySelector("[data-gallery-carousel]");
        const galleryPrev = galleryEle.querySelector("[data-gallery-prev]") as HTMLElement;
        const galleryNext = galleryEle.querySelector("[data-gallery-next]") as HTMLElement;
        const galleryClose = galleryEle.querySelector("[data-gallery-close]");

        this.galleryCarousel = new Carousel(carouselEle);

        const setPrevNextState = index => {
            galleryPrev.classList.remove("disabled");
            galleryNext.classList.remove("disabled");

            if (index === 0) galleryPrev.classList.add("disabled");
            if (index === this.galleryCarousel.count() - 1) galleryNext.classList.add("disabled");
        };

        const handleKeyboard = (e: KeyboardEvent) => {
            switch (e.which) {
                case 27: // Escape
                    closeGallery();
                    break;
                case 37: // Arrow left
                    this.galleryCarousel.prev();
                    break;
                case 39: // Arrow right
                    this.galleryCarousel.next();
                    break;
            }
        };

        const openGallery = () => {
            let index = this.currentSlide - 1;

            document.body.style.overflow = "hidden";

            galleryEle.classList.add("show")

            this.galleryCarousel.move(index);
            this.setStateIconFavorito();

            document.body.addEventListener("keydown", handleKeyboard);

            // Le quito el foco al carousel de la ficha porque se mueve cuando toco las flechas del teclado
            if (galleryPrev) galleryPrev.focus();
        };

        const closeGallery = () => {
            document.body.style.overflow = "";

            galleryEle.classList.remove("show")

            this.setStateIconFavorito();
            document.body.removeEventListener("keydown", handleKeyboard);
        };

        slickSlider.addEventListener("click", e => {
            const target = e.target as HTMLElement;
            const isOpenGallery = target.hasAttribute("data-gallery-open");

            if (isOpenGallery) {
                e.preventDefault();

                openGallery();
            }
        });

        this.galleryCarousel.onChange(index => {
            this.thumbnail.move(index);
            this.galleryCurrent = index + 1;

            setPrevNextState(index);
        });

        carouselEle.addEventListener("click", () => closeGallery());
        openGalleryBtn.addEventListener("click", () => openGallery());
        galleryClose.addEventListener("click", () => closeGallery());

        if (galleryPrev && galleryNext) {
            galleryPrev.addEventListener("click", () => {
                this.galleryCarousel.prev();
            });

            galleryNext.addEventListener("click", () => {
                this.galleryCarousel.next();
            });
        }
    }

    private static get currentSlide(): number {
        const currentSlideEle = document.querySelector("[data-current-slide]");

        return parseInt(currentSlideEle.textContent);
    }

    private static ComptibilityInternetExplorer() {
        $('[data-ie-object-fit]').each(function () {
            let $element = $(this),
                imgUrl = $element.prop('src');
            if (imgUrl) {
                $element.prop('src', '');
                $element.prop('alt', '');
                $element.prop('onerror', '');
                $element.css('backgroundImage', 'url(' + imgUrl + ')');
                $element.css('backgroundSize', 'cover');
                $element.css('backgroundPositionX', 'center');
                $element.css('backgroundPositionY', 'center');
            }
        });
    }

    private static setStateIconFavorito() {
        const btnFavorito = document.querySelector("[data-favourite-slider]");

        if (!btnFavorito) return;

        const idaviso = btnFavorito.getAttribute("data-favourite-button-id");
        const getStateUrl = btnFavorito.getAttribute("data-favourite-button-check-url");
        const url = `${getStateUrl}?idavisos=${idaviso}`;

        fetch(url, {
            method: "GET",
            credentials: "same-origin"
        }).then<IsFavoriteResponse[]>(response => {
            if (response.ok) return response.json();
        }).then(data => {
            if (data.length == 0) return;

            const btns = document.querySelectorAll("[data-favourite-button]");
            const value = data[0].Favorito;

            for (let i = 0; i < btns.length; i++) {
                const btn = btns.item(i);

                btn.setAttribute("data-favourite-current-status", value ? "true" : "false");

                if (value) {
                    btn.classList.add("active");
                } else {
                    btn.classList.remove("active");
                }
            }
        });
    }

    private static handleClickMap() {
        const container = document.querySelector("[data-simple-map-container]") as HTMLElement;
        const mapa = document.querySelector("[data-simple-map]") as HTMLElement;
        if (!container) return;

        container.addEventListener("click", () => {
            container.style.cursor = "default";
            mapa.style.pointerEvents = "unset";
        });
    }

    private static get isFicha(): boolean {
        return document.querySelector("#ficha") != null;
    }

    private static get contactForm(): HTMLFormElement {
        return document.querySelector("[data-contact-form]") as HTMLFormElement;
    }

    private static get contactContainer(): HTMLElement {
        return document.querySelector("[data-contact-container]") as HTMLElement;
    }

    private static get contactButton(): HTMLButtonElement {
        return this.contactForm.querySelector("[data-contact-button]") as HTMLButtonElement;
    }

    private static get agentContainer(): HTMLElement {
        return document.querySelector("[data-agent-container]") as HTMLElement;
    }

    private static get agentShowPhone(): HTMLAnchorElement {
        return this.agentContainer.querySelector("[data-agent-show-phone]") as HTMLAnchorElement;
    }

    private static set viewPhoneVisible(value: boolean) {
        let eles = document.querySelectorAll("[data-agent-show-phone]");

        for (var i = 0; i < eles.length; i++) {
            let item = eles.item(i) as HTMLElement;

            item.style.display = value ? "block" : "none";
        }
    }

    private static set phoneLoading(value: boolean) {
        let eles = document.querySelectorAll("[data-agent-phone-loading]");

        for (var i = 0; i < eles.length; i++) {
            let ele = eles.item(i) as HTMLElement;

            ele.style.display = value ? "block" : "none";
        }
    }

    private static set phoneText(value: string) {
        let eles = document.querySelectorAll("[data-agent-phone-container]");

        for (var i = 0; i < eles.length; i++) {
            let ele = eles.item(i) as HTMLElement;

            ele.textContent = value;
        }
    }

    private static getBoardsByPropertyPartial() {
        const container = document.getElementById("boardsByPropertyContainer");

        if (container) {
            const url = container.getAttribute("data-url-partial");

            fetch(url, {
                credentials: "same-origin",
                method: "GET"
            }).then(response => {
                if (response.ok)
                    return response.text();
            }).then(html => {
                container.innerHTML = html;
                this.checkInitializeComments();
            });
        }
    }

    private static contactTextPopup(): void {
        const btnConsultarTexto = document.querySelector("[data-button-consultar-texto]") as HTMLButtonElement;

        if (!btnConsultarTexto) return;

        const consultarPopup = new Popup("contactPopup");
        let contactTextForm: HTMLFormElement;

        const handleSubmit = (e: Event) => {
            e.preventDefault();
            contactTextForm = e.target as HTMLFormElement;

            contactTextForm.querySelector(".btn-primary")
                .classList.add("btn-loading");

            consultarPopup.fetching = false;

            const data = new FormData(contactTextForm);
            const url = contactTextForm.action;

            data.append("g-recaptcha-response", "no-captcha");

            fetch(url, {
                credentials: "same-origin",
                method: "POST",
                body: data
            }).then(res => {
                if (res.ok) return res.text();
                throw new Error("Error al intentar contactar");
            }).then(html => {
                consultarPopup.content.innerHTML = html;
                initContent(consultarPopup.content);

                pushEventoContacto(MedioContacto.Texto);

                let precio = this.getPrecioAviso();
                let idaviso = this.getIdAviso();
                let moneda = this.getMoneda();

                Criteo.pushEventTrackTransaction(idaviso, precio);
                Facebook.PurchaseEvent(idaviso, precio, moneda);
            });
        }

        const initContent = (ele: HTMLElement) => {
            AutocompleteInput.initialize();
            Common.reloadFormValidator(contactTextForm);
            MaterialControl.initialize(ele);

            utmStorage.setHidden();
        }

        consultarPopup.content.addEventListener("submit", handleSubmit);

        consultarPopup.content.addEventListener("click", e => {
            const target = e.target as HTMLElement;
            const isSendOtherMessage = target.hasAttribute("data-send-other-message");

            if (isSendOtherMessage) {
                e.preventDefault();
                const url = target.getAttribute("href");

                fetch(url, {
                    credentials: "same-origin",
                    method: "POST"
                }).then(res => {
                    if (res.ok) return res.text();
                    throw new Error("Error");
                }).then(html => {
                    consultarPopup.content.innerHTML = html;
                    initContent(consultarPopup.content);
                });
            }
        });

        btnConsultarTexto.addEventListener("click", e => {
            const url = btnConsultarTexto.getAttribute("data-url");

            btnConsultarTexto.classList.add("btn-loading");

            fetch(url, {
                credentials: "same-origin",
                method: "POST"
            }).then(res => {
                if (res.ok) return res.text();
                throw new Error("Error al obtener el formulario de contacto");
            }).then(data => {
                btnConsultarTexto.classList.remove("btn-loading");

                const parser = new DOMParser();
                const doc = parser.parseFromString(data, "text/html");

                consultarPopup.content.innerHTML = "";
                consultarPopup.content.appendChild(doc.body.firstChild);

                consultarPopup.show();
                initContent(consultarPopup.content);
            });
        });
    }

    private static contactFooter() {
        let btnWhatsApp = document.querySelector("[data-whatsapp-target]") as HTMLAnchorElement;

        if (!btnWhatsApp) return;

        btnWhatsApp.addEventListener("click", e => {
            let url = btnWhatsApp.getAttribute("data-url-post-whatsapp");
            let userName = Common.getKeyToLocalStorage("userName");
            if (userName !== null) url = `${url}&email=${userName}`;

            fetch(url, { method: "POST" })
                .then(response => {
                    if (response.ok) return response.json();
                });

            window.open(btnWhatsApp.getAttribute("href"), '_blank');
        });
    }

    private static contactFormInit(): void {
        this.contactCaptcha = new ReCaptcha("contactCaptcha");

        let captchaEvents = () => {

            ReCaptcha.on(ReCaptchaEvents.Success, "contactCaptcha", () => {
                let url = this.contactForm.action;
                let data = new FormData(this.contactForm);
                let recaptchaToken = this.contactCaptcha.getResponse();

                this.contactButton.classList.add("btn-loading");

                data.append("g-recaptcha-response", recaptchaToken);

                fetch(url, {
                    method: "POST",
                    credentials: "same-origin",
                    body: data
                }).then(response => {
                    this.contactButton.classList.remove("btn-loading");

                    if (response.ok) return response.text();
                }).then(res => {
                    Common.saveLocalStorageFromValue("Email", "userName");
                    Common.saveLocalStorageFromValue("Telefono", "userPhone");
                    this.contactContainer.innerHTML = res;
                    
                    this.contactCaptcha.reset();

                    let precio = this.getPrecioAviso();
                    let idaviso = this.getIdAviso();
                    let moneda = this.getMoneda();

                    Criteo.pushEventTrackTransaction(idaviso, precio);
                    Facebook.PurchaseEvent(idaviso, precio, moneda);
                });
            });

            ReCaptcha.on(ReCaptchaEvents.Error, "contactCaptcha", () => {
                this.contactButton.classList.remove("btn-loading");
            });

            ReCaptcha.on(ReCaptchaEvents.Expired, "contactCaptcha", () => {
                this.contactButton.classList.remove("btn-loading");
            });

        };

        if (!this.contactContainer) return;

        this.contactContainer.addEventListener("submit", e => {
            e.preventDefault();

            let isValid = Common.validateForm(this.contactForm);

            if (isValid) {
                captchaEvents();
                this.contactCaptcha.execute();
            }
        });

        this.contactContainer.addEventListener("click", e => {
            let target = e.target as HTMLElement;
            let sendOtherMessage = target.hasAttribute("data-send-other-message");

            if (sendOtherMessage) {
                e.preventDefault();

                let anchor = target as HTMLAnchorElement;
                let url = anchor.href;

                fetch(url, {
                    method: "POST",
                    credentials: "same-origin"
                }).then(response => {
                    if (response.ok) return response.text();
                }).then(res => {
                    this.contactContainer.innerHTML = res;

                    AutocompleteInput.initialize();
                    Common.reloadFormValidator(this.contactForm);
                    MaterialControl.initialize(this.contactForm);

                    utmStorage.setHidden();
                });
            }
        });
    }

    private static get idVisibilidad(): number {
        const idVisibilidadEle = document.querySelector("[data-id-visibilidad]") as HTMLElement;

        if (idVisibilidadEle) return parseInt(idVisibilidadEle.getAttribute("data-id-visibilidad"));
    }

    static getIdAviso(): string {
        return String(document.querySelector("[data-tracker-statistics]").getAttribute("data-id-aviso"));
    }

    static getMoneda(): string {
        return String(document.querySelector("[data-tracker-statistics]").getAttribute("data-moneda"));
    }

    static getPrecioAviso(): string {
        return document.querySelector("[data-tracker-statistics]").getAttribute("data-price");
    }

    private static initializeVideos(): void {
        $('.filter-photos').on('click', function () {
            $(".property-slider").slick('slickUnfilter');
            $(".property-slider").slick('slickFilter', '.foto-slide');
            $(".multimedia-button").removeClass("active");
            $(this).addClass("active");
        });

        $('.filter-video').on('click', function () {
            $(".property-slider").slick('slickUnfilter');
            $(".property-slider").slick('slickFilter', '.video-slide');
            $(".multimedia-button").removeClass("active");
            $(this).addClass("active");
        });
    }

    public static checkInitializeComments() {
        if (document.querySelector(".details-view") === null) return;

        if (document.querySelectorAll(".chkBoard:checked").length > 0) {
            //El aviso está en al menos un tablero
            this.initializeComments();
        } else {
            document.getElementById("propiedad-comentarios").classList.add("hidden");
        }
    }

    public static initializeComments() {
        let container = document.getElementById("propiedad-comentarios") as HTMLElement;
        container.classList.remove("hidden");
        let idVisibilidad = container.getAttribute("data-id-visibilidad");
        let url = container.getAttribute("data-url-get-comments") + "?idVisibilidadAviso=" + idVisibilidad;

        fetch(url, {
            method: "GET",
            credentials: "same-origin"
        }).then(async response => {
            if (response.ok) {
                container.innerHTML = await response.text();
            } else return;
        }).then(res => {
            this.initializeWordRatings();

            document.querySelector(".property-new-comment-textarea").addEventListener('input', function (event) {
                if ((event.target as HTMLElement).tagName.toLowerCase() !== 'textarea') return;
                Ficha.validateInput(event.target as HTMLInputElement);
                Ficha.autoExpand(event.target as HTMLElement);
            }, false);

            let textareas = document.querySelectorAll(".favourite-comment-text textarea");
            if (textareas) {
                for (var i = 0; i < textareas.length; i++) {
                    Ficha.autoExpand(textareas[i] as HTMLElement);
                }
            }
        });

        if (container.getAttribute("bind-events") == "true") {
            container.setAttribute("bind-events", "false");
        } else {
            return;
        }        
    }

    private static autoExpand(field: HTMLElement) {
        field.style.height = "0px"

        if (field.classList.contains("property-new-comment-textarea"))
            field.style.height = (field.scrollHeight + 1) + 'px';
        else
            field.style.height = (field.scrollHeight - 20) + 'px';
    }

    private static validateInput(field: HTMLInputElement) {
        let btn = document.querySelector(".btn-submit-add-comment");
        if (field.value != "") {
            btn.removeAttribute("disabled");
        } else {
            btn.setAttribute("disabled", "disabled");
        }
    }

    private static viewPhoneMobile(): void {
        const showPhone = document.querySelector("[data-footer-show-phone]") as HTMLButtonElement;
        if (!showPhone) return;

        const captchaEvents = (url: string, sendData: FormData) => {
            ReCaptcha.on(ReCaptchaEvents.Expired, "contactCaptcha", () => {
                showPhone.classList.remove("btn-loading");
            });

            ReCaptcha.on(ReCaptchaEvents.Error, "contactCaptcha", () => {
                showPhone.classList.remove("btn-loading");
            });

            ReCaptcha.on(ReCaptchaEvents.Success, "contactCaptcha", () => {
                showPhone.classList.add("btn-loading");

                let recaptchaToken = this.contactCaptcha.getResponse();

                sendData.append("g-recaptcha-response", recaptchaToken);

                this.callShowPhone(url, sendData)
                    .catch(() => showPhone.classList.remove("btn-loading"))
                    .then(() => showPhone.classList.remove("btn-loading"));

                this.contactCaptcha.reset();
            });
        };

        showPhone.addEventListener("click", e => {
            let store = utmStorage.get();
            let url = showPhone.getAttribute("data-url");
            let sendData = new FormData();

            if (store && store.utm_source) sendData.append("utm_source", store.utm_source);
            if (store && store.utm_medium) sendData.append("utm_medium", store.utm_medium);
            if (store && store.utm_campaign) sendData.append("utm_campaign", store.utm_campaign);

            captchaEvents(url, sendData);

            this.contactCaptcha.execute();
        });
    }

    private static viewPhoneLoginLink(): void {
        let captchaEvents = (url: string, sendData: FormData) => {
            this.viewPhoneVisible = false;
            this.phoneLoading = true;

            ReCaptcha.on(ReCaptchaEvents.Error, "contactCaptcha", () => {
                this.viewPhoneVisible = true;
                this.phoneLoading = false;
            });

            ReCaptcha.on(ReCaptchaEvents.Success, "contactCaptcha", () => {
                let recaptchaToken = this.contactCaptcha.getResponse();

                sendData.append("g-recaptcha-response", recaptchaToken);

                this.callShowPhone(url, sendData);
                this.contactCaptcha.reset();
            });
        };

        this.eventManager.add(document.body, "click", e => {
            let target = e.target as HTMLElement;
            let isAgentShowPhone = target.hasAttribute("data-agent-show-phone") || target.parentElement.hasAttribute("data-agent-show-phone");

            if (isAgentShowPhone) {
                e.preventDefault();

                if (target.parentElement.hasAttribute("data-agent-show-phone")) {
                    target = target.parentElement;
                }

                let store = utmStorage.get();
                let anchor = target as HTMLAnchorElement;
                let url = anchor.href;
                let sendData = new FormData();

                if (store && store.utm_source) sendData.append("utm_source", store.utm_source);
                if (store && store.utm_medium) sendData.append("utm_medium", store.utm_medium);
                if (store && store.utm_campaign) sendData.append("utm_campaign", store.utm_campaign);

                captchaEvents(url, sendData);
                //Se agrega el scroll porque el captcha se renderiza arriba de todo y desde el ver telefono que esta en la parte
                //inferior de la ficha se pierde el captcha
                //Revisar a futuro si se solucionó el problema ya que por lo que se investigó no se puede solucionar por css
                window.scrollTo(null, 0);
                this.contactCaptcha.execute();
            }
        });
    }

    private static callShowPhone(url: string, sendData: FormData) {
        return fetch(url, {
            method: "POST",
            credentials: "same-origin",
            body: sendData
        }).then(response => {
            this.phoneLoading = false;

            if (response.ok) return response.json();
        }).then(data => {
            if (data.statusCode == 200) {
                this.viewPhoneVisible = true;
                let contactoPopup = new Popup("contactoPopup");
                let pop = document.querySelector("[data-popup-contact-phone]");
                pop.innerHTML = data.partial;
                contactoPopup.show();

                pushEventoContacto(MedioContacto.Telefono);

                let precio = this.getPrecioAviso();
                let idaviso = this.getIdAviso();
                let moneda = this.getMoneda();

                Criteo.pushEventTrackTransaction(idaviso, precio);
                Facebook.PurchaseEvent(idaviso, precio, moneda);
            }

            if (data.statusCode == 401) {
                this.viewPhoneVisible = true;

                Common.showLogin(() => this.callbackShowPhonePopup());
            }

            if (data.statusCode == 500) {
                this.viewPhoneVisible = true;
                console.error("Error en el servidor!!");
            }
        });
    }

    private static callbackShowPhonePopup() {
        this.viewPhoneVisible = false;
        this.phoneLoading = true;

        const target = document.querySelector<HTMLAnchorElement>("[data-agent-show-phone]");
        const url = target.href;
        const sendData = new FormData();

        sendData.append("showPhone", 'true');

        this.callShowPhone(url, sendData);
    }

    private static initializeWordRatings() {
        let stars = document.querySelectorAll("input[name='Calificacion']")
        for (let i = 0; i < stars.length; i++) {
            stars[i].addEventListener("click", e => {
                let wordRating = (stars[i] as HTMLElement).getAttribute("wordRating");
                (document.getElementById("wordRating") as HTMLElement).innerHTML = wordRating;
            });
        }
    }

    private static setupContact() {
        var x = document.querySelectorAll("div.col-md-12 > input");
        for (let i = 0; i < x.length; i++) {
            if (!(x[i] as HTMLInputElement).value) continue;
            x[i].classList.add('valid');
        }
    }

    private static unidadesInitialize() {
        let container = document.querySelector("[data-container-unidades]");
        let toggleUnidades = document.querySelector("[data-toggle-unidades]");

        if (!toggleUnidades) return;

        toggleUnidades.addEventListener("click", () => {
            toggleUnidades.classList.toggle("toggled");
            container.classList.toggle("emprendimiento__unidades--show-all");
        });
    }

    private static shareButtonInitialize() {
        let shareBtn = document.querySelector("[data-share-menu-button]");

        if (shareBtn) {
            let menu = document.querySelector("[data-share-menu]");
            let isVisible = () => menu.classList.contains("visible");

            shareBtn.addEventListener("click", e => {
                menu.classList.toggle("visible");
            });

            document.body.addEventListener("click", e => {
                let target = e.target as HTMLElement;
                let isShareMenu = target.closest("[data-share-menu-container]") != null;

                if (!isShareMenu && isVisible()) {
                    menu.classList.remove("visible");
                }
            });
        }
    }

    private static scrollToContact() {
        let eles = document.querySelectorAll("[data-scroll-to-contact]")

        for (var i = 0; i < eles.length; i++) {
            let ele = eles[i];

            ele.addEventListener("click", e => {
                e.preventDefault();
                let from = this.scrollTop;
                let animation = new Animate(from, 0, 1000, Easings.EaseInOutCirc);

                animation.start(value => this.scrollTop = value, () => this.setContactFormFocus());
            });
        }
    }

    private static scrollToElement(dataOriginButton, dataDestination) {
        const eles = document.querySelectorAll(dataOriginButton);
        const destination = document.querySelector(dataDestination) as HTMLElement;

        for (var i = 0; i < eles.length; i++) {
            let ele = eles[i];

            if (ele === null || destination === null) return;

            ele.addEventListener("click", e => {
                e.preventDefault();
                var bodyRect = document.body.getBoundingClientRect();
                var destRect = destination.getBoundingClientRect();
                var eleRect = ele.getBoundingClientRect();

                var to = destRect.top - bodyRect.top;
                var from = eleRect.top - bodyRect.top;

                let animation = new Animate(from, to, 1000, Easings.EaseInOutCirc);
                animation.start(value => window.scrollTo(null, value));
            });
        }
    }

    private static setContactFormFocus(): void {
        let form = this.contactForm;
        let inputs = form.querySelectorAll("input[type=text]");
        let textarea = form.querySelector("textarea");

        for (var i = 0; i < inputs.length; i++) {
            let input = inputs[i] as HTMLInputElement;

            if (input.value == "") {
                input.focus();
                return;
            }
        }

        if (textarea.value.length == 0) {
            textarea.focus();
        } else {
            let submitBtn = form.querySelector("[type=submit]") as HTMLElement;

            if (submitBtn) submitBtn.focus();
        }
    }

    private static get scrollTop(): number {
        return window.scrollY;
    }

    private static set scrollTop(value: number) {
        window.scrollTo(null, value);
    }

    private static trackAviso(): void {
        var hidden = $("[data-tracker-statistics]");
        if (hidden.data("tracker-statistics") === true) {

            var url = hidden.data("tracker-statistics-url");
            var visibilidad = hidden.data("id-visibilidad");
            var vendedor = hidden.data("id-vendedor");
            var destaque = hidden.data("destaque");
            var idTipoOperacion = hidden.data("id-tipo-operacion");
            var idTipoPropiedad = hidden.data("id-tipo-propiedad");
            var idPais = hidden.data("id-pais");
            var idProvincia = hidden.data("id-provincia");
            var idPartido = hidden.data("id-partido");
            var idLocalidad = hidden.data("id-localidad");
            var idBarrio = hidden.data("id-barrio");
            var idSubBarrio = hidden.data("id-sub-barrio");
            var idSistemaOrigen = hidden.data("id-sistema");
            var dormitorios = hidden.data("dormitorios");
            var urlTracker = hidden.data("tracker-statistics-url-tracker") + $.track.default_options.url;

            idTipoOperacion = idTipoOperacion === 0 ? "" : idTipoOperacion;
            idPais = idPais === 0 ? "" : idPais;
            idProvincia = idProvincia === 0 ? "" : idProvincia;
            idPartido = idPartido === 0 ? "" : idPartido;
            idLocalidad = idLocalidad === 0 ? "" : idLocalidad;
            idBarrio = idBarrio === 0 ? "" : idBarrio;
            idSubBarrio = idSubBarrio === 0 ? "" : idSubBarrio;

            $.getJSON(url,
                function (response) {
                    var recperpage = 20;
                    var positionAbsolute = response.PosicionGeneral;
                    var positionCantidadDormitoriosAbsolute = response.PosicionCantidadDormitorios;
                    var pagenr = Math.ceil(positionAbsolute / recperpage);
                    var position = positionAbsolute - ((pagenr - 1) * recperpage);

                    $.track("ResBus_EntrarEnAviso",
                        {
                            pagenr: pagenr,
                            position: position,
                            recsperpage: recperpage,
                            posicionreal: positionAbsolute,
                            visibilidad: visibilidad,
                            vendedor: vendedor,
                            destaque: destaque,
                            idTipoOperacion: idTipoOperacion,
                            idTipoPropiedad: idTipoPropiedad,
                            idPais: idPais,
                            idProvincia: idProvincia,
                            idPartido: idPartido,
                            idLocalidad: idLocalidad,
                            idBarrio: idBarrio,
                            idSubBarrio: idSubBarrio,
                            idSesion: "",
                            idSistemaOrigen: idSistemaOrigen,
                            cantidadDormitorios: dormitorios,
                            posicionCantidadDormitorios: positionCantidadDormitoriosAbsolute
                        },
                        { url: urlTracker });
                });
        }
    }

    private static set ignoradoIcon(value: boolean) {
        const eles = document.querySelectorAll("[data-delete]");

        this.isIgnoradoClient = value;

        for (let i = 0; i < eles.length; i++) {
            const ele = eles.item(i) as HTMLElement;

            if (value) {
                ele.classList.add("active");
            } else {
                ele.classList.remove("active");
            }
        }
    };


    private static ignoradoInit() {
        const isIgnoradoUrlEle = document.getElementById("isIgnoradoUrl") as HTMLInputElement;

        if (!isIgnoradoUrlEle) return;

        const isIgnoradoUrl = isIgnoradoUrlEle.value;
        Ficha.discardPopup = new Popup("discardPopup");

        const ignoreAviso = (end?: () => void) => {
            const idAviso = parseInt(this.getIdAviso());

            Common.ToggleDelete(idAviso, this.idVisibilidad, result => {
                this.ignoradoIcon = result.Add;
                Ficha.discardPopup.close();

                if (!result.IsAuthenticated) {
                    Common.showLogin(() => ignoreAviso());
                } else {
                    end && end();
                }
            });
        };

        const setStatusFromServer = (callback?: () => void) => {
            fetch(isIgnoradoUrl, {
                credentials: "same-origin"
            }).then(res => {
                if (res.ok) return res.json();
                throw new Error("Error al intentar obtener si esta ignorado");
            }).then(data => {
                if (data) {
                    if (data.ignorado == true) {
                        this.ignoradoIcon = true;
                    } else {
                        if (callback) callback();
                    }
                }
            });
        };
        
        document.body.addEventListener("click", e => {
            const target = e.target as HTMLElement;

            const isDeleteIgnorado = target.hasAttribute("data-delete");
            const isDeleteOn = target.hasAttribute("data-delete-on");

            if (isDeleteIgnorado) {
                if (this.isIgnoradoClient) {
                    ignoreAviso();
                } else {
                    Common.showLogin(() => Ficha.discardPopup.show());
                }
            }

            if (isDeleteOn) {
                target.classList.add("btn-loading");
                ignoreAviso(() => target.classList.remove("btn-loading"));
            }
        });

        setStatusFromServer();
    }

    private static get similarPropertyContainer(): HTMLElement {
        return document.getElementById("similarPropertyContainer");
    }

    private static avisosSimilaresPartial() {
        const templateEle = document.getElementById("tmpl_item_aviso") as HTMLElement;

        if (!this.similarPropertyContainer) return;

        const url = this.similarPropertyContainer.getAttribute("data-url-partial");
        const btn = document.querySelector("[data-similar-properties-button]") as HTMLElement;

        let visibleSimilar = (value: boolean) => {
            let cssVal = value ? "block" : "none";

            btn.style.display = cssVal;
            this.similarPropertyContainer.style.display = cssVal;
        };

        visibleSimilar(false);

        fetch(url, {
            credentials: "same-origin",
            method: "GET"
        }).then<AvisosSimilaresResponse>(response => {
            if (response.ok) return response.json();
        })
            .then(data => {
                if (!data) return;
                const template = templateEle.innerHTML;

                let options = this.getSimilares(data.Results);
                let html = Mustache.render(template, options);

                this.similarPropertyContainer.innerHTML = html;
                visibleSimilar(true);
            });
    }

    private static getSimilares(data: Array<Aviso>) {

        var indexNumber = 0;
        const labelNoPrice = this.similarPropertyContainer.getAttribute("data-no-price-label");
        return {
            items: data,
            "HtmlId": function () {
                indexNumber++;
                let htmlId = "prop-similares-" + indexNumber;
                return htmlId;
            },

            "ExpensasPrice": function () {
                let expensasPrice = "";
                if (this)
                    expensasPrice = `${this.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;

                return expensasPrice;
            },

            "Price": function () {
                let price = labelNoPrice;
                if (this.MontoOperacion_i)
                    price = `${this.MontoOperacion_i.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;

                return price;
            },

            "UrlPhoto": function () {
                let url;
                const multimedia = this.Multimedia_s.filter(function (m) {
                    return m.IdTipoMultimedia == 1;
                })[0];

                if (!multimedia) url = "/content/images/ficha-camera.svg";
                else url = multimedia.Xsmall_U;
                return url;
            },
            "Description": function () {
                let description = `${this.TipoPropiedad_t.replace("Depto Tipo Casa", "Ph")} en ${this.TipoOperacion_t.toString().toLowerCase()}`;
                if (this.SubBarrio_t)
                    description += ` en ${this.SubBarrio_t}, ${this.Localidad_t}`;
                else if (this.Barrio_t)
                    description += ` en ${this.Barrio_t}, ${this.Localidad_t}`;
                else
                    description += ` en ${this.Localidad_t}`;

                return description;
            },
            "UrlProperty": function () {
                let descripcionSeoText = "aviso";

                if (this.DescripcionSeo_t) {
                    descripcionSeoText = this.DescripcionSeo_t.split("--")[1];

                    descripcionSeoText = descripcionSeoText.replace("DeptoTipoCasa", "Ph")
                        .replace("-/-", "-")
                        .replace(" / ", "-")
                        .replace("/", "-")
                        .replace(" ", "-");
                }

                return `${descripcionSeoText.toLowerCase()}--${this.IdAviso}`;
            },
            "DatosComunes": function () {
                let item = this as Aviso;

                let showValor = value => {
                    return value &&
                        value != "" &&
                        value.trim() != "-" &&
                        value.trim() != "0" &&
                        value.trim() != "0 m" &&
                        value.trim() != "0 m²" &&
                        value.trim() != "|";
                };

                let items = item.DatosComunes_s.filter(dato => {
                    let ret = dato.IdSistemas.indexOf(3) != 1 &&
                        dato.TipoDatoComun &&
                        showValor(dato.Valor);

                    if (ret) {
                        dato.TipoDatoComun = dato.TipoDatoComun.toLowerCase();
                        return ret;
                    }
                });

                return items.slice(0, 3);
            }
        }
    }
}

class ShowPhoneResponse {
    phone: string;
    result: boolean;
    isAuthenticated: boolean;
}

class IsIgnoradoResponse {
    ignorado: boolean;
    isAuthenticated: boolean;
}

class AvisosSimilaresResponse {
    Results: Array<Aviso>;
}

class Aviso {
    IdAviso: string;
    MontoOperacion_i: number;
    Expensas_i: number;
    MonedaSimbolo_t: string;
    DescripcionSeo_t: string;
    TipoOperacion_t: string;
    TipoPropiedad_t: string;
    Localidad_t: string;
    Barrio_t: string;
    SubBarrio_t: string;
    MonedaSimboloExpensas_t: string;
    PublicaPrecio_b: boolean;
    Titulo_t: string;
    Direccion_NombreCalle_t: string;
    Direccion_NumeroRedondeado_i: string;
    DatosComunes_s: Array<DatosComunes>;
    Multimedia_s: Array<Multimedia>;
}

class DatosComunes {
    Leyenda: string;
    Valor: string;
    Abreviatura: string;
    AbreviaturaLeyenda: string;
    IdSistemas: Array<number>;
    TipoDatoComun: string;
}

class Multimedia {
    Id: number;
    IdTipoMultimedia: number;
    Url: string;
    Xsmall_U: string;
}

class IsFavoriteResponse {
    Favorito: boolean;
}