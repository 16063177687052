import { Line } from './line';

export function simplifyGeometry(points: number[][], tolerance: number): number[][]{
    let dmax = 0;
    let index = 0;
    let results: number[][];

    for (let i = 1; i <= points.length - 2; i++){
        let line = new Line(points[0], points[points.length - 1]);
        let d = line.perpendicularDistance(points[i]);

        if (d > dmax) {
            index = i;
            dmax = d;
        }
    }

    if (dmax > tolerance){
        let results_one = simplifyGeometry(points.slice(0, index), tolerance);
        let results_two = simplifyGeometry(points.slice(index, points.length), tolerance);
        
        results = results_one.concat(results_two);
    } else if (points.length > 1) {
        results = [points[0], points[points.length - 1]];
    } else {
        results = [points[0]];
    }

    return results;
}
