
export class Cookie{
    static get(key: string): string{
        let name = encodeURIComponent(key);
        
		let regexp = new RegExp('(?:^' + name + '|;\\s*' + name + ')=(.*?)(?:;|$)', 'g');
		let result = regexp.exec(document.cookie);

        return (result === null) ? null : decodeURIComponent(result[1]);
    }

    static set(key: string, value: string, expires?: number, path?: string, domain?: string, secure?: boolean){
        let cookieStr = encodeURIComponent(key) + '=' + encodeURIComponent(value) + ';';
		
		if (expires) {
			let dtExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);
			cookieStr += 'expires=' + dtExpires.toUTCString() + ';';
		}
		if (path) {
			cookieStr += 'path=' + path + ';';
		}
		if (domain) {
			cookieStr += 'domain=' + domain + ';';
		}
		if (secure) {
			cookieStr += 'secure;';
		}

        document.cookie = cookieStr;
    }

    static delete(key: string, path?: string, domain?: string){
        if (Cookie.get(key)) {
			Cookie.set(key, '', -1, path, domain);
        }
    }
}
