
class Tab {
    private tabContainer: Element;
    private currentTabContent: Element;

    constructor(private container: Element) {
        this.tabContainer = this.container.nextElementSibling;
    }

    init() {
        this.container.addEventListener("click", e => {
            let target = e.target as HTMLElement;

            if (target.tagName == "A" && !target.classList.contains('button')) {
                e.preventDefault();

                if (!target.classList.contains("disabled")) this.tabClicked(target as HTMLAnchorElement);
            }
        });
    }

    private tabClicked(ele: HTMLAnchorElement) {
        let id = ele.getAttribute("href");
        
        this.activeTab(id);
        this.showContent(id);
        this.resetContent(id);
    }
    
    private activeTab(id: string): void {
        let anchorSelected = this.container.querySelector(`[href="${id}"]`) as HTMLAnchorElement;
        let liSelected = anchorSelected.parentElement;
        let url = liSelected.getAttribute("data-url");
        let lis = this.container.querySelectorAll("li");

        for (var i = 0; i < lis.length; i++) {
            let li = lis[i] as HTMLLIElement;

            li.classList.remove("active");
        }

        liSelected.classList.add("active");

        history.pushState(null, null, url);
    }

    private showContent(id: string): void {
        this.currentTabContent = this.tabContainer.querySelector(`[data-tab="${id}"]`);
        let tabsContent = this.tabContainer.querySelectorAll(".tab-content");

        for (let i = 0; i < tabsContent.length; i++) {
            let t = tabsContent[i] as HTMLElement;

            t.style.display = "none";
        }

        if (this.currentTabContent) {
            let tabContent = this.currentTabContent as HTMLElement;

            tabContent.style.display = "block";
        }
    }

    private resetContent(id: string): void {
        if (!this.currentTabContent) return;

        const inputs = this.currentTabContent.querySelectorAll("input");
        let focused = false;

        for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i];
            
            switch (input.type) {
                case "text":
                case "password":
                    input.value = null;

                    if (!focused) {
                        input.focus();
                        focused = true;
                    }

                    break;
            }

            input.classList.add("input-validation-valid");
            input.classList.remove("input-validation-error");
        }

        const fieldsError = this.currentTabContent.querySelectorAll(".field-validation-error");

        for (let n = 0; n < fieldsError.length; n++) {
            const field = fieldsError[n] as HTMLElement;

            field.classList.add("field-validation-valid");
            field.classList.remove("field-validation-error");
        }
    }
}

export class Tabs {
    static initialize(selector?: string) {
        let container = selector ? document.querySelector(`#${selector}`) : document.body;
        let tabs = container.querySelectorAll(".tabs-nav");

        for (var i = 0; i < tabs.length; i++) {
            let tabEle = tabs[i];

            let tab = new Tab(tabEle);

            tab.init();
        }
    }
}
