import { Common } from "./components/common";
import { Notifications } from "./components/notifications";
import { AjaxBehaviour } from "./components/ajax-behaviour";
import { ShareUtils } from "./components/share-utils";
import { GeneralHandlers } from "./components/general-handlers";
import { MyAccount } from "./components/my-account";
import { MyAccountData } from "./components/my-account-data";
import { MyAccountNotices } from "./components/my-account-notices";
import { MyAccountDelete } from "./components/my-account-delete";
import { Chat } from "./components/chat";
import { Creditos } from "./components/creditos";
import { BannerExpandable } from "./components/banner-expandable";
import { DynamicTagManager } from "./components/dynamic-tag-manager";
import { Publish } from "./components/publish";
import { TextArea } from "./components/common/textarea";
import { Footer } from "./components/common/footer";

export function initialize() {

    document.addEventListener("DOMContentLoaded", e => {
        AjaxBehaviour.initialize();
        Common.initialize();
        Notifications.initialize();
        ShareUtils.initialize();
        GeneralHandlers.initialize();
        MyAccount.initialize();    
        MyAccountNotices.initialize();
        MyAccountData.initialize();
        MyAccountDelete.initialize();
        Chat.initialize();
        Creditos.initialize();
        BannerExpandable.initialize();
        DynamicTagManager.initialize();        
        Publish.initialize();
        TextArea.initialize();
        Footer.initialize();
    });
}
