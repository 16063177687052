export class GeneralHandlers {

    static initialize() {
        this.collapsable();
        this.trimWhiteSpace();
    }

    static collapsable() {
        const triggers = document.querySelectorAll("[data-toggle=collapse]");

        for (let i = 0; i < triggers.length; i++) {
            const trigger = triggers[i];
            const targetId = trigger.getAttribute("data-target");
            const target = document.querySelector(targetId);

            trigger.addEventListener("click", e => {
                e.preventDefault();

                target.classList.toggle("collapse");
                trigger.classList.toggle("collapsed");
            });
        }
    }

    static trimWhiteSpace() {
        const inputs = document.querySelectorAll("[data-trim-whitespace]");
        if (!inputs) return;

        for (let i = 0; i < inputs.length; i++) {
            const input = inputs[i];
            input.addEventListener("keydown", (evt: KeyboardEvent) => {
                var code = evt.keyCode;
                if (code === 32) //Space
                    evt.preventDefault();
            });
        }
    }

}