
export class Publish {
    private static localidad: HTMLInputElement;
    private static barrio: HTMLInputElement;
    private static noPublicaPrecio: HTMLInputElement;

    public static initialize() {
        const wizardPublish = document.querySelector("[data-wizard-publish]");
        if (wizardPublish === null) return;
        this.init();
    }

    private static init(): void {
        this.localidad = document.querySelector("#Direccion_IdLocalidad") as HTMLInputElement;
        this.barrio = document.querySelector("#Direccion_IdBarrio") as HTMLInputElement;
        this.noPublicaPrecio = document.querySelector("#hdNoPublicaPrecio") as HTMLInputElement;

        this.addMethodValidateNoPublicaPrecio();
        this.addMethodToValidateNeighborhoodLocation();
    }

    private static addMethodToValidateNeighborhoodLocation(): void {
        const CapitalFederal = "LOCALIDAD_2102";

        $.validator.addMethod('validationneighborhoodlocation', (value, element) => {
            if (this.localidad.value == CapitalFederal && element == this.barrio) {
                return value != "";
            }

            return true;
        });

        $.validator.unobtrusive.adapters.add(
            'validationneighborhoodlocation', function (options) {
                options.rules['validationneighborhoodlocation'] = "";
                options.messages['validationneighborhoodlocation'] = options.message;
            });
    }


    private static addMethodValidateNoPublicaPrecio(): void {
        $.validator.addMethod('nopublicaprecio', (value, element) => {
            if (this.noPublicaPrecio.value === "True") {
                return true;
            }
            else {
                if (value != "")
                    return true;
            }
            return false;
        });

        $.validator.unobtrusive.adapters.add(
            'nopublicaprecio', function (options) {
                options.rules['nopublicaprecio'] = "";
                options.messages['nopublicaprecio'] = options.message;
            });
    }

}
