
class DisableEle {
    private disableElement: HTMLInputElement;

    constructor(public ele: HTMLInputElement) {
        let deId = ele.getAttribute("data-disable-element");
        this.disableElement = document.getElementById(deId) as HTMLInputElement;
    }

    init() {
        this.ele.addEventListener("change", e => this.eleChange(e));

        if (this.disableElement.value == "0") {
            this.ele.checked = true;
            this.eleChange(null);
        }
    }

    private eleChange(e: Event) {
        if (this.ele.checked) {
            this.disableElement.value = "0";
            this.disableElement.setAttribute("readonly", "readonly");
        } else {
            this.disableElement.removeAttribute("readonly");
        }
    }
}

export class DisableElement {
    public static initialize(selector?: string) {
        let scope = selector ? document.querySelector(`#${selector}`) :
            document.body;

        let eles = scope.querySelectorAll("[data-disable-element]");

        for (let i = 0; i < eles.length; i++) {
            let ele = eles.item(i) as HTMLInputElement;
            let disableEle = new DisableEle(ele);

            disableEle.init();
        }
    }
}
