
export class Carousels {
    static initialize(selector?: string) {
        const prefixSelector = (selector) ? `#${selector} ` : "";

        this.initCarousel(prefixSelector)

        if ($(".slick-initialized").length > 0) return;

        const slider = $(`${prefixSelector}.property-slider`);

        slider.slick({
            slidesToShow: 1,
            lazyLoad: "ondemand",
            prevArrow: "<button class='slick-arrow slick-prev'><i class='basico1-icon-angle_left_bold'></i></button>",
            nextArrow: "<button class='slick-arrow slick-next'><i class='basico1-icon-angle_right_bold'></i></button>"
        });

        slider.slick('slickFilter', '.foto-slide');

        slider.on('lazyLoadError', (e, slick, currentSlide) => {
            currentSlide.attr('src', "/content/images/ficha-camera.svg");
        });

        slider.on("afterChange", (e, slick, currentSlide) => {
            const currentSlideEle = document.querySelector("[data-current-slide]") as HTMLElement;

            if (currentSlideEle) {
                currentSlideEle.textContent = currentSlide + 1;
            }
        });

        // Le doy foco a la primera imagen para que se puedan usar las flechas y pasar de imagen
        slider.find(".slick-track a")
            .first()
            .focus();
    }

    private static initCarousel(containerSelector: string): void {
        const container = containerSelector === "" ?
            document.body :
            document.querySelector(containerSelector);

        if (container == null)
            return;

        const carousels = container.querySelectorAll("[data-carousel]");

        for (var i = 0; i < carousels.length; i++) {
            const ele = carousels.item(i);

            let carousel = new Carousel(ele),
                parent = ele.parentElement,
                prev = parent.querySelector("[data-prev]"),
                next = parent.querySelector("[data-next]");

            const setPrevNextState = index => {
                prev.classList.remove("disabled");
                next.classList.remove("disabled");

                if (index === 0) prev.classList.add("disabled");
                if (index === carousel.count() - 1) next.classList.add("disabled");
            };

            // No es lo mas bonito pero va a funcionar hasta que se cambien de nuevo las cosas
            if (prev && next) {
                carousel.onChange(index => setPrevNextState(index));

                prev.addEventListener("click", e => {
                    e.preventDefault();
                    carousel.prev();
                });

                next.addEventListener("click", e => {
                    e.preventDefault();
                    carousel.next();
                });

                // Inicializo en la posicion cero
                setPrevNextState(0);
            }
        }
    }
}
