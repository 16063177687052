import { Animate, Easings } from "./animate";

export class Thumbnail {
    private onclick: (index: number) => void;
    private canMove = false;
    private hasMove = false;
    private startScrollLeft = 0;
    private startX = 0;

    constructor(private ele: HTMLElement) {
        ele.addEventListener("mousedown", (e: MouseEvent) => this.handleMouseDown(e));
        ele.addEventListener("mousemove", (e: MouseEvent) => this.handleMouseMove(e));
        ele.addEventListener("mouseup", (e: MouseEvent) => this.handleMouseUp(e));
    }

    onClick(fn: (index: number) => void) {
        this.onclick = fn;
    }

    move(index: number): void {
        this.center(index);
    }

    private center(index: number): void {
        const item = this.ele.children.item(index) as HTMLElement;
        const mid = this.ele.offsetWidth / 2;
        const value = item.offsetLeft - this.ele.offsetLeft - mid + (item.offsetWidth / 2);

        const animation = new Animate(this.ele.scrollLeft, value, 250, Easings.EaseInOutCubic);

        animation.start(newValue => this.ele.scrollLeft = newValue, end => {
            this.ele.scrollLeft = end
            this.selectItem(item);
        });
    }

    private handleMouseDown(e: MouseEvent) {
        this.startScrollLeft = this.ele.scrollLeft;
        this.startX = e.pageX - this.ele.offsetLeft;
        
        this.canMove = true;
        this.ele.classList.add("grabbing");
    }

    private handleMouseMove(e: MouseEvent) {
        if (this.canMove) {
            e.preventDefault();

            const value = this.startX - (e.pageX - this.ele.offsetLeft);
            this.ele.scrollLeft = this.startScrollLeft + value;

            if (value !== 0) this.hasMove = true;
        }
    }

    private handleMouseUp(e: MouseEvent) {
        this.canMove = false;

        if (!this.hasMove && this.onclick) {
            const target = e.target as HTMLElement;

            if (target.tagName.toLowerCase() === "li") {
                const index = this.getElementIndex(target);

                this.onclick(index);
                this.center(index);
            }
        }
        
        this.hasMove = false;
        this.ele.classList.remove("grabbing");
    }

    private selectItem(ele: HTMLElement) {
        for (var i = 0; i < this.ele.childElementCount; i++) {
            const item = this.ele.children.item(i);

            item.classList.remove("active");

            if (item === ele) item.classList.add("active");
        }
    }

    private getElementIndex(ele: Element): number {
        let i = 0;
        let newEle = ele;

        while (newEle)
        {
            newEle = newEle.previousElementSibling;
            i++;
        }

        return i - 1;
    }
}
