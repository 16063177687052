
export class MaterialControl {

    static initialize(selector: string | HTMLElement) {
        let isElement = selector instanceof HTMLElement;
        let container: HTMLElement;

        if (isElement) {
            container = <HTMLElement>selector;
        } else if (selector) {
            container = document.querySelector(`#${selector}` as string) as HTMLElement;
        } else {
            container = document.body;
        }

        let eles = container.querySelectorAll(".material-control");
        
        for (var i = 0; i < eles.length; i++) {
            let ele = eles.item(i) as HTMLElement;
            new MaterialControl(ele);
        }
    }

    constructor(public container: HTMLElement) {
        let isAlreadyBinded = this.control.hasAttribute("data-binded");
        
        if (isAlreadyBinded) return;

        let form = this.control.closest("form");
        this.control.setAttribute("data-binded", "");
        this.filled = this.length > 0;

        this.control.addEventListener("focus", () => {
            this.active = true;
        });

        this.control.addEventListener("blur", () => {
            this.active = false;
            this.filled = this.length > 0;
        });

        if (form) {
            form.addEventListener("reset", e => {
                setTimeout(() => this.filled = this.length > 0, 0);
            });
        }        

        if (this.errorContainer) {
            let observer = new MutationObserver((mutations) => {
                for (var i = 0; i < mutations.length; i++) {
                    let mutation = mutations[i];
                    let target = mutation.target as HTMLElement;
                    
                    if (mutation.attributeName == "class") {
                        this.error = target.classList.contains("field-validation-error");
                    }
                }
            });

            observer.observe(this.errorContainer, {
                attributes: true             
            });
        }
    }

    get control(): HTMLElement {
        return this.container.querySelector("input[type=email], input[type=text], input[type=password], textarea") as HTMLElement;
    }

    get errorContainer(): HTMLSpanElement {
        return this.container.querySelector("span") as HTMLSpanElement;
    }

    get length(): number {
        let ele = <any>this.control;
        return ele.value.length;
    }

    get active(): boolean {
        return this.container.classList.contains("material-control--active");
    }

    set active(value: boolean) {
        if (value) {
            this.container.classList.add("material-control--active");
        } else {
            this.container.classList.remove("material-control--active");
        }
    }
    
    get filled(): boolean {
        return this.container.classList.contains("material-control--filled");
    }

    set filled(value: boolean) {
        if (value) {
            this.container.classList.add("material-control--filled");
        } else {
            this.container.classList.remove("material-control--filled");
        }
    }

    set error(value: boolean) {
        if (value) {
            this.container.classList.add("material-control--error");
        } else {
            this.container.classList.remove("material-control--error");
        }
    }
    
}
