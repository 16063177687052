
export class Footer {
    static container: HTMLElement;

    public static initialize(selector?: string) {
        this.container = selector ? document.getElementById(selector) : document.body;
        this.adjustFooter();

        const config: MutationObserverInit = {
            subtree: true,
            childList: false,
            attributes: true,
            attributeFilter: ['style']
        };

        let localAdjustFnc = function () {
            Footer.resetFooter()
            Footer.adjustFooter();
        }

        const callback: MutationCallback = function (mutationsList: Array<MutationRecord>, observer: any) {
            for (let mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.target.ELEMENT_NODE === Node.ELEMENT_NODE) {
                    let element: HTMLElement = <HTMLElement>mutation.target;
                    if (element.id.indexOf('content') !== -1 ||
                        element.classList.contains("progress-bar") === true) {
                        localAdjustFnc();
                    }
                }
            }
        };

        const observer: MutationObserver = new MutationObserver(callback);
        observer.observe(this.container, config);

        //Tambien cargar cuando se cargue incialmente la pantalla.
        localAdjustFnc();
    }

    public static adjustFooter() {
        const footerElements: HTMLCollectionOf<HTMLElement> = document.getElementsByTagName("footer");
        const headerElements: HTMLCollectionOf<HTMLElement> = document.getElementsByTagName("header");

        let headerHeight: number = 0;

        if (headerElements.length > 0) {
            headerHeight = headerElements[0].clientHeight;
        }

        const windowHeight: number = document.documentElement.clientHeight;
        const bodyHeight: number = document.body.scrollHeight + headerHeight;

        if (footerElements.length > 0) {
            const footerEle = footerElements[0];
            if (windowHeight >= bodyHeight) {
                this.addWhiteSpace(footerEle, windowHeight - bodyHeight);
            } else {
                this.resetFooter();
            }
        }
    }

    private static resetFooter() {
        let emptySpace = document.getElementById("footer-whitespace");
        if (emptySpace != null) {
            emptySpace.remove();
        }
    }

    private static addWhiteSpace(element: HTMLElement, height: number) {
        const emptyNode: HTMLElement = this.getOrCreateEmptyDiv(height);
        element.parentElement.insertBefore(emptyNode, element);
    };

    private static getOrCreateEmptyDiv(height: number): HTMLElement {
        let elementExist: HTMLElement = document.getElementById("footer-whitespace");

        if (elementExist == null) {
            const emptyNode: HTMLElement = document.createElement("div");
            emptyNode.setAttribute("id", "footer-whitespace");
            emptyNode.style.height = `${height}px`;
            elementExist = emptyNode;
        } else {
            elementExist.style.height = `${elementExist.style.height + height}px`;
        }
        return elementExist;
    }
}