import { Map } from "./common/map";
import { Autocomplete } from "./common/autocomplete";
import { CommonHelper } from "common/common-helper";

interface CoordenadasResponse {
    Coordenadas: {
        Latitud: number,
        Longitud: number
    };
    Existe: boolean;
}

export class MyAccountData {
    private static container: Element;
    private static map: Map;

    private static maxHeigth: number = 300;
    private static maxWidth: number = 300;
    private static maxFileSize: number = 1 * 1024 * 1024; // 1 Mb

    private static pais: HTMLInputElement;
    private static provincia: HTMLInputElement;
    private static partido: HTMLInputElement;
    private static localidad: HTMLInputElement;
    private static barrio: HTMLInputElement;
    private static subBarrio: HTMLInputElement;
    private static idCalle: HTMLInputElement;
    private static direccionCalle: HTMLInputElement;
    private static direccionNumeroCalle: HTMLInputElement;
    private static direccionLatitud: HTMLInputElement;
    private static direccionLongitud: HTMLInputElement;
    private static inmobiliariaCuit: HTMLInputElement;
    private static particularDNI: HTMLInputElement;
    private static particularTipoDoc: HTMLSelectElement;
    private static particularTipoIva: HTMLSelectElement;
    private static botonGuardar: HTMLButtonElement;
    private static emailContacto: HTMLInputElement;
    private static invoiceLoader: HTMLDivElement;
    private static emailContactoOri: HTMLInputElement;

    public static initialize() {
        this.container = document.querySelector("#invoice-form");
        if (!this.container) return;
        if (this.container.hasAttribute("binded")) return;

        this.init()
        this.container.setAttribute("binded", "true");
    }
    

    private static score() {
        const groups = document.querySelectorAll("[data-score-group]");
        if (groups.length == 0) return;

        let total = 0;
        let index = 0;

        const speedElem = document.querySelector<HTMLElement>("[data-speed]");
        const speedInput = speedElem.querySelector<HTMLElement>("#speedbox-score");
        const totalElem = document.querySelectorAll<HTMLElement>("[data-score-total]");

        speedElem.classList.remove("speedbox__success", "speedbox__warning", "speedbox__red");

        groups.forEach(group => {
            index++;
            let score = 0;
            const resume = document.querySelector(`#score-${index}`); 
            const scoreGroup = +group.getAttribute("data-score-group");
            const items = group.querySelectorAll<HTMLInputElement>("[data-score-item]");

            items.forEach(item => {
                if (item.hasAttribute("data-check-input")) {
                    if (item.checked)
                        score += +item.getAttribute("data-score-item"); 
                }             
                else if (item.value !== "")
                    score += +item.getAttribute("data-score-item"); 
            });         
                       
            const icon = resume.querySelector<HTMLElement>("[data-score-icon]");
            icon.classList.remove("icon-success", "icon-warning", "icon-red");  

            const chip = group.querySelector<HTMLElement>("[data-score-chip]");
            chip.classList.remove("chip-success", "chip-warning", "chip-red");     

            if (score == 0) {
                chip.classList.add("chip-red");
                icon.classList.add("icon-red");
            }
            else if (score == scoreGroup) {
                chip.classList.add("chip-success");
                icon.classList.add("icon-success");
            }
            else {
                chip.classList.add("chip-warning");    
                icon.classList.add("icon-warning");           
            }       
            
            const totalResume = resume.querySelector<HTMLElement>("[data-resume-total]");
            const totalElem = group.querySelector<HTMLElement>("[data-score-group-total]");            
            totalElem.innerText = score.toString();      
            totalResume.innerText = score.toString();

            total += score;
        });

        totalElem.forEach(ele => ele.innerText = total.toString());

        const updatedSpeed = Math.round(total * 180 / 100) - 45;
        speedInput.style.transform = `rotate(${updatedSpeed}deg)`;

        if (total >= 90)        speedElem.classList.add("speedbox__success");
        else if (total >= 40)   speedElem.classList.add("speedbox__warning"); 
        else speedElem.classList.add("speedbox__red"); 
    }

    private static location() {
        
        this.direccionLatitud = this.container.querySelector<HTMLInputElement>("[data-control-direccion-latitud]");
        this.direccionLongitud = this.container.querySelector<HTMLInputElement>("[data-control-direccion-longitud]");

        this.pais = document.querySelector<HTMLInputElement>("#Direccion_IdPais");
        const customSelectPais = this.pais.parentElement.getElementsByClassName('custom-select-container')[0];
        if (customSelectPais) customSelectPais.setAttribute("id", "Direccion.IdPais.Custom");

        this.provincia = document.querySelector<HTMLInputElement>("#Direccion_IdProvincia");
        const customSelectProvincia = this.provincia.parentElement.getElementsByClassName('custom-select-container')[0];
        if (customSelectProvincia) customSelectProvincia.setAttribute("id", "Direccion.IdProvincia.Custom");

        this.partido = document.querySelector<HTMLInputElement>("#Direccion_IdPartido");
        const customSelectPartido = this.partido.parentElement.getElementsByClassName('custom-select-container')[0];
        if (customSelectPartido) customSelectPartido.setAttribute("id", "Direccion.IdPartido.Custom");

        this.localidad = document.querySelector<HTMLInputElement>("#Direccion_IdLocalidad");
        const customSelectLocalidad = this.localidad.parentElement.getElementsByClassName('custom-select-container')[0];
        if (customSelectLocalidad) customSelectLocalidad.setAttribute("id", "Direccion.IdLocalidad.Custom");

        this.barrio = document.querySelector<HTMLInputElement>("#Direccion_IdBarrio");
        const customSelectBarrio = this.barrio.parentElement.getElementsByClassName('custom-select-container')[0];
        if (customSelectBarrio) customSelectBarrio.setAttribute("id", "Direccion.IdBarrio.Custom");

        this.subBarrio = document.querySelector<HTMLInputElement>("#Direccion_IdSubBarrio");
        const customSelectSubBarrio = this.subBarrio.parentElement.getElementsByClassName('custom-select-container')[0];
        if (customSelectSubBarrio) customSelectSubBarrio.setAttribute("id", "Direccion.IdSubBarrio.Custom");

        this.idCalle = document.querySelector<HTMLInputElement>("#Direccion_IdCalle");
        this.direccionCalle = document.querySelector<HTMLInputElement>("[data-nombre-calle]");
        this.direccionNumeroCalle = document.querySelector<HTMLInputElement>("[data-control-direccion-numero]");

        this.addMethodToValidateNeighborhoodLocation();

        if (this.direccionCalle && this.direccionNumeroCalle) {
            this.direccionNumeroCalle.addEventListener("blur", e => this.searchLatLngFromDireccion());

            if (this.direccionLatitud.value
                && this.direccionLongitud.value
                && parseFloat(this.direccionLatitud.value) != 0
                && parseFloat(this.direccionLongitud.value) != 0) {
                let lat = this.direccionLatitud.value.replace(",", ".");
                let lng = this.direccionLongitud.value.replace(",", ".");

                this.initMap();

                this.Latitud = parseFloat(lat);
                this.Longitud = parseFloat(lng);

                this.updateMapMarker();
                this.map.available = true;
            } else
                this.searchLatLngFromDireccion()
        }

        this.initAutocomplete(); 
    }  

    private static initOthersInputs() {

        this.botonGuardar = document.querySelector<HTMLButtonElement>("#invoice-save");

        this.particularTipoIva = document.querySelector<HTMLSelectElement>('#Particular_IdCondicionIva');
        this.particularTipoDoc = document.querySelector<HTMLSelectElement>('#Particular_IdTipoDocumento');

        this.emailContactoOri = document.querySelector<HTMLInputElement>('#emailOri');
        this.emailContacto = document.querySelector<HTMLInputElement>('#DatosContacto_Email');
        this.inmobiliariaCuit = document.querySelector<HTMLInputElement>("#Inmobiliaria_Cuit");

        this.botonGuardar.addEventListener("click", async e => {
            await this.validateForm(e);
        });

        if (this.emailContacto != null) {
            this.emailContacto.addEventListener("change", e => { this.elementoEmail(this.emailContacto) });
            this.emailContacto.addEventListener("focusout", e => { this.elementoEmail(this.emailContacto) });
            this.elementoEmail(this.emailContacto);
        }

        if (this.inmobiliariaCuit != null) {
            this.inmobiliariaCuit.addEventListener("change", e => { this.elementoCuit(this.inmobiliariaCuit) });
            this.inmobiliariaCuit.addEventListener("focusout", e => { this.elementoCuit(this.inmobiliariaCuit) });
            this.elementoCuit(this.inmobiliariaCuit);
        }

        this.particularDNI = document.querySelector("#Particular_NumeroDocumento") as HTMLInputElement;
        if (this.particularDNI != null) {
            this.particularDNI.addEventListener("change", e => { this.elementoDNI(this.particularDNI) });
            this.particularDNI.addEventListener("focusout", e => { this.elementoDNI(this.particularDNI) });
            this.botonGuardar.addEventListener("mouseout", e => { this.updateCondicionTipoIva() });

            this.particularTipoIva.addEventListener("change", e => { this.updateCondicionTipoIva() });
            this.particularTipoDoc.addEventListener("change", e => { this.elementoLabelNumero(this.particularTipoDoc.options.selectedIndex) });

            if (this.particularTipoDoc != null) this.elementoLabelNumero(this.particularTipoDoc.options.selectedIndex);
        }
        if (this.particularTipoIva != null)
            this.updateCondicionTipoIva();

        const tab = document.querySelector("[data-tab-anunciante]");
        tab.addEventListener("click", () => {
            window.dispatchEvent(new Event('resize'));
            if (this.map)
                this.map.invalidateSize();
        });
    }

    private static async init(): Promise<void> {    
        this.score();          
        this.location();        
        this.initOthersInputs();

        document.addEventListener("click", async e => {
            const target = e.target as HTMLElement;
            const tooltip = document.querySelector("[data-form-tooltip]");

            tooltip.classList.remove("show");

            if (target.hasAttribute("data-map-container")) {
                target.classList.add("unlock-zoom");
                return;
            } 

            if (target.hasAttribute("data-tooltip-trigger")) { 
                tooltip.classList.add("show")               
                return;
            }               
        })

        document.addEventListener("change", e => {
            const target = e.target as Element;

            if (target.hasAttribute("data-upload-logo")) {
                this.logoThumbnail(e);
                return;
            }
        });

        document.addEventListener("partialAjaxComplete", () => {
            this.score();
            this.location();
            this.initOthersInputs();
        });
        document.addEventListener("notificationAjaxComplete", () => this.score()); 
    }

    private static addMethodToValidateNeighborhoodLocation(): void {
        const CapitalFederal = "2102";
        $.validator.addMethod('validationneighborhoodlocation', (value, element) => {
            if (this.localidad.value == CapitalFederal && element == this.barrio) {
                return value != "";
            }

            return true;
        });

        $.validator.unobtrusive.adapters.add(
            'validationneighborhoodlocation', function (options) {
                options.rules['validationneighborhoodlocation'] = "";
                options.messages['validationneighborhoodlocation'] = options.message;
            });
    }

    private static initMap(): void {
        let mapContainer = document.querySelector("[data-map]");

        if (mapContainer) {
            let isLoaded = mapContainer.hasAttribute("data-map-loaded");

            if (!isLoaded) {
                mapContainer.setAttribute("data-map-loaded", "");
                mapContainer.classList.add("show-map");

                this.map = new Map(mapContainer);
                this.map.init();

                this.map.onMarkerDragEnd((lat, lng) => {
                    this.Latitud = lat;
                    this.Longitud = lng;

                    this.map.setView(lat, lng);
                });
            }
        }
    }

    private static initAutocomplete(): void {
        if (!this.direccionCalle) return;

        let autocompleteUrl = this.direccionCalle.getAttribute("data-autocomplete-url");
        new Autocomplete(this.direccionCalle, {
            url: autocompleteUrl,
            autoSelect: false,
            highlight: true,
            dataText: "Nombre",
            dataValue: "Id",
            dataParameters: text => {
                let localidadId = this.localidad.value;

                if (!localidadId || !text) return;

                return {
                    "localidadId": localidadId,
                    "nombreCalle": text
                }
            },
            onSelect: (optionSelected) => {
                this.idCalle.value = optionSelected.id;
                this.searchLatLngFromDireccion();
            },
            onSelectCustomText: (text: string) => {
                this.idCalle.value = null;
                this.Latitud = this.Longitud = null;

                if (this.map) {
                    this.map.removeMarker();
                    this.map.available = false;
                }

                if (text != "")                   
                    this.direccionCalle.value = text;
                
                this.searchLatLngFromDireccion();
            }
        });
    }

    private static async searchLatLngFromDireccion() {
        this.initMap();

        if (this.direccionCalle.value != "" && this.direccionNumeroCalle.value != null) {
            let data = {
                NumeroCalle: this.direccionNumeroCalle.value,
                IdProvincia: this.provincia.value,
                IdPartido: this.partido.value,
                IdLocalidad: this.localidad.value,
                NombreCalle: this.direccionCalle.value
            };

            if (data.NombreCalle.length == 0 || data.NumeroCalle.length == 0) return;

            let coords = await this.getCoordenadas();

            if (coords) {
                this.Latitud = coords[0];
                this.Longitud = coords[1];

                this.updateMapMarker();
                this.map.available = true;
            } else {
                this.Latitud = this.Longitud = null;
                this.map.removeMarker();
                this.map.available = false;
            }
        } else {
            this.Latitud = this.Longitud = null;
            this.map.removeMarker();
            this.map.available = false;
        }
    }

    private static async getCoordenadas() {
        let sp = new URLSearchParams();
        let url = document.querySelector("[data-url-coordenadas]").getAttribute("data-url-coordenadas");

        if (this.direccionCalle.value.length == 0 || this.direccionNumeroCalle.value.length == 0) return null;

        sp.append("IdProvincia", this.provincia.value);
        sp.append("IdPartido", this.partido.value);
        sp.append("IdLocalidad", this.localidad.value);
        sp.append("NombreCalle", this.direccionCalle.value);
        sp.append("NumeroCalle", this.direccionNumeroCalle.value);

        var stringParams = sp.toString();

        url += `?${stringParams}`;

        return fetch(url, { method: "GET" }).then<CoordenadasResponse>(response => {
            if (response.ok) return response.json();
        }).then(response => {
            if (response.Existe) {
                return [response.Coordenadas.Latitud, response.Coordenadas.Longitud];
            }
        }).catch(reason => {
            return null;
        });
    }

    private static updateMapMarker(): void {
        this.map.setMarker(this.Latitud, this.Longitud, true);
        this.map.setView(this.Latitud, this.Longitud);
    }
    private static set Latitud(value: number) {
        this.direccionLatitud.value = value ? value.toString() : null;
    }
    private static get Latitud(): number {
        let value = this.direccionLatitud.value.replace(",", ".");
        return value ? parseFloat(value) : null;
    }
    private static set Longitud(value: number) {
        this.direccionLongitud.value = value ? value.toString() : null;
    }
    private static get Longitud(): number {
        let value = this.direccionLongitud.value.replace(",", ".");
        return value ? parseFloat(value) : null;
    }

    private static async logoThumbnail(evt: Event): Promise<void> {
        const target = evt.target as HTMLInputElement;

        const logo = this.container.querySelector("#logo-content"); 
        const logoError = this.container.querySelectorAll("[data-img-error]");
        const logoImg = this.container.querySelector<HTMLImageElement>("[data-logo] img");

        logoError.forEach(ele => {
            ele.classList.remove("show");

            const span = ele.querySelector<HTMLElement>("span");
            span.textContent = ""; 
        });     

        const file = target.files[0];
        if (!file) return; 

        const reader = new FileReader();
        const allowedExtension = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif'];

        const logoShowError = (error: string) => {
            logoError.forEach(ele => {
                ele.classList.add("show");

                const span = ele.querySelector<HTMLElement>("span");
                span.textContent = logo.getAttribute(`${error}`);
            });
        }

        if (allowedExtension.indexOf(file.type) == -1) {
            logoShowError("data-img-valid-extension")
            return;
        };

        if (file.size > this.maxFileSize) {  
            logoShowError("data-img-valid-size")
            return;
        };

        const { width, height } = await CommonHelper.getImageDimensions(file);

        if (width < this.maxWidth || height < this.maxHeigth) {
            logoShowError("data-img-valid-width")
            return;
        };    

        const loadImage = (e: ProgressEvent) => {
            reader.removeEventListener("load", loadImage);

            const input = this.container.querySelector<HTMLInputElement>("[data-logo] input");
            const fileReader = e.target as FileReader;
            const src = fileReader.result as string;
            logoImg.src = src;           
            input.value = src;

            document.dispatchEvent(new CustomEvent("notificationAjaxComplete"));
        };
    
        reader.addEventListener("load", loadImage);
        reader.readAsDataURL(file);   
    }

    private static updateCondicionTipoIva() {
        let object = this.particularTipoIva.options;
        let option = object[object.selectedIndex].value;
        this.elementoCondicionIva(option);
    };

    private static elementoCondicionIva(option) {
        this.particularTipoDoc.options[0].removeAttribute("disabled");
        this.particularTipoDoc.options[1].removeAttribute("disabled");
        this.particularTipoDoc.options[0].removeAttribute("selected");
        this.particularTipoDoc.options[1].removeAttribute("selected");
        switch (option) {
            case "1":
            case "2":
                this.particularTipoDoc.options[0].setAttribute("disabled", 'true');
                this.particularTipoDoc.options[1].setAttribute("selected", "true");
                break;
            case "3":
                this.particularTipoDoc.options[1].setAttribute("disabled", 'true');
                this.particularTipoDoc.options[0].setAttribute("selected", "true");
                break;
        }
        this.elementoLabelNumero(this.particularTipoDoc.options.selectedIndex);
        return null
    }

    private static elementoLabelNumero(option) {
        if (option == 0) {
            $('#IdLabelDocumento').html("Número de DNI: *");
        } else {
            $('#IdLabelDocumento').html("Número de CUIT: *");
        }
        this.elementoDNI(this.particularDNI);
        return null;
    }

    private static async elementoEmail(elemento: HTMLInputElement) {
        this.botonGuardar.type = "button";
        if (elemento.value == "") {
            $('span[data-valmsg-for="' + elemento.name + '"]').text('Este campo es obligatorio');
            $('span[data-valmsg-for="' + elemento.name + '"]').removeClass("field-validation-valid");
            $('span[data-valmsg-for="' + elemento.name + '"]').addClass("field-validation-error");
            return null;
        } else if (!this.validateEmail(elemento.value)) {
            $('span[data-valmsg-for="' + elemento.name + '"]').text('El valor debe ser un email válido');
            $('span[data-valmsg-for="' + elemento.name + '"]').removeClass("field-validation-valid");
            $('span[data-valmsg-for="' + elemento.name + '"]').addClass("field-validation-error");
            return null;
        } else if (!await this.validateEmailIdValid()) {
            $('span[data-valmsg-for="' + elemento.name + '"]').text('El mail ya existe para otro anunciante');
            $('span[data-valmsg-for="' + elemento.name + '"]').removeClass("field-validation-valid");
            $('span[data-valmsg-for="' + elemento.name + '"]').addClass("field-validation-error");
            return null; 
        }
        this.botonGuardar.type = "submit";
    }

    private static elementoCuit(elemento: HTMLInputElement) {
        if (!this.validateCuitBusiness(elemento.value) ) {
            elemento.value = "";
        }
        if (!this.validateCuitFormat(elemento.value) && elemento.value != "") {
            $('span[data-valmsg-for="' + elemento.name + '"]').text('El número de Cuit es inválido.');
            $('span[data-valmsg-for="' + elemento.name + '"]').removeClass("field-validation-valid");
            $('span[data-valmsg-for="' + elemento.name + '"]').addClass("field-validation-error");
        }
        return null;
    }

    private static elementoDNI(elemento: HTMLInputElement) {
        $('span[data-valmsg-for="' + elemento.name + '"]').removeClass("field-validation-valid");
        $('span[data-valmsg-for="' + elemento.name + '"]').removeClass("field-validation-error");
        $('span[data-valmsg-for="' + elemento.name + '"]').text('');

        if (this.particularTipoDoc.options.selectedIndex == 0 && !this.validateDNIFormat(elemento.value)) {
            $('span[data-valmsg-for="' + elemento.name + '"]').text('El número de DNI es inválido.');
            $('span[data-valmsg-for="' + elemento.name + '"]').addClass("field-validation-error");
        };

        if (this.particularTipoDoc.options.selectedIndex == 1 && (!this.validateCuitFormat(elemento.value) || !this.validateCuitBusiness(elemento.value)) && elemento.value != "") {
            $('span[data-valmsg-for="' + elemento.name + '"]').text('El número de Cuit es inválido.');
            $('span[data-valmsg-for="' + elemento.name + '"]').addClass("field-validation-error");
        };
        return null;
    }

    private static async validateForm(e): Promise<void> {
        let input: HTMLInputElement;
        let select: HTMLSelectElement;

        //Verificar cual de los dos está seleccionado. Dejar el siguiente if por las dudas.
        if (this.inmobiliariaCuit != null) {
            input = this.inmobiliariaCuit;
            select = null;

            if (input.name.toLowerCase().search('cuit') > 0 && !this.validateCuitFormat(input.value)) {
                this.elementoCuit(input);
                return e.preventDefault();
            }
        }

        if (this.emailContacto != null) {
            input = this.emailContacto;
            select = null;
            if (input.name.toLowerCase().search('datoscontacto.email') > -1 && !this.validateEmail(input.value)) {
                this.elementoEmail(input);
                return e.preventDefault();
            }
        }

        if (this.particularDNI != null) {
            input = this.particularDNI;
            select = this.particularTipoDoc;

            if (input.name.toLowerCase().search('documento') > 0) {
                if (
                    (select.selectedIndex == 0 && !this.validateDNIFormat(input.value))
                    ||
                    (select.selectedIndex == 1 && (!this.validateCuitFormat(input.value) || !this.validateCuitBusiness(input.value)))
                ) {
                    this.elementoDNI(input);
                    return e.preventDefault();
                }
            }
        }

        if (input.name.toLowerCase().search('cuit') > 0 && !this.validateCuitFormat(input.value)) {
            this.elementoCuit(input);
            return e.preventDefault();
        }

        return e;
    }

    private static validateCuitBusiness(valor: string): boolean {
        var inputString = valor.toString();

        if (inputString == "") {
            return true;
        }

        if (inputString == '30111111118') {
            return false;
        }
        return true;
    }

    private static validateCuitFormat(valor: string): boolean {
       
        var inputString = valor.toString();

        //Para que lo levante la validacion del form.
        if (inputString == "") {
            return true;
        }

        if (inputString.length == 11) {
            var Caracters_1_2 = inputString.charAt(0) + inputString.charAt(1)
            if (Caracters_1_2 == "20" || Caracters_1_2 == "23" || Caracters_1_2 == "24" || Caracters_1_2 == "27" || Caracters_1_2 == "30" || Caracters_1_2 == "33" || Caracters_1_2 == "34") {
                var Count = parseInt(inputString.charAt(0)) * 5 +
                    parseInt(inputString.charAt(1)) * 4 +
                    parseInt(inputString.charAt(2)) * 3 +
                    parseInt(inputString.charAt(3)) * 2 +
                    parseInt(inputString.charAt(4)) * 7 +
                    parseInt(inputString.charAt(5)) * 6 +
                    parseInt(inputString.charAt(6)) * 5 +
                    parseInt(inputString.charAt(7)) * 4 +
                    parseInt(inputString.charAt(8)) * 3 +
                    parseInt(inputString.charAt(9)) * 2 +
                    parseInt(inputString.charAt(10)) * 1
                var Division = Count / 11;
                if (Division == Math.floor(Division)) {
                    return true;
                }
            }
        }
        return false;
    }

    private static validateDNIFormat(dni: string): boolean {
        var ex_regular_dni;
        ex_regular_dni = /^([0-9]{7,8})$/;
        if (ex_regular_dni.test(dni)) {
            return true;
        } else {
            return false;
        }
    }

    private static validateEmail(email: string): any {
        var emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;   // /\\S+@\\S+\\.[a-zA-Z]{2,}/;
        var result = emailReg.test(email);
        return result;
    }

    private static async validateEmailIdValid(): Promise<boolean> {
        var email = this.emailContacto.value;
        var emailOri = this.emailContactoOri.value;
        const emailLogin = document.querySelector<HTMLButtonElement>("[data-menu-account-trigger]")?.getAttribute("_title");
        if (email && email != emailOri && email != emailLogin) {
            try {
                const url = `/MiCuenta/GetValidateEmail?userName=${encodeURIComponent(email)}`;
                const response = await fetch(url, { method: "GET" });
                if (response.ok) {
                    const data = await response.json();
                    return data.status; 
                } else {
                    return false;
                }
            } catch (error) {
                return false;
            }
        }
        return true;
    }
}
