import { Common } from "./common";
import { ReCaptcha, ReCaptchaEvents } from "./common/recaptcha";

export class Agent {

    static initialize() {
        let agent = new Agent();
        agent.initSearchButtom();
        agent.initContactAgentForm();

        let selectSort = document.querySelector("[data-agent-select-sort]") as HTMLSelectElement;

        if (selectSort) {
            let hiddenAgent = document.querySelector("[data-agent-input-sort]") as HTMLInputElement;
            let form = hiddenAgent.closest("form") as HTMLFormElement;

            selectSort.addEventListener("change", () => {
                hiddenAgent.setAttribute("value", selectSort.value);

                form.submit();
            });
        }
    }

    private initContactAgentForm() {
        let form = document.querySelector("[data-contact-agent-form]") as HTMLFormElement;

        if (form) {
            const agentCaptcha = new ReCaptcha("contactAgentCaptcha");
            
            let captchaEvents = () => {
                ReCaptcha.on(ReCaptchaEvents.Error, "contactAgentCaptcha", () => {
                    button.classList.remove("btn-loading");
                });

                ReCaptcha.on(ReCaptchaEvents.Expired, "contactAgentCaptcha", () => {
                    button.classList.remove("btn-loading");
                });
            }

            let button = form.querySelector("[data-send-message-button].btn-spinner") as HTMLElement;

            if (button) {
                form.addEventListener("submit", e => {
                    e.preventDefault();

                    let isValid = Common.validateForm(form);
                    if (isValid) {
                        button.classList.add("btn-loading");

                        captchaEvents();
                        agentCaptcha.execute();
                    }
                });
            }
        }
    }

    private initSearchButtom(): void {
        let form = document.querySelector(".agent__form") as HTMLFormElement;

        if (form) {
            let btnSubmit = form.querySelector("[type=submit].btn-search") as HTMLButtonElement;

            btnSubmit.addEventListener("click", () => {
                btnSubmit.classList.add("btn-loading");
                btnSubmit.disabled = true;
                
                form.submit();
            });
        }
    }
}
