import { Criteo } from "./common/criteo";
import { GroovinAds } from "./common/groovinads";
import { Autocomplete, AutocompleteOption } from "./common/autocomplete";

enum SearchView {
    Location,
    PropertyCode,
    Agent
}

declare var Mustache: any;

export class Home {
    private lastOperationEle: HTMLElement;
    private ubicacionData: UbicacionData;

    static initialize() {
        let home = new Home();

        let isHome = document.getElementById("home_page") as HTMLInputElement;

        if (!isHome || isHome.value) return;
        isHome.value = "true";

        home.initSearch();
        home.noticiasPartial();
        home.setRememberSearch();

        let criteoHome = document.querySelector("#criteo-home") != null;
        if (criteoHome)
            Criteo.pushEventHome();

        GroovinAds.pushEventRetargetingHome(0);
        //GroovinAds.pushEventConversionsHome(0, 40);
    }

    //#region Properties

    private get searchCodeEle(): HTMLInputElement {
        return document.querySelector("[data-search-code-input]") as HTMLInputElement;
    }

    private get searchAgentEle(): HTMLInputElement {
        return document.querySelector("[data-search-agent-input]") as HTMLInputElement;
    }

    private get lastSearchTextEle(): HTMLElement {
        return document.querySelector("[data-last-search-text]") as HTMLElement;
    }

    private get lastSearchBoxEle(): HTMLElement {
        return document.querySelector("[data-last-search]") as HTMLElement;
    }

    private get locationForm(): HTMLFormElement {
        return document.querySelector("[data-location-form]") as HTMLFormElement;
    }

    private get propertyCodeForm(): HTMLFormElement {
        return document.querySelector("[data-code-form]") as HTMLFormElement;
    }

    private get agentForm(): HTMLFormElement {
        return document.querySelector("[data-agent-form]") as HTMLFormElement;
    }

    private get viewMap(): boolean {
        let ele = document.querySelector("[data-view-map]") as HTMLInputElement;

        if (ele) return ele.value == "True";

        return false;
    }

    private set viewMap(value: boolean) {
        let ele = document.querySelector("[data-view-map]") as HTMLInputElement;

        if (ele) {
            ele.value = value ? "True" : "False";
        }
    }

    private get enableOperations(): boolean {
        return !this.operationsContainerEle.classList.contains("hero__operations--disabled");
    }

    private set enableOperations(value: boolean) {
        let ele = this.operationsContainerEle.querySelector(".active") as HTMLElement;

        if (ele) this.lastOperationEle = ele;

        if (value) {
            this.operationsContainerEle.classList.remove("hero__operations--disabled");
            this.lastOperationEle.classList.add("active");
        } else {
            this.operationsContainerEle.classList.add("hero__operations--disabled");
            this.lastOperationEle.classList.remove("active");
        }
    }

    private get findByPropertyCodeEle(): HTMLElement {
        return document.querySelector("[data-search-code]") as HTMLElement;
    }

    private get findByAgentEle(): HTMLElement {
        return document.querySelector("[data-search-agent]") as HTMLElement;
    }

    private get searchContainerEle(): HTMLElement {
        return document.querySelector("[data-hero-search-container]") as HTMLElement;
    }

    private set searchBy(value: SearchView) {
        this.searchContainerEle.className = "hero__search";

        this.setErrorMessageFrom(this.ubicacionEle, false);
        this.setErrorMessageFrom(this.searchCodeEle, false);
        this.setErrorMessageFrom(this.searchAgentEle, false);

        switch (value) {
            case SearchView.Location:
                this.searchContainerEle.classList.add("hero__search-location");

                this.enableOperations = true;
                this.findByPropertyCodeEle.classList.remove("active");
                this.findByAgentEle.classList.remove("active");
                break;
            case SearchView.PropertyCode:
                this.searchContainerEle.classList.add("hero__search-code");

                this.enableOperations = false;
                this.findByPropertyCodeEle.classList.add("active");
                this.findByAgentEle.classList.remove("active");
                break;
            case SearchView.Agent:
                this.searchContainerEle.classList.add("hero__search-agent");

                this.enableOperations = false;
                this.findByPropertyCodeEle.classList.remove("active");
                this.findByAgentEle.classList.add("active");
                break;
        }
    }

    private get searchBy(): SearchView {
        if (this.searchContainerEle.classList.contains("hero__search-location")) return SearchView.Location;
        if (this.searchContainerEle.classList.contains("hero__search-code")) return SearchView.PropertyCode;
        if (this.searchContainerEle.classList.contains("hero__search-agent")) return SearchView.Agent;

        return SearchView.Location;
    }

    private get operationArrow(): HTMLElement {
        return document.querySelector("[data-operation-arrow]") as HTMLElement;
    }

    private get operationsContainerEle(): HTMLElement {
        return document.querySelector("[data-operations-container]") as HTMLElement;
    }

    private get fieldName(): HTMLInputElement {
        return document.querySelector("[data-field-name]") as HTMLInputElement;
    }

    private get fieldValue(): HTMLInputElement {
        return document.querySelector("[data-field-value]") as HTMLInputElement;
    }

    private get tipoOperacionEle(): HTMLSelectElement {
        return document.querySelector("[data-tipo-operacion]") as HTMLSelectElement;
    }

    private set tipoOperacion(value: string) {
        this.tipoOperacionEle && (this.tipoOperacionEle.value = value);

        let operations = this.operationsContainerEle.querySelectorAll("[data-operation]");

        for (var i = 0; i < operations.length; i++) {
            let item = operations.item(i) as HTMLElement;
            let itemValue = item.getAttribute("data-operation");

            if (itemValue == value) {
                item.classList.add("active");

                this.setArrowPositionFrom(item);
            } else {
                item.classList.remove("active");
            }
        }

        this.ubicacionEle.focus();
    }

    private get tipoOperacion(): string {
        if (this.tipoOperacionEle) {
            return this.tipoOperacionEle.value;
        }

        return null;
    }

    private get tipoPropiedadEle(): HTMLSelectElement {
        return document.querySelector("[data-tipo-propiedad]") as HTMLSelectElement;
    }

    private set tipoPropiedad(value: string) {
        this.tipoPropiedadEle && (this.tipoPropiedadEle.value = value);
    }

    private get tipoPropiedad(): string {
        return this.tipoPropiedadEle ? this.tipoPropiedadEle.value : null;
    }

    private set HomeDataSearch(value: HomeSearch) {
        if (value) {
            localStorage.setItem("homeDataSearch", JSON.stringify(value));
        } else {
            localStorage.removeItem("homeDataSearch");
        }
    }

    private get HomeDataSearch(): HomeSearch {
        let value = localStorage.getItem("homeDataSearch");

        return value ? JSON.parse(value) : null;
    }

    private get ubicacionEle(): HTMLInputElement {
        return document.querySelector("[data-ubicacion]") as HTMLInputElement;
    }

    private get ubicacionUrl(): string {
        return this.ubicacionEle.getAttribute("data-autocomplete-url");
    }

    //#endregion

    private disableSubmit(): void {
        let container = this.locationForm;

        switch (this.searchBy) {
            case SearchView.Agent:
                container = this.agentForm;
                break;
            case SearchView.PropertyCode:
                container = this.propertyCodeForm;
                break;
        }

        const btnSubmit = container.querySelector("[type=submit]") as HTMLButtonElement;

        btnSubmit.classList.add("btn-loading");
        btnSubmit.disabled = true;
    }

    private setArrowPositionFrom(ele: HTMLElement) {
        let arrowEle = document.querySelector("[data-operation-arrow]") as HTMLElement;

        if (arrowEle === null) return;

        let arrowWidth = arrowEle.getBoundingClientRect().width;
        let btnWidth = ele.getBoundingClientRect().width;
        let btnLeft = ele.offsetLeft;

        arrowEle.classList.add("show");
        arrowEle.style.left = (btnLeft + btnWidth / 2) - (arrowWidth / 2) + "px";
    }

    private initSearch(): void {
        new Autocomplete(this.ubicacionEle, {
            url: this.ubicacionUrl,
            autoSelect: false,
            highlight: true,
            dataText: "nombre",
            dataValue: "id",
            dataParameters: text => {
                return { "stringBusqueda": text };
            },
            onItemResponse: (data) => {
                let ret = new AutocompleteOption();

                ret.id = this.getUltimoNivelCallejeroCodigo(data.value);

                ret.text = data.label;
                ret.item = new UbicacionData();
                ret.item.ultimoNivel = new UbicacionUltimoNivel();

                ret.item.ultimoNivel.codigo = this.getUltimoNivelCallejeroCodigo(data.value);
                ret.item.ultimoNivel.nombre = this.getUltimoNivelCallejeroNombre(data.value);
                ret.item.ultimoNivel.campo = this.getUltimoNivelCallejeroCampo(data.value);

                return ret;
            },
            onSelect: (optionSelected) => {
                let item = optionSelected.item as UbicacionData;

                this.ubicacionData = item;
                this.setErrorMessageFrom(this.ubicacionEle, false);

                this.saveSearch();
                this.locationForm.submit();
                this.disableSubmit();
            },
            onSelectCustomText: (text: string) => {
                this.ubicacionData = null;

                if (!text) this.setErrorMessageFrom(this.ubicacionEle, false);
            }
        });

        document.addEventListener("click", e => {
            let target = e.target as HTMLElement;

            let isOperation = target.hasAttribute("data-operation");
            let isFindByCode = target.hasAttribute("data-search-code");
            let isFindByAgent = target.hasAttribute("data-search-agent");
            let isFindByMap = target.hasAttribute("data-search-map");

            if (isOperation) {
                this.tipoOperacion = target.getAttribute("data-operation");
            }

            if (isFindByCode) {
                target.blur();

                if (this.searchBy == SearchView.PropertyCode) {
                    this.searchBy = SearchView.Location;
                    this.ubicacionEle.focus();
                }
                else {
                    this.searchBy = SearchView.PropertyCode;
                    this.searchCodeEle.focus();
                }
            }

            if (isFindByAgent) {
                target.blur();

                if (this.searchBy == SearchView.Agent) {
                    this.searchBy = SearchView.Location;
                    this.ubicacionEle.focus();
                }
                else {
                    this.searchBy = SearchView.Agent;
                    this.searchAgentEle.focus();
                }
            }

            if (isFindByMap) {
                this.viewMap = true;
                this.locationForm.submit();
            }
        });

        this.propertyCodeForm.addEventListener("submit", e => {
            e.preventDefault();

            let data = new FormData(this.propertyCodeForm);
            let url = this.propertyCodeForm.action;

            fetch(url, {
                credentials: "same-origin",
                method: "POST",
                body: data
            }).then<CodeSearchResponse>(response => {
                if (response.ok) return response.json();
            }).then(res => {
                if (res.ok) {
                    this.setErrorMessageFrom(this.searchCodeEle, false);
                    this.disableSubmit();

                    window.location.href = res.url;
                } else {
                    this.setErrorMessageFrom(this.searchCodeEle, true, res.message);
                }
            });
        });

        this.agentForm.addEventListener("submit", e => {
            if (this.searchAgentEle.value.trim() == "") {
                e.preventDefault();

                this.setErrorMessageFrom(this.searchAgentEle, true);
            } else {
                this.setErrorMessageFrom(this.searchAgentEle, false);
                this.disableSubmit();
            }
        });

        // Esto parece estupido pero es para setear la posicion inicial de la flecha de la operacion
        this.tipoOperacion = this.tipoOperacion;
        this.ubicacionEle.focus();
    }

    private getUltimoNivelCallejeroCodigo(value): string {

        return value.CodigoSubBarrio ||
            value.CodigoBarrio ||
            value.CodigoLocalidad ||
            value.CodigoPartido ||
            value.CodigoProvincia ||
            value.CodigoRegionBusqueda ||
            value.CodigoPais;
    }

    private getUltimoNivelCallejeroNombre(value): string {

        return value.NombreSubBarrio ||
            value.NombreBarrio ||
            value.NombreLocalidad ||
            value.NombrePartido ||
            value.NombreProvincia ||
            value.NombreRegionBusqueda ||
            value.NombrePais;
    }

    private getUltimoNivelCallejeroCampo(value): string {

        if (value.CodigoSubBarrio)
            return "subbarrio";
        if (value.CodigoBarrio)
            return "barrio";
        if (value.CodigoLocalidad)
            return "localidad";
        if (value.CodigoPartido)
            return "partido";
        if (value.CodigoProvincia)
            return "provincia";
        if (value.CodigoRegionBusqueda)
            return "regionbusqueda";
        if (value.CodigoPais)
            return "pais";
    }

    private saveSearch() {
        let search = new HomeSearch();

        search.operacion = this.tipoOperacion;
        search.propiedad = this.tipoPropiedad;
        search.ubicacion = this.ubicacionData;

        search.isemprendimiento = false;
        search.emprendimiento = this.GetDataEmprendimiento();
        // Guardo la busqueda
        this.HomeDataSearch = search;

        this.fieldName.value = search.ubicacion.ultimoNivel.campo;
        this.fieldValue.value = search.ubicacion.ultimoNivel.codigo;
    }

    private setRememberSearch(): void {
        if (!this.tipoOperacionEle || !this.tipoPropiedadEle || !this.ubicacionEle) return;

        let homeSearchData = this.HomeDataSearch;

        if (homeSearchData) {
            var hasTipoOperacion = this.hasTipoOperacion(homeSearchData.operacion);
            var hasTipoPropiedad = this.hasTipoPropiedad(homeSearchData.propiedad);

            if (!hasTipoOperacion || !hasTipoPropiedad) {
                this.HomeDataSearch = null;
            } else {
                this.tipoOperacion = homeSearchData.operacion;
                this.tipoPropiedad = homeSearchData.propiedad;

                if (homeSearchData.ubicacion) {
                    this.lastSearchTextEle.textContent = this.getLastSearchFormated();

                    this.lastSearchBoxEle.style.display = "block";
                }
            }
        }

        this.locationForm.addEventListener("submit", e => {
            if (!this.tipoOperacion || !this.tipoPropiedad || !this.ubicacionData) {
                e.preventDefault();

                this.setErrorMessageFrom(this.ubicacionEle, true);
            } else {
                this.saveSearch();
            }
        });

        this.lastSearchTextEle.addEventListener("click", e => {
            e.preventDefault();
            this.tipoOperacionEle && (this.tipoOperacionEle.value = homeSearchData.operacion);
            this.tipoPropiedad = homeSearchData.propiedad;
            this.ubicacionData = homeSearchData.ubicacion;

            this.fieldName.value = homeSearchData.ubicacion.ultimoNivel.campo;
            this.fieldValue.value = homeSearchData.ubicacion.ultimoNivel.codigo;

            this.locationForm.submit();

        });
    }

    private hasTipoOperacion(value: string): boolean {
        for (var i = 0; i < this.tipoOperacionEle.length; i++) {
            let item = this.tipoOperacionEle.item(i) as HTMLOptionElement;

            if (item.value == value) return true;
        }

        return false;
    }

    private hasTipoPropiedad(value: string): boolean {
        for (var i = 0; i < this.tipoPropiedadEle.options.length; i++) {
            let option = this.tipoPropiedadEle.options.item(i) as HTMLOptionElement;

            if (option.value == value) return true;
        }

        return false;
    }

    private getLastSearchFormated(): string {
        let text: string[] = [];
        let textValue: string;
        const functionWord = this.lastSearchTextEle.getAttribute("data-last-search-functionword");

        if (this.HomeDataSearch.isemprendimiento === true) {
            return Home.FormatSearchText(this.HomeDataSearch.emprendimiento);
        } else {
            for (let i = 0; i < this.tipoPropiedadEle.options.length; i++) {
                const option = this.tipoPropiedadEle.options[i] as HTMLOptionElement;

                if (option.value == this.HomeDataSearch.propiedad) {
                    textValue = option.innerText.toLowerCase();
                    textValue = textValue.charAt(0).toUpperCase() + textValue.substr(1);

                    text.push(textValue);
                }
            }

            for (let n = 0; n < this.tipoOperacionEle.length; n++) {
                const tipoOperacion = this.tipoOperacionEle[n] as HTMLOptionElement;

                if (tipoOperacion.value == this.HomeDataSearch.operacion) {
                    textValue = tipoOperacion.textContent.trim();

                    text.push(textValue.toLowerCase());
                    break;
                }
            }

            const ubicacionText = this.getultimoNivel();

            text.push(ubicacionText);

            return text.join(` ${functionWord} `);
        }


    }

    private getultimoNivel(): string {
        return this.HomeDataSearch.ubicacion.ultimoNivel.nombre;
    }

    private setErrorMessageFrom(ele: HTMLElement, show: boolean, message?: string) {
        let messageEle = ele.closest("[data-message-container]")
            .querySelector("[data-message]") as HTMLElement;

        if (messageEle) {
            if (message) messageEle.innerText = message;

            messageEle.style.display = show ? "block" : "none";

            if (show) {
                ele.classList
                    .add("input-validation-error");
            } else {
                ele.classList
                    .remove("input-validation-error");
            }
        }
    }

    private setIframeVideoNoticias(): void {
        var play = document.querySelector("[data-widget-youtube]") as HTMLElement;
        if (!play) return;

        play.addEventListener("click", e => {
            e.preventDefault();
            let iframe = document.createElement("iframe");

            iframe.setAttribute("allow", "autoplay");
            iframe.setAttribute("frameborder", "0");
            iframe.setAttribute("allowfullscreen", "");
            iframe.setAttribute("src", `https://www.youtube.com/embed/${play.dataset.embed}?rel=0&autoplay=1&modestbranding=1&fs=0`);

            play.innerHTML = "";
            play.appendChild(iframe);
        });
    }

    private getNoticias(data: NoticiasResponse) {
        return {
            data: data,
            "Posts": function () {
                return data.sidebar;
            },
            "PostFeatured": function () {
                return data.main;
            }
        };
    }


    private noticiasPartial() {
        const container = document.getElementById("noticiasContainer") as HTMLElement;
        const template = document.getElementById("tmpl_noticias") as HTMLElement;

        if (!container || !template) return;

        const url = container.getAttribute("data-url-partial");

        fetch(url, {
            credentials: "same-origin",
            method: "GET"
        }).then<NoticiasResponse>(response => {
            if (response.ok) return response.json();

            container.style.display = "none";
        })
            .then(data => {
                const tmpl = template.innerHTML;
                const options = this.getNoticias(data);
                const html = Mustache.render(tmpl, options);

                container.innerHTML = html;

                this.setIframeVideoNoticias();
            });
    }

    private GetDataEmprendimiento(): EmprendimientoData {
        let emprendimientoSearch: EmprendimientoData = new EmprendimientoData();

        const searchContainer = document.querySelector<HTMLSelectElement>("#EmprendimientoSearch_Region");
        const dateContainer = document.querySelector<HTMLSelectElement>("#EmprendimientoSearch_Entrega");
        const dormitorioContainer = document.querySelector<HTMLElement>(".search-input__control");

        if (searchContainer != null) {
            emprendimientoSearch.ubicacion = searchContainer.value;
        }
        if (dateContainer != null) {
            emprendimientoSearch.entrega = dateContainer.value;
        }
        if (dormitorioContainer != null) {
            emprendimientoSearch.dormitorios = dormitorioContainer.innerHTML;
        }

        return emprendimientoSearch;
    }

    private static FormatSearchText(value: EmprendimientoData): string {
        let retString = "Emprendimientos ";
        if (value.ubicacion != "") {
            var ubicacionFormat = value.ubicacion.replace("-", " ");
            retString += `en ${ubicacionFormat} `;
        }
        if (value.dormitorios != "") {
            retString += `de ${value.dormitorios} `;
        }
        if (value.entrega != "") {
            let entregaFormat = value.entrega.replace("-", " ");
            retString += `entrega ${entregaFormat}`;
        }
        return retString;
    }
    private static FormatSearchLink(value: EmprendimientoData): string {
        let retString = "emprendimiento"; //Siempre es emprendimiento

        if (value.ubicacion != null) {
            retString += "-region-" + value.ubicacion;
        }
        if (value.dormitorios != null) {
            retString += "-" + value.dormitorios.replace(",", "-dormitorio-y-").replace(" ", "-");
        }
        if (value.entrega != null) {
            retString += "-entrega-" + value.entrega;
        }
        return retString;
    }
}

class HomeSearch {
    operacion: string;
    propiedad: string;
    ubicacion: UbicacionData;
    isemprendimiento: boolean;
    emprendimiento: EmprendimientoData;
}

class EmprendimientoData {
    ubicacion: string;
    dormitorios: string;
    entrega: string;
}

class UbicacionUltimoNivel {
    campo: string;
    codigo: string;
    nombre: string;
}

class UbicacionData {
    id: string;
    nombre: string;
    ultimoNivel: UbicacionUltimoNivel;
}

class CodeSearchResponse {
    ok: boolean;
    message: string;
    url: string;
}

class NoticiasResponse {
    main: PostFeatured;
    sidebar: Array<Post>;
}

class PostFeatured {
    post_category: string;
    post_date: string;
    post_excerpt: string;
    post_link: string;
    post_thumbnail: string;
    post_title: string;
    video_id: string;
}

class Post {
    post_category: string;
    post_date: string;
    post_link: string;
    post_thumbnail: string;
    post_title: string;
}