import { Common } from "./common";
import { Popup, PopupEvents } from "./common/popup";
import { ListingMap } from "./listing-map";
import { Facebook } from "./common/facebook";
import { AutocompleteInput } from "./common/autocomplete-input";
import { CommonHelper } from "common/common-helper";
import { GroovinAds } from "./common/groovinads";
import { pushEventoContacto, MedioContacto } from './common/gtm'
import { Criteo } from "./common/criteo";

export class Listing {
    static initialize() {
        this.treeCheckboxBehavior();
        this.activeFilterButton();
        this.map();
        this.AlertSavePartial();

        if (this.ListingEle) {
            const ids = this.ListingEle.getAttribute("data-avisos-id");
            const arr = ids.split(",");
            if (arr.length == 0) return;

            Criteo.pushEventList(arr);
            Facebook.SearchEvent(arr);

            const datosList = document.getElementById("ga-dimension-list");
            const idCategoria = datosList.getAttribute("data-tipo-propiedad") + "-" + datosList.getAttribute("data-tipo-operacion");
            GroovinAds.pushEventRetargetingList(0, idCategoria);
        }

        this.on("click", "[data-remove-filter]", (target, e) => {
            const url = target.getAttribute("data-remove-filter");
            window.location.assign(url);
        });

        this.on("click", "[data-clear-filters]", (target, e) => {
            const url = target.getAttribute("data-clear-filters");
            window.location.assign(url);
        });

        this.on("change", "[data-autosubmit]", (target, e) => {
            let form = target.closest("form") as HTMLFormElement;
            form.submit();
        });

        this.on("click", "[data-show-sort]", (target, e) => {
            document.body.classList.add("show-mobile-sort");
        });

        this.on("click", "[data-hide-sort]", (target, e) => {
            e.preventDefault();
            document.body.classList.remove("show-mobile-sort");
        });

        this.on("click", "[data-show-filters]", (target, e) => {
            document.body.classList.add("show-mobile-filters");
        });

        this.on("click", "[data-hide-filters]", (target, e) => {
            e.preventDefault();
            document.body.classList.remove("show-mobile-filters");
        });

        this.on("change", "[data-sort-dropdown]", (target: HTMLSelectElement, e) => {
            let form = target.closest("form") as HTMLFormElement;

            form.submit();
        });

        this.on("keyup", "[data-location-search]", (target: HTMLInputElement, e) => {
            const value = target.value.toLowerCase(),
                containerId = target.getAttribute("data-location-search"),
                container = document.getElementById(containerId),
                listItems = container.querySelectorAll("li");

            for (let i = 0; i < listItems.length; i++) {
                const target = listItems[i] as HTMLLIElement,
                    text = target.innerText.trim().toLowerCase(),
                    result = text.indexOf(value) == -1;

                if (result) {
                    target.classList.add("hide");
                } else {
                    target.classList.remove("hide");
                }
            }

            const uls = container.querySelectorAll("li > ul");

            for (let n = 0; n < uls.length; n++) {
                const ul = uls[n] as HTMLUListElement;
                const hideCount = ul.querySelectorAll("li.hide").length;
                const totalChilds = ul.querySelectorAll("li").length;
                const hide = hideCount >= totalChilds;

                ul.style.display = hide ? "none" : "block";
            }
        });

        let contactPopup = new Popup("contactPopup");

        let contactPopupEventDelegate = (type: PopupEvents, data: ContactPopupResponse) => {
            if (type == PopupEvents.Success) {
                Common.saveLocalStorageFromValue("Email", "userName");
                Common.saveLocalStorageFromValue("Telefono", "userPhone");
                contactPopup.close();
                Popup.showAlert(data.title, data.message);

                if (data.send)
                {
                    pushEventoContacto(MedioContacto.Texto);
                }
            }
        }

        this.on("click", "[data-contact]", (target, event) => {
            event.preventDefault();
            const inputAviso = document.getElementById("IdAviso") as HTMLInputElement;
            const idAviso = target.getAttribute("data-aviso-id");
            inputAviso.value = idAviso;

            const idTipoAnunciante = target.getAttribute("data-anunciante-tipo");
            document.getElementById("contactPopup").getElementsByClassName("popup-title")[0].innerHTML = idTipoAnunciante;

            const listEle = document.querySelector("[data-contact-list-aviso-id]");
            listEle.setAttribute("data-contact-list-aviso-id", idAviso);

            const inputIdAnunciante = document.getElementById("IdAnunciante") as HTMLInputElement;
            inputIdAnunciante.value = target.getAttribute("data-anunciante-id");

            contactPopup.show();
            AutocompleteInput.initialize();
            contactPopup.event((t, e, d) => contactPopupEventDelegate(t, d));
        });

        if (CommonHelper.isInternetExplorer()) {
            this.ComptibilityInternetExplorer();
        }
    }

    private static ComptibilityInternetExplorer() {
        $('[data-ie-object-fit-listing]').each(function () {
            let $element = $(this),
                imgUrl = $element.data('src'),
                imgErrorUrl = $element.data('error');
            if (imgUrl) {
                $element.prop('src', '');
                $element.prop('alt', '');
                $element.data('src', '');
                $element.data('error', '');
                if (imgErrorUrl) {
                    $element.css('backgroundImage', 'url(' + imgUrl + '), url(' + imgErrorUrl + ')');
                } else {
                    $element.css('backgroundImage', 'url(' + imgUrl + ')');
                }

                $element.css('backgroundSize', 'cover');
                $element.css('backgroundPositionX', 'center');
                $element.css('backgroundPositionY', 'center');
            }
        });
    }

    static setSaveAlertResult(result: SaveSearchAndAlertResponse): void {
        let saveAlertContainer = document.getElementById("saveAlertContainer");
        let docSaveAlertResult = Common.createElementFromTemplate("#saveAlertResultTmpl");

        if (result.isAlert && saveAlertContainer) {
            saveAlertContainer.innerHTML = "";
            saveAlertContainer.appendChild(docSaveAlertResult);
        }
    }

    static treeCheckboxBehavior() {
        this.on("change", "[data-tree-checkbox-behavior] input[type=checkbox]", (target, e) => {
            const father = target.closest("ul");
            const value = (target as HTMLInputElement).checked;
            const button = document.getElementById("locationApply");

            // Reseteo de estados
            button.removeAttribute("disabled");
            button.classList.remove("btn-primary");
            button.classList.add("btn-secondary");

            // Chequea los checkbox hijos si tiene
            let nextUl = target.parentElement.nextElementSibling;

            if (nextUl) {
                let childsChk = nextUl.querySelectorAll("input[type=checkbox]");

                for (var i = 0; i < childsChk.length; i++) {
                    let childCheck = childsChk[i] as HTMLInputElement;

                    childCheck.checked = value;
                }
            }

            const checked = father.querySelectorAll("input[type=checkbox]:checked");
            const nodeCheckbox = document.querySelectorAll("[id=locationContainer] input[type=checkbox]");

            if (checked.length == 0 && !this.hasAnyChecked(nodeCheckbox)) {
                button.setAttribute("disabled", "");
            } else {
                button.classList.remove("btn-secondary");
                button.classList.add("btn-primary");
            }
        });
    }

    static hasAnyChecked(array) {
        for (var i = 0; i < array.length; i++) {
            if ((array[i] as HTMLInputElement).checked)
                return true;
        }

        return false;
    }

    private static activeFilterButton() {
        const filtros = document.querySelectorAll("[data-filter-active-button]");
        for (let i = 0; i < filtros.length; i++) {
            const btn = filtros[i].querySelector("[type=submit]");
            const checks = filtros[i].querySelectorAll("input[type=checkbox]") as NodeListOf<HTMLInputElement>;

            for (var j = 0; j < checks.length; j++) {
                checks[j].addEventListener("change", () => {
                    if (this.hasAnyChecked(checks)) btn.classList.add("active");
                    else btn.classList.remove("active");
                });
            }
        }
    } 

    private static map(): void {
        let container = document.querySelector("[data-listing-map]") as HTMLElement;

        if (!container || (container && container.getAttribute("data-listing-map") != "")) return;

        container.setAttribute("data-listing-map", "true");

        let listingMap = new ListingMap(container);

        listingMap.init();
    }
    
    private static on(eventType: string, selector: string, fn: (target: Element, e: Event) => void): void {
        let eles = document.querySelectorAll(selector);

        for (var i = 0; i < eles.length; i++) {
            let ele = eles[i];

            ele.addEventListener(eventType, e => fn(ele, e));
        }
    }    

    private static get ListingEle(): HTMLElement {
        return document.querySelector("#listing-avisos-id") as HTMLElement;
    }

    private static SuggestAlert() {
        const suggestAlertPopup = new Popup("suggestAlertPopup");
        const btnSubmit = document.querySelector(`#${suggestAlertPopup.id} [type=submit]`);
        const canSave = document.querySelector(`#${suggestAlertPopup.id} [data-alert-can-save]`) !== null;

        const canSuggest = () => {
            const get = () => {
                const value = localStorage.getItem("_ap_web_al");
                return value ? new Date(value) : null;
            };
            const save = (value: Date) => localStorage.setItem("_ap_web_al", value.toJSON());

            const now = new Date();
            const value = get();

            if ((!value) || (value && now > value)) {
                now.setHours(now.getHours() + 24);
                save(now);

                return true;
            }

            return false;
        };
        
        if (!canSave || !canSuggest()) return;

        suggestAlertPopup.show();

        btnSubmit.addEventListener("click", e => {
            const btnSaveAlert = document.querySelector("[data-popup-save-alert]");

            suggestAlertPopup.close();

            const clickEvent = new Event("click");
            btnSaveAlert.dispatchEvent(clickEvent);
        });      
    }
    
    private static AlertSavePartial() {
        const container = document.getElementById("saveAlertContainer");

        if (container) {
            const url = container.getAttribute("data-url-partial");

            fetch(url, {
                credentials: "same-origin",
                method: "GET"
            }).then(response => {
                if (response.ok)
                    return response.text();
            }).then(html => {
                container.innerHTML = html;

                let btnSaveAlert = document.querySelector("[data-popup-save-alert]");

                if (btnSaveAlert) {
                    let saveAlertPopup = new Popup("saveAlertPopup");

                    this.SuggestAlert();

                    btnSaveAlert.addEventListener("click", e => {
                        e.preventDefault();
                        saveAlertPopup.show();         
                        document.querySelector(`#${saveAlertPopup.id} form`).addEventListener("submit", handleSubmit);
                    });

                    const handleSubmit = (e: Event) => {
                        const form = e.target as HTMLFormElement;
                        const formData = new FormData(form);

                        e.preventDefault();
                        
                        fetch(form.action, {
                            credentials: "same-origin",
                            method: "POST",
                            body: formData
                        }).then(async res => {
                            if (res.ok) {
                                const ret = new SaveSearchAndAlertResponse();

                                ret.isAlert = true;

                                this.setSaveAlertResult(ret);
                                saveAlertPopup.close();

                                return;
                            }

                            throw await res.text();
                        }).catch(reason => console.error(reason));
                    }
                }
            });
        }
    }
}

class SaveSearchAndAlertResponse {
    id: number;
    isSearch: boolean;
    isAlert: boolean;
}

class ContactPopupResponse {
    send: boolean;
    title: string;
    message: string;
}
