
export class TabStep {
    private static stepList: HTMLUListElement;
    private static stepId: HTMLInputElement;

    public static initialize(selector?: string) {
        const progress = document.querySelector<HTMLUListElement>("[data-progress-wizard]");
        this.stepList = document.querySelector("[data-tab-step-list]") as HTMLUListElement;
        this.stepId = document.querySelector("[data-tab-step-id]") as HTMLInputElement;

        if (this.stepList && this.stepId) {
            let stepValue = this.stepId.value;
            let steps = this.stepList.querySelectorAll(`[data-tab-step]`);

            for (let i = 0; i < steps.length; i++) {
                let step = steps.item(i);
                let tabStep = step.getAttribute("data-tab-step");

                step.classList.remove("active");

                if (tabStep == stepValue) {
                    step.classList.add("active");
                }
            }
        }

        if (!progress && !this.stepId) return;
        const step = Number(this.stepId.value.split("-")[1]);
        const dots = document.querySelectorAll("[data-progress]");
        dots.forEach(d => d.classList.remove("active"));
        if (isNaN(step)) return;
        for (let i = 0; i < step; i++)
            dots[i].classList.add("active");
    }
}
