
class NumberUpDown {
    private btnUp: HTMLAnchorElement;
    private btnDown: HTMLAnchorElement;
    private input: HTMLInputElement;
    
    constructor(public container: Element) {
        this.input = container.querySelector("input") as HTMLInputElement;
        this.btnUp = container.querySelector("[data-numeric-up]") as HTMLAnchorElement;
        this.btnDown = container.querySelector("[data-numeric-down]") as HTMLAnchorElement;
    }

    public init() {
        this.btnUp.addEventListener("click", (e) => {
            e.preventDefault();

            this.increment();
        });

        this.btnDown.addEventListener("click", (e) => {
            e.preventDefault();

            this.decrement();
        });

        this.input.addEventListener("keydown", (e) => {
            let key = e.key;
            let num = parseInt(key);

            if (e.keyCode == 38) this.increment();
            if (e.keyCode == 40) this.decrement();
            
            if (key.length == 1 && isNaN(num)) {
                e.preventDefault();
            }
        });

        this.input.addEventListener("keyup", (e) => {
            if (this.min != null && this.value < this.min) {
                this.value = this.min;
            }

            if (this.max != null && this.value > this.max) {
                this.value = this.max;
            }
        });
    }

    private increment(): void {
        if (this.readonly) return;

        if (this.max == null || (this.value < this.max)) {
            this.value++;
        }
    }

    private decrement(): void {
        if (this.readonly) return;

        if (this.min == null || (this.value > this.min)) {
            this.value--;
        }
    }

    private get min(): number {
        let value = this.input.getAttribute("data-val-range-min");

        if (value) return parseInt(value);

        return null;
    }

    private get max(): number {
        let value = this.input.getAttribute("data-val-range-max");

        if (value) return parseInt(value);

        return null;
    }

    private get value(): number {
        return this.input.value ? parseInt(this.input.value) : 0;
    }

    private set value(val: number) {
        this.input.value = val.toString();
    }

    private get readonly(): boolean {
        return this.input.getAttribute("readonly") != null;
    }
}

export class NumericUpDown {
    public static initialize(selector?: string): void {
        let scope = this.getScope(selector);

        let eles = scope.querySelectorAll("[data-numeric-up-down]");

        for (var i = 0; i < eles.length; i++) {
            let ele = eles[i];

            let numberUpDown = new NumberUpDown(ele);

            numberUpDown.init();
        }
    }

    private static getScope(scope: string): Element {
        if (!scope) return document.body;
        let ret = document.getElementById(scope) as Element;

        if (!ret)
            ret = document.querySelector(`${scope}`);

        return ret;
    }
}
