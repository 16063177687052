const domains = ["gmail.com", "hotmail.com", "yahoo.com.ar", "outlook.com"];
const list = document.createElement("ol");
const fragment = document.createDocumentFragment();

domains.map(value => {
    const item = document.createElement("li");
    const span = document.createElement("span");

    item.appendChild(span);

    span.setAttribute("data-item", "");
    span.textContent = `@${value}`;

    fragment.appendChild(item);
});

list.setAttribute("data-domain-suggest", "");
list.classList.add("domain-suggest");

list.appendChild(fragment);

class DomainSuggets {
    private previousValue: AutoFill;
    private list: HTMLElement;

    constructor(private input: HTMLInputElement) {
        this.previousValue = this.input.autocomplete;

        this.handleInput = this.handleInput.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleGlobalClick = this.handleGlobalClick.bind(this);

        this.create();
    }

    private set visible(value: boolean) {
        this.list.style.display = value ? "block" : "none";

        if (value) {
            // Selecciono el primero siempre
            this.selectedIndex = 0;

            document.addEventListener("click", this.handleGlobalClick);
        } else {
            this.clearFilter();
            document.removeEventListener("click", this.handleGlobalClick);
        }
    }

    private get visible(): boolean {
        return this.list.style.display === "block";
    }
    
    private set selectedIndex(index: number) {
        let indexVisible = -1;

        // Solo pueden ser "active" los items que estan visibles
        for (let i = 0; i < this.list.children.length; i++) {
            const item = this.list.children.item(i) as HTMLElement;
            const isVisible = item.offsetWidth && item.offsetHeight;

            item.classList.remove("active");

            if (isVisible) indexVisible++;
            if (indexVisible === index) item.classList.add("active");
        }
    }

    private get selectedIndex(): number {
        const selected = this.list.querySelector<HTMLElement>(".active");

        if (selected) return getElementIndex(selected);

        return -1;
    }

    private getElement(index: number): HTMLElement {
        for (let i = 0; i < this.list.children.length; i++) {
            if (i === index) return this.list.children.item(i) as HTMLElement;
        }

        return null;
    }

    private setDomainBySelection(): void {
        const ele = this.getElement(this.selectedIndex);
        const current = this.input.value;
        const indexAt = current.indexOf("@");
        const newValue = current.substring(0, indexAt);

        this.input.value = `${newValue}${ele.textContent}`;
        this.visible = false;
    }

    private create(): void {
        this.list = list.cloneNode(true) as HTMLElement;

        this.input.insertAdjacentElement("afterend", this.list);

        this.input.addEventListener("input", this.handleInput);
        this.input.addEventListener("keydown", this.handleKeydown);
        this.list.addEventListener("click", this.handleItemClick);
    }

    private handleGlobalClick(e: Event): void {
        const target = e.target as HTMLElement;
        const parentSuggest = target.closest("[data-domain-suggest]");
        
        if ((target !== this.input && target !== this.list && !parentSuggest) ||
            (parentSuggest && parentSuggest !== this.list)) {
            this.visible = false;
        }
    }

    private handleItemClick(e: Event): void {
        const target = e.target as HTMLElement;
        const isItem = target.hasAttribute("data-item");

        if (isItem) {
            const index = getElementIndex(target.parentElement);

            this.selectedIndex = index;
            this.setDomainBySelection();
        }
    }

    private handleKeydown(e: KeyboardEvent): void {
        if (!this.visible) return;

        let current = 0;

        switch (e.which) {
            case 27: // Esc
            case 9: // Tab
                this.visible = false;
                break;
            case 13: // Enter
                if (this.selectedIndex !== -1) {
                    e.preventDefault();
                    this.setDomainBySelection();
                }

                break;
            case 38: // Up
                e.preventDefault();
                current = this.selectedIndex - 1;
                this.selectedIndex = current < 0 ? 0 : current;

                break;
            case 40: // Down
                e.preventDefault();

                current = this.selectedIndex + 1;
                this.selectedIndex = current >= this.list.childElementCount ?
                    this.list.childElementCount - 1 :
                    current;
                break;
        }
    }

    private handleInput(e: InputEvent): void {
        const target = e.target as HTMLInputElement;
        const hasAt = target.value.indexOf("@");

        if (e.data === "@") {
            target.autocomplete = "off";
            this.visible = true;
        } else if (hasAt === -1) {
            this.visible = false;
            target.autocomplete = this.previousValue;
        }

        if (this.visible) {
            this.filterList();
        }
    }

    private filterList(): void {
        let domainValue = "";

        const value = this.input.value;
        const hasAt = value.indexOf("@");

        if (hasAt !== -1) {
            domainValue = value.substring(hasAt).toLowerCase();
        }

        for (let i = 0; i < this.list.children.length; i++) {
            const item = this.list.children.item(i) as HTMLElement;
            const span = item.querySelector("span");
            const text = span.textContent.toLowerCase();
            const contentTerm = text.startsWith(domainValue);

            item.style.display = contentTerm ? "block" : "none";

            if (contentTerm) {
                span.innerHTML = text.replace(domainValue, `<em>${domainValue}</em>`);
            } else {
                span.textContent = text;
            }
        }

        // Seleccionar el primero que haya
        this.selectedIndex = 0;
    }

    private clearFilter(): void {
        for (let i = 0; i < this.list.children.length; i++) {
            const item = this.list.children.item(i) as HTMLElement;
            item.style.display = "";
        }
    }
}

const isMobile = () => {
    return window.matchMedia("(max-width: 600px)").matches;
};

const getElementIndex = (element: HTMLElement) => {
    return Array.from(element.parentNode.children).indexOf(element);
};

export const createDomainSuggest = (ele: HTMLInputElement | NodeListOf<HTMLInputElement>) => {
    if (isMobile()) return;

    const isIterable = 'forEach' in ele;
    const iterable = ele as NodeListOf<HTMLInputElement>;
    const element = ele as HTMLInputElement;
    
    if (isIterable) {
        iterable.forEach(ele => new DomainSuggets(ele));
    } else {
        new DomainSuggets(element);
    }
};
