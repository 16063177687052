
import { Animate, Easings } from "./animate";

export class SearchTypeButtons {
    private static container: Element;

    static initialize(selector?: string) {
        this.container = selector ? document.querySelector(`#${selector}`) : document.body;

        if (document.querySelector(".main-search-form")) {
            this.searchTypeButtons();
        }

        this.scrollToTopButton();
    }
    
    private static searchTypeButtons() {
        let radios = this.container.querySelectorAll(".search-type input[type=radio]");
        
        this.setArrowPosition();

        let searchTypeContainer = this.container.querySelector(".search-type");
        if (searchTypeContainer === null) return;

        searchTypeContainer.addEventListener("change", e => {
            let target = e.target as HTMLInputElement;
            
            for (var i = 0; i < radios.length; i++) {
                let radio = radios[i];

                radio.parentElement.classList.remove("active");
            }

            target.parentElement.classList.add("active");

            this.setArrowPosition();
        });
    }

    private static setArrowPosition() {
        let arrowEle = this.container.querySelector(".search-type-arrow") as HTMLElement;
        if (arrowEle === null) return;
        let arrowWidth = arrowEle.getBoundingClientRect().width;

        let btnActive = this.container.querySelector(".search-type label.active") as HTMLLabelElement;
        let btnWidth = btnActive.getBoundingClientRect().width;
        let btnLeft = btnActive.offsetLeft;

        arrowEle.style.left = (btnLeft + btnWidth / 2) - (arrowWidth / 2) + "px";
    }

    private static scrollToTopButton() {
        if (this.container) {
            let pxShow = 600; // height on which the button will show
            let backToTop = this.container.querySelector("#backtotop") as HTMLElement;

            if (!backToTop) return;

            window.addEventListener("scroll", () => {
                if (this.scrollTop >= pxShow) {
                    backToTop.classList.add("show");
                } else {
                    backToTop.classList.remove("show");
                }
            });

            backToTop.addEventListener("click", e => {
                e.preventDefault();

                let animate = new Animate(this.scrollTop, 0, 500, Easings.EaseInOutQuad);

                animate.start(value => this.scrollTop = value);
                });
        }
    }
    
    private static set scrollTop(value: number) {
        window.scroll(null, value);
    }

    private static get scrollTop(): number {
        return window.scrollY;
    }
}
