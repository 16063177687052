import { Common } from "./common";
import { MyAccountChat } from "./my-account-chat";
import { pushEventoContacto, MedioContacto } from './common/gtm'

export class MessageBoards {
    static container: HTMLElement;

    public static initialize() {
        this.container = document.getElementById("my-account-content");
        if (this.container && !this.container.hasAttribute("message-board-binds")) {
            this.container.setAttribute("message-board-binds", "false");
            this.initializeSorting();

        }
        this.initializeChat();
        this.checkUrlRedirect();
        //this.cleanSelectors();
    }

    private static cleanSelectors() {
        const filterProperty = document.querySelector<HTMLSelectElement>("#filterMessagesBy")
        if (filterProperty != null) {
            const textFilterProperty = filterProperty.nextElementSibling?.closest(".custom-select-container")?.children[0]?.children[0] as HTMLSpanElement;
            if(textFilterProperty!=null)
                textFilterProperty.innerText = "Ver propiedad";
        }
        
        const sortingMessage = document.querySelector<HTMLSelectElement>("#sortMessagesBy")
        if (sortingMessage != null) {
            const textSort = sortingMessage.nextElementSibling?.closest(".custom-select-container")?.children[0]?.children[0] as HTMLSpanElement;
            if(textSort!=null)
                textSort.innerText = "Ordenar por";
        }
    }

    private static checkUrlRedirect() {
        //Validacion extra para el caso de estar llegando de una pagina externa u otra ruta y que no cambie la url relativa.
        const authChat = document.querySelector<HTMLElement>("#RedirectMiCuenta");

        if (window.location.href.endsWith("MisMensajes") === false && window.location.href.includes("/MiCuenta/MisMensajes/")==true && authChat != null) {
            window.location.href = window.location.href.substring(0, window.location.href.lastIndexOf("/MisMensajes") + 12);
        }
    }

    private static filterMessages(selectFilter: HTMLSelectElement) {
        if (selectFilter == null || selectFilter.value == null) return;

        (document.getElementById('my-account-content') as HTMLElement).classList.add("hidden");
        (document.getElementById('my-account-loader') as HTMLElement).style.display = "block";
        Common.initialize();

        var dateSelected = document.querySelector<HTMLSelectElement>("#filterDateMessagesBy");

        var url = selectFilter.getAttribute("data-url-messages") + "?idAviso=" + selectFilter.value + "&date=" + dateSelected.value;

        fetch(url, {
            method: "GET",
            credentials: "same-origin"
        }).then(async response => {
            if (response.ok) {
                (document.getElementById('my-account-content') as HTMLElement).innerHTML = await response.text();
                (document.getElementById('my-account-content') as HTMLElement).classList.remove("hidden");
                (document.getElementById('my-account-loader') as HTMLElement).style.display = "none";
                this.initialize();
                Common.initialize('my-account-content');
                MyAccountChat.initCards(document.getElementById("my-account-content"));
                MyAccountChat.initNavigation(document.getElementById("my-account-content"));
            }
        });

    }
    private static sortMessages(selectSort: HTMLSelectElement) {
        (document.getElementById('my-account-content') as HTMLElement).classList.add("hidden");
        (document.getElementById('my-account-loader') as HTMLElement).style.display = "block";
        Common.initialize();

        var dateSelected = document.querySelector<HTMLSelectElement>("#filterDateMessagesBy");

        //var propertySelected = document.querySelector<HTMLSelectElement>("#filterMessagesBy");

        //var url = selectSort.getAttribute("data-url-messages") + "?sort=" + selectSort.value + "&idAviso=" + propertySelected.value + "&date=" + dateSelected.value;

        var url = selectSort.getAttribute("data-url-messages") + "?sort=" + selectSort.value + "&date=" + dateSelected.value;

        fetch(url, {
            method: "GET",
            credentials: "same-origin"
        }).then(async response => {
            if (response.ok) {
                (document.getElementById('my-account-content') as HTMLElement).innerHTML = await response.text();
                (document.getElementById('my-account-content') as HTMLElement).classList.remove("hidden");
                (document.getElementById('my-account-loader') as HTMLElement).style.display = "none";
                this.initialize();
                Common.initialize('my-account-content');
                MyAccountChat.initCards(document.getElementById("my-account-content"));
                MyAccountChat.initNavigation(document.getElementById("my-account-content"));
            }
        });

    }

    private static filterDateMessages(selectSort: HTMLSelectElement) {
        (document.getElementById('my-account-content') as HTMLElement).classList.add("hidden");
        (document.getElementById('my-account-loader') as HTMLElement).style.display = "block";
        Common.initialize();

        var url = selectSort.getAttribute("data-url-messages") + "?date=" + selectSort.value;
        
        fetch(url, {
            method: "GET",
            credentials: "same-origin"
        }).then(async response => {
            if (response.ok) {
                (document.getElementById('my-account-content') as HTMLElement).innerHTML = await response.text();
                (document.getElementById('my-account-content') as HTMLElement).classList.remove("hidden");
                (document.getElementById('my-account-loader') as HTMLElement).style.display = "none";
                this.initialize();
                Common.initialize('my-account-content');
                MyAccountChat.initCards(document.getElementById("my-account-content"));
                MyAccountChat.initNavigation(document.getElementById("my-account-content"));
            }
        });

    }

    private static initializeSorting() {
        this.container.addEventListener("change", e => {
            e.preventDefault();
            const selectorTarget = e.target as HTMLSelectElement;
            if (selectorTarget.id === "filterMessagesBy") {
                this.filterMessages(selectorTarget);
            }
            if (selectorTarget.id === "sortMessagesBy") {
                this.sortMessages(selectorTarget);
            }
            if (selectorTarget.id === "filterDateMessagesBy") {
                this.filterDateMessages(selectorTarget);
            }
        }, false);
    }
    private static initializeChat() {
        let $chatBody = $("[data-chat-body]");

        if ($chatBody.length === 0) {
            return;
        }
        

        let positionChatBody = $chatBody.position();
        const $chatSubmit = $("[data-chat-inner-submit]");
        let $chatFormContainer = $("[data-chat-form-container]");
        const inputNewMsg = document.getElementById("ContenidoNuevoMensaje") as HTMLInputElement;
        

        if ($chatSubmit.attr("initialized") == "true") {
            //evito doble inicializacion del boton de chat
            return;
        }

        $chatSubmit.attr("initialized","true");
        inputNewMsg.addEventListener("input", () => {
            if (inputNewMsg.value.trim().length > 0)
                $chatSubmit.prop('disabled', false);
            else
                $chatSubmit.prop('disabled', true);
        });

        $chatSubmit.on("click", (e) => {

            e.preventDefault();
            const smbtButton = e.target as HTMLSelectElement;
            const url = smbtButton.getAttribute("data-url");
            let sendData = new FormData();
            
            let contentElement = document.getElementById('my-account-chat-content') as HTMLElement;
            let loaderElement = document.getElementById('my-account-chat-loader-msg') as HTMLElement;
            let inputElement = document.getElementById('ContenidoNuevoMensaje') as HTMLInputElement;
            const divScroll = document.querySelector(".partial-view-message") as HTMLElement;
            
            loaderElement.style.display = "block"
            if (divScroll != null) {
                divScroll.scrollTop = divScroll.scrollHeight;
            }
            
            smbtButton.disabled = true;
            inputElement.disabled = true;

            sendData.append("contenidoNuevoMensaje", inputElement.value);
            sendData.append("idConversacion", (document.getElementById('IdConversacion') as HTMLInputElement).value);
            sendData.append("idAnunciante", (document.getElementById('IdAnunciante') as HTMLInputElement).value);
            sendData.append("emailInteresado", (document.getElementById('EmailInteresado') as HTMLInputElement).value);
            sendData.append("idRemitente", (document.getElementById('IdRemitente') as HTMLInputElement).value);
            sendData.append("idAviso", (document.getElementById('Aviso_IdAviso') as HTMLInputElement).value);

            fetch(url, {
                method: "POST",
                credentials: "same-origin",
                body: sendData
            }).then(async response => {
                if (response.ok) {
                    contentElement.innerHTML = await response.text();  
                    (document.getElementById('ContenidoNuevoMensaje') as HTMLInputElement).value = "";     

                    pushEventoContacto(MedioContacto.Texto);
                    
                    this.initialize();
                }
                loaderElement.style.display = "none";
                smbtButton.disabled = true;
                inputElement.disabled = false;
                if (divScroll != null) {
                    divScroll.scrollTop = divScroll.scrollHeight;
                }
            });



        });


        let cssFixedForm = function () {
            if ($(window).width() <= 425) {
                $(window).on('scroll', cssFixedFormScroll);
            } else {
                $chatFormContainer.removeClass("sm-chat-form-fixed");
                $(window).off('scroll', cssFixedFormScroll);
            }
        }

        let cssFixedFormScroll = function () {
            let currentScrollTop = $(window).scrollTop();

            if (currentScrollTop >= positionChatBody.top - 300) {
                $chatFormContainer.removeClass("sm-chat-form-fixed");
            } else {
                $chatFormContainer.addClass("sm-chat-form-fixed");
            }
        }

        $(window).on("load", cssFixedForm);
        $(window).on("resize", cssFixedForm);
    }
}

