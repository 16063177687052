import { Map } from "./map";

export class Maps {
    private static mapContainers: Element[];

    static initialize() {
        if (!this.mapContainers) this.mapContainers = [];

        let simpleMaps = document.querySelectorAll("[data-simple-map]");

        for (var i = 0; i < simpleMaps.length; i++) {
            let ele = simpleMaps[i];

            // Si ya fue inicializado paso al siguiente
            if (this.mapContainers.indexOf(ele) != -1) continue;

            let lat = ele.getAttribute("data-latitude");
            let lng = ele.getAttribute("data-longitude");
            let disable = ele.hasAttribute("data-disable");

            let map = new Map(ele);
            
            map.init();
            map.enable = !disable;

            if (lat && lng) {
                let latitude = parseFloat(lat.replace(",", "."));
                let longitude = parseFloat(lng.replace(",", "."));

                map.setMarker(latitude, longitude);
                map.setView(latitude, longitude);
            }

            this.mapContainers.push(ele);
        }
    }
}
