
import { Common } from "../common";

export class Autocomplete {
    private container: HTMLElement;
    private list: HTMLOListElement;
    private backButton: HTMLElement;
    private termInput: HTMLInputElement;
    private timeoutHandler: number;

    private olSpans: HTMLSpanElement[] = [];
    private listOptions: AutocompleteOption[] = [];
    private currentOptionSelected: AutocompleteOption;

    constructor(private element: HTMLInputElement, public options: AutocompleteOptions) {
        if (this.text) {
            // Cuando el input ya tiene un texto, determino que fue seleccionado del servidor
            this.currentOptionSelected = new AutocompleteOption();
        }
        
        this.createList();
        this.bind();
    }

    clearList(): void {
        this.list.innerHTML = "";
    }

    private createList(): void {
        this.container = document.createElement("div");
        this.list = document.createElement("ol");

        this.container.classList.add("autocomplete");
        this.container.appendChild(this.list);

        let header = document.createElement("div");

        this.backButton = document.createElement("a");
        this.termInput = document.createElement("input");

        header.classList.add("autocomplete-header");

        this.backButton.setAttribute("href", "#");
        this.backButton.classList.add("header-back");

        const iconBack = document.createElement("i");

        iconBack.classList.add("basico1-icon-angle_left");

        this.backButton.appendChild(iconBack);

        this.termInput.setAttribute("type", "text");
        this.termInput.setAttribute("autocomplete", "off");
        this.termInput.setAttribute("data-input", "");

        if (this.element.hasAttribute("placeholder")) {
            let placeholder = this.element.getAttribute("placeholder");

            this.termInput.setAttribute("placeholder", placeholder);
        }

        header.appendChild(this.backButton);
        header.appendChild(this.termInput);

        this.container.insertBefore(header, this.list);

        if (Common.isMobile) {
            this.container.classList.add("autocomplete-mobile");
            document.body.appendChild(this.container);
        } else {
            this.wrap(this.element, this.container);
        }
    }

    private wrap(el: Element, wrapper: Element) {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }

    private get uiSelected(): HTMLSpanElement {
        for (var i = 0; i < this.olSpans.length; i++) {
            let span = this.olSpans[i] as HTMLSpanElement;

            if (span.classList.contains("autocomplete-selected")) return span;
        }
        
        return null;
    }

    private set uiSelected(value: HTMLSpanElement) {
        for (var i = 0; i < this.olSpans.length; i++) {
            let span = this.olSpans[i] as HTMLSpanElement;

            if (span == value) {
                span.classList.add("autocomplete-selected");
                this.text = span.textContent;
                this.scrollIntoView(span);
            } else {
                span.classList.remove("autocomplete-selected");
            }
        }
    }

    private get selected(): AutocompleteOption {
        return this.currentOptionSelected;
    }

    private set selected(option: AutocompleteOption) {
        if (option) {
            this.currentOptionSelected = option;
            this.text = option.text;

            this.options.onSelect && this.options.onSelect(option);
        } else {
            this.currentOptionSelected = null;
        }
    }
    
    private bind(): void {
        this.backButton.addEventListener("click", e => {
            const data = document.querySelector<HTMLInputElement>("[data-input]").value;
            this.options.onSelectCustomText(data);
            e.preventDefault();
            this.containerVisible = false;
        });

        this.element.addEventListener("click", e => {
            if (Common.isMobile) {
                e.stopPropagation();

                this.textTerm = "";
                this.clearList();
                this.termInput.focus();

                this.containerVisible = true;
            }
        });

        this.element.addEventListener("input", e => this.onchange(e));
        this.termInput.addEventListener("input", e => this.onchange(e));
        
        this.element.addEventListener("blur", () => {
            if (!this.selected) {
                this.options.onSelectCustomText && this.options.onSelectCustomText(this.text);
            }
        });

        this.element.addEventListener("keydown", (e: KeyboardEvent) => {
            switch (e.which) {
                case 37: // Izquierda
                    if (!this.listVisible) break;
                case 38: // Up
                    e.preventDefault();
                    this.moveUp(e);

                    break;
                case 39: // Derecha
                    if (!this.listVisible) break;
                case 40: // Abajo
                    e.preventDefault();
                    this.moveDown(e);

                    break;
                case 9:  // Tab
                case 13: // Enter
                    if (this.listVisible) {
                        e.preventDefault();

                        if ((!this.selected || (this.selected.text != this.text)) && this.uiSelected) {
                            let option = this.findOptionByText(this.uiSelected.textContent);

                            this.selected = option;
                            this.listVisible = false;
                        }
                    }
                    
                    break;
                case 27: // Esc
                    if (this.selected && this.selected.text != this.text) {
                        let span = this.findSpanById(this.selected.id);
                        this.uiSelected = span;
                    }

                    this.listVisible = false;
                    break;
            }
        }, true);

        document.addEventListener("click", e => {
            let target = e.target as Element;
            let autocomplete = target.closest(".autocomplete");

            if (autocomplete && autocomplete == this.container) return;

            this.listVisible = false;
        });
        
        this.list.addEventListener("click", e => {
            let target = e.target as HTMLElement;
            let parentLi = target.closest("li");
            
            if (parentLi) {
                let selected = parentLi.querySelector("span") as HTMLSpanElement;
                let id = selected.getAttribute("data-id");

                this.selected = this.findOptionById(id);

                this.containerVisible = false;
                this.listVisible = false;
            }
        });
    }
    
    private moveUp(e: KeyboardEvent): void {
        if (e.altKey) {
            if (this.olSpans.length > 0) this.listVisible = !this.listVisible;
            return;
        }

        if (!this.listVisible) return;

        let index = this.uiSelected ? this.olSpans.indexOf(this.uiSelected) -1 : 0;

        if (index < 0) index = 0;

        let prev = this.olSpans[index];
        this.uiSelected = prev;
    }
    
    private moveDown(e: KeyboardEvent): void {
        if (e.altKey) {
            if (this.olSpans.length > 0) this.listVisible = !this.listVisible;
            return;
        }

        if (!this.listVisible) return;

        let count = this.olSpans.length;
        let index = this.uiSelected ? this.olSpans.indexOf(this.uiSelected) + 1 : 0;

        if (index > count - 1) index = count - 1;

        let next = this.olSpans[index];
        this.uiSelected = next;
    }

    private onchange(e: Event) {
        if (this.timeoutHandler) clearTimeout(this.timeoutHandler);
        
        this.listVisible = false;
        this.selected = null;
        this.timeoutHandler = window.setTimeout(() => this.getDataFromServer(), 300);
    }

    private findSpanById(id: string): HTMLSpanElement {
        for (var i = 0; i < this.olSpans.length; i++) {
            let span = this.olSpans[i] as HTMLSpanElement;
            let spanId = span.getAttribute("data-id");

            if (spanId == id) return span;
        }

        return null;
    }

    private findOptionById(id: string): AutocompleteOption {
        for (var i = 0; i < this.listOptions.length; i++) {
            let option = this.listOptions[i];

            if (option.id == id) return option;
        }

        return null;
    }

    private findOptionByText(text: string): AutocompleteOption {
        for (var i = 0; i < this.listOptions.length; i++) {
            let option = this.listOptions[i];

            if (option.text.toLowerCase() == text.toLowerCase()) return option;
        }

        return null;
    }

    private getDataFromServer(): void {
        let valueText = this.textTerm.length > 0 ? this.textTerm : this.text;
        let apiArgument = valueText.replace(/,/g, "").replace(/ {2,}/g, " "); 
        let parameters = this.options.dataParameters(apiArgument);

        if (valueText == "" || valueText.length < 3) return;

        if (parameters) {
            let sp = new URLSearchParams();
            let url = this.options.url;

            for (var parameter in parameters) {
                sp.append(parameter, parameters[parameter]);
            }

            var stringParams = sp.toString();

            url += `?${stringParams}`;
            
            fetch(url, { method: "GET" }).then(response => {
                if (response.ok) return response.json();
                return [];
            }).then(response => {
                let canContinue = Common.isMobile ? (valueText == this.termInput.value) : (valueText == this.text);

                if (!canContinue) {
                    return;
                }

                let contentFragment = document.createDocumentFragment();

                this.clearList();
                this.listOptions = [];
                this.olSpans = [];

                if (this.options.autoSelect && response.length == 1) {
                    let firstOption = this.options.onItemResponse ?
                        this.options.onItemResponse(response[0]) : 
                        this.getAutocompleOption(response[0]);

                    this.selected = firstOption;
                } else {
                    for (let item of response) {
                        let option = this.options.onItemResponse ?
                            this.options.onItemResponse(item) :
                            this.getAutocompleOption(item);

                        this.listOptions.push(option);

                        let li = document.createElement("li");
                        let span = document.createElement("span");

                        span.setAttribute("data-id", option.id);

                        if (!this.options.highlight) {
                            span.innerText = option.text;
                        } else {
                            let reg = new RegExp(valueText, "ig");
                            let start = option.text.toLowerCase().indexOf(valueText.toLowerCase());
                            let word = option.text.substr(start, valueText.length);
                            let strong = `<strong>${word}</strong>`;
                            
                            span.innerHTML = option.text.replace(reg, strong);
                        }

                        this.olSpans.push(span);
                        li.appendChild(span);
                        
                        contentFragment.appendChild(li);
                    }

                    if (this.listOptions.length > 0) {
                        this.list.appendChild(contentFragment);
                        this.listVisible = true;
                    }
                }
            });
        }
    }

    private getAutocompleOption(item: any): AutocompleteOption {
        let ret = new AutocompleteOption();

        ret.id = this.getDataValue(item);
        ret.text = this.getDataText(item);
        ret.item = item;

        return ret;
    }

    private getDataText(item: any): string {
        return item[this.options.dataText];
    }

    private getDataValue(item: any): string {
        return item[this.options.dataValue];
    }

    private get text(): string {
        return this.element.value;
    }

    private set text(text: string) {
        this.element.value = text;
    }

    private get textTerm(): string {
        return this.termInput.value;
    }

    private set textTerm(value: string) {
        this.termInput.value = value;
    }

    private get listVisible(): boolean {
        return this.list.classList.contains("show");
    }

    private set listVisible(value: boolean) {
        if (value) {
            this.list.classList.add("show");
        } else {
            this.list.classList.remove("show");
        }
        
        if (!Common.isMobile) {
            this.containerVisible = value;
        }
    }

    private get containerVisible(): boolean {
        return this.container.classList.contains("autocomplete-opened");
    }

    private set containerVisible(value: boolean) {
        if (value) {
            this.container.classList.add("autocomplete-opened");
            if (Common.isMobile) document.body.classList.add("autocomplete-mobile-opened");
        } else {
            this.container.classList.remove("autocomplete-opened");
            if (Common.isMobile) document.body.classList.remove("autocomplete-mobile-opened");
        }
    }

    private scrollIntoView(ele: HTMLElement): void {
        let scrollTop = this.list.scrollTop;
        let height = this.list.offsetHeight;
        let itemTop = ele.offsetTop;
        let itemHeight = ele.offsetHeight;

        if (itemTop + itemHeight >= scrollTop + height) {
            let diff = (itemTop + itemHeight) - height;
            this.list.scrollTop = diff;
        }

        if (itemTop <= scrollTop)
            this.list.scrollTop = itemTop;
    }
}

export interface AutocompleteOptions {
    url: string;
    dataParameters: (value: string) => any;
    autoSelect?: boolean;
    highlight?: boolean;
    onSelect?: (option: AutocompleteOption) => void;
    onSelectCustomText?: (value: string) => void;
    onItemResponse?: (data: any) => AutocompleteOption;
    dataText: string;
    dataValue: string;
}

export class AutocompleteOption {
    id: string;
    text: string;
    item: any;
}
