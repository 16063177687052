

export class Toggle {
    static initialize() {
        $(".toggle-container").hide();
        $(".trigger, .trigger.opened").on("click", function (a) {
            $(this).toggleClass("active");
            a.preventDefault();
        });
        $(".trigger").on("click", function () {
            $(this).next(".toggle-container").slideToggle(300);
        });
        $(".trigger.opened").addClass("active").next(".toggle-container").show();
    }
}