import { ReCaptcha } from "./common/recaptcha";
import { manageKeyAllow } from "./common/key-allow";
import { Popup, PopupEvents } from "./common/popup";
import { ImageLazyies } from "./common/image-lazies";
import { CustomSelect } from "./common/custom-select";
import { MaterialControl } from "./common/material-control";

export class MyAccountBoards {
    static board: HTMLElement;
    static container: HTMLElement;    
    static boardList: HTMLElement;        
    static stars: NodeListOf<HTMLElement>;
    static calificacionInput: HTMLInputElement;   
    static boardPendingInvitationCaptcha: ReCaptcha;
   
    static boardEditPopup: Popup;
    static addCommentPopup: Popup;   
    static editCommentPopup: Popup;   
    static boardCreatePopup: Popup;
    static deleteCommentPopup: Popup;
    static boardAddMemberPopup: Popup;
    static boardEditMemberPopup: Popup;
    static boardEditMembersPopup: Popup;
    static boardDeleteMemberPopup: Popup;
    static boardInvitationSentPopup: Popup;
    static boardAddMemberListPopup: Popup;

    public static initialize() {
        this.container = document.getElementById("my-account-content");
        this.boardList = document.getElementById("my-boards-content");       

        if (this.container && !this.container.hasAttribute("board-binds")) {
            this.container.setAttribute("board-binds", "false");

            this.boardPendingInvitationCaptcha = new ReCaptcha("boardPendingInvitationCaptcha");
                      
            this.boardEditPopup = new Popup("boardEditPopup");
            this.addCommentPopup = new Popup("addCommentPopup");            
            this.editCommentPopup = new Popup("editCommentPopup");            
            this.boardCreatePopup = new Popup("boardCreatePopup"); 
            this.deleteCommentPopup = new Popup("deleteCommentPopup");
            this.boardAddMemberPopup = new Popup("boardAddMemberPopup");            
            this.boardEditMemberPopup = new Popup("boardEditMemberPopup");
            this.boardEditMembersPopup = new Popup("boardEditMembersPopup");
            this.boardDeleteMemberPopup = new Popup("boardDeleteMemberPopup");
            this.boardAddMemberListPopup = new Popup("boardAddMemberListPopup");
            this.boardInvitationSentPopup = new Popup("boardInvitationSentPopup");  
                       
            this.getBoard(); 
            this.createBoard();
            this.initPopups();
            this.buttons();
            this.sorting();            
        }   
    }

    private static getBoard() {
        const handleGetBoard = (e: Event) => {
            const target = e.target as HTMLElement;
            const loader = document.getElementById("my-account-loader");
            if (!target.hasAttribute("data-getboard-action")) return;
            window.scrollTo(0, 0);
            this.container.style.display = "none";
            loader.style.display = "block";
            const url = target.getAttribute("data-url-getboard");            

            fetch(url, {               
                credentials: "same-origin",
                headers: {
                    "X-Requested-With": 'XMLHttpRequest'
                }
            })
            .then(async response => {
                if (response.ok) { 
                    this.container.innerHTML = await response.text();
                    loader.style.display = "none";
                    this.container.style.display = "block";
                   
                    CustomSelect.initialize();              
                    ImageLazyies.initialize('my-account-content');
                    MaterialControl.initialize(this.container);
                    this.board = document.querySelector<HTMLElement>("[data-board]");
                }                               
            });            
        }
        this.container.addEventListener("click", handleGetBoard);
    }       

    private static sorting() {
        this.container.addEventListener("change", e => {
            const select = e.target as HTMLSelectElement;
            const loader = document.querySelector<HTMLElement>('#my-account-loader');
            if (!select.hasAttribute("data-favourite-select-sort")) return;

            this.container.style.display = "none";             
            loader.style.display = "block";
            const id = select.getAttribute("tablero");
            const url =
                `${select.getAttribute("data-url-favourites")}?idTablero=${id}&sort=${select.value}`;                 

            fetch(url, {
                method: "GET",
                credentials: "same-origin"
            }).then(async response => {
                if (response.ok) {
                    this.container.innerHTML = await response.text();
                    loader.style.display = "none";
                    this.container.style.display = "block";
           
                    CustomSelect.initialize();                  
                    ImageLazyies.initialize('my-account-content');                                                            
                }
            });
        });
    } 
 
    private static deleteComment(e: MouseEvent) {
        const target = e.target as HTMLElement;
        if (target.hasAttribute("data-delete-note")) {
            e.preventDefault();     
            this.editCommentPopup.close();

            const confirm = () => {
                const id = target.getAttribute("data-delete-note");
                this.deleteCommentSubmit(+id);
            };

            const title = target.getAttribute("data-delete-title");
            const info = target.getAttribute("data-delete-info");
            const btn = target.getAttribute("data-delete-btn");
            Popup.showCancelConfirm(title, `"${info}"`, btn, confirm);
        }
    }

    private static deleteCommentSubmit(idComment: number) {
        const form = document.querySelector<HTMLFormElement>(`#form-delete-comment-${idComment}`);
        const id = form.getAttribute("data-id-aviso");    

        const url = form.action;
        const data = new FormData(form);       

        fetch(url, {
            method: "POST",
            credentials: "same-origin",
            body: data
        }).then(async response => {
            if (response.ok) {
                const partial = await response.text();
                const notes = document.querySelector(`#comments-${id}`);                    
                notes.innerHTML = partial;                
            }
        })        
    }

    private static stateCardActions(ele: Element) {
        const close = ele.querySelectorAll("[data-close-note]");
        close.forEach(c => c.classList.remove("hidden"));

        const open = ele.querySelector("[data-open-note]");        
        open.classList.add("hidden");
    }

    private static editComment(e: MouseEvent) {
        const target = e.target as HTMLElement;
        if (target.hasAttribute("data-edit-note")) {
            e.preventDefault();
            const url = target.getAttribute("data-edit-note");
            const modal = document.querySelector("#edit-comment-modal");

            fetch(url, {
                method: "GET",
                credentials: "same-origin"
            }).then(async response => {
                if (response.ok) {
                    const partial = await response.text();
                    modal.innerHTML = partial;
                    this.editCommentPopup.show();
                    MaterialControl.initialize(this.container);

                    const txtarea = modal.querySelector<HTMLTextAreaElement>("textarea");
                    txtarea.focus();
                    this.editCommentSubmit();
                }
            })
        }
    }

    private static editCommentSubmit() {
        const form = document.querySelector<HTMLFormElement>("#form-edit-comment");
        const id = form.getAttribute("data-id-aviso");

        form.addEventListener("submit", (e) => {
            e.preventDefault();
            if (!$("#form-edit-comment").valid()) return;

            const url = form.action;
            const data = new FormData(form);
            const btn = form.querySelector<HTMLButtonElement>("button[type=submit]");
            btn.classList.add("btn-loading");

            fetch(url, {
                method: "POST",
                credentials: "same-origin",
                body: data
            }).then(async response => {
                if (response.ok) {
                    const partial = await response.text();                   
                    const notes = document.querySelector(`#comments-${id}`);
                    notes.innerHTML = partial;                   
                    this.editCommentPopup.close();
                }
            })
        });
    }

    private static addComment(e: MouseEvent) {
        const target = e.target as HTMLElement;
        if (target.hasAttribute("data-add-note")) {
            e.preventDefault(); 
            const url = target.getAttribute("data-add-note");
            const modal = document.querySelector("#add-comment-modal");

            fetch(url, {
                method: "GET",
                credentials: "same-origin"
            }).then(async response => {
                if (response.ok) {                    
                    const partial = await response.text();
                    modal.innerHTML = partial;                   
                    this.addCommentPopup.show();
                    MaterialControl.initialize(this.container);   

                    const txtarea = modal.querySelector<HTMLTextAreaElement>("textarea");                    
                    txtarea.focus();
                    this.addCommentSubmit();
                }
            })             
        }
    }

    private static addCommentSubmit() {
        const form = document.querySelector<HTMLFormElement>("#form-add-comment");
        const id = form.getAttribute("data-id-aviso");

        form.addEventListener("submit", (e) => {
            e.preventDefault();
            if (!$("#form-add-comment").valid()) return;

            const url = form.action;
            const data = new FormData(form);
            const btn = form.querySelector("button[type=submit]");            
            btn.classList.add("btn-loading");

            fetch(url, {
                method: "POST",
                credentials: "same-origin",
                body: data
            }).then(async response => {
                if (response.ok) {
                    const partial = await response.text();  
                    const notes = document.querySelector(`#comments-${id}`);
                    notes.innerHTML = partial;                 
                }    
                this.addCommentPopup.close();
            })
        });
    }

    private static deletelike(btn: HTMLElement) {
        const url = "/Tableros/DeleteRating";

        const actions = btn.closest("[data-fav-actions]");
        const likes = actions.querySelectorAll("[data-like]");

        const idFavorito = btn.getAttribute("data-id-favorito");
        const idRating = btn.getAttribute("data-id-rating");

        const formData = new FormData();
        formData.append("idRating", idRating);
        formData.append("idFavorito", idFavorito);
  
        fetch(url, { method: "POST", body: formData, credentials: "same-origin" })
            .then(res => {
                if (res.ok) {
                    likes.forEach(btn => {
                        btn.classList.remove("active");
                        btn.removeAttribute("data-id-rating");
                    });
                }
            });
    }


    private static like(btn: HTMLElement) {
        const url = "/Tableros/PostRating";

        const actions = btn.closest("[data-fav-actions]");
        const likes = actions.querySelectorAll("[data-like]");

        const idFavorito = btn.getAttribute("data-id-favorito");
        const calificacion = btn.getAttribute("data-calificacion");

        const formData = new FormData();
        formData.append("idFavorito", idFavorito);
        formData.append("calificacion", calificacion);

        likes.forEach(btn => btn.classList.remove("active"));
        btn.classList.add("active");

        fetch(url, { method: "POST", body: formData, credentials: "same-origin" })
            .then(res => {
                if (res.ok) return res.json();
            }).then((data: IDResponse) => {
                btn.setAttribute("data-id-rating", `${data.id}`);
            });
    }

    private static handleLike(e: MouseEvent) {
        const target = e.target as HTMLElement;

        if (target.hasAttribute("data-like")) {
            e.preventDefault();
            if (target.classList.contains("active")) this.deletelike(target);
            else this.like(target);
        }
    }

    private static openNote(e: MouseEvent) {
        const target = e.target as HTMLElement;
        if (target.hasAttribute("data-open-note")) {
            e.preventDefault();            
            const id = target.getAttribute("data-open-note");
            const card = document.querySelector(`#card-${id}`);            
            const close = card.querySelectorAll("[data-close-note]");

            card.classList.add("extend");
            target.classList.add("hidden");            
            close.forEach(c => c.classList.remove("hidden"));
        }

        if (target.hasAttribute("data-card-note"))
            e.preventDefault();
    }

    private static closeNote(e: MouseEvent) {
        const target = e.target as HTMLElement;
        if (target.hasAttribute("data-close-note")) {
            e.preventDefault();
            const id = target.getAttribute("data-close-note");
            const card = document.querySelector<HTMLElement>(`#card-${id}`);
            const close = card.querySelectorAll("[data-close-note]");
            const open = card.querySelector("[data-open-note]");
            const actions = document.querySelectorAll<HTMLElement>("[data-note-menu]:not(.hidden)");
            
            card.classList.remove("extend");
            target.classList.add("hidden");
            open.classList.remove("hidden");
            close.forEach(c => c.classList.add("hidden"));
            actions.forEach(a => a.classList.add("hidden"));
        }
    }    

    public static actions(e: MouseEvent) {
        const target = e.target as HTMLElement;  

        if (target.hasAttribute("data-note-open-menu")) {
            const content = target.closest("[data-note-actions]");
            const menu = content.querySelector<HTMLElement>("[data-note-menu]");          

            if (menu.classList.contains("hidden")) {
                this.hiddenActions();
                menu.classList.remove("hidden");
            } else {                 
                menu.classList.add("hidden");
            }
        } else 
            this.hiddenActions();           
    }

    private static hiddenActions() {
        const actions = document.querySelectorAll<HTMLElement>("[data-note-menu]:not(.hidden)");
        actions.forEach(a => a.classList.add("hidden"));
    }

    private static buttons() {
        this.container.addEventListener("click", e => {
            const target = e.target as HTMLElement;
                             
            this.editBoard(target);
            this.editMember(target);
            this.exitBoard(target);
            this.deleteBoard(target);
            this.deleteMember(target);       
            this.rejectInvite(target);
            this.resendInvite(target);
            this.removeFavorite(target);
            this.closePopups(target);
            this.actions(e);            
            this.addComment(e);
            this.editComment(e);
            this.deleteComment(e);
            this.openNote(e);
            this.handleLike(e);    
            this.closeNote(e);  
            this.addMember(target);
            this.addMemberList(target);
            this.addMemberListNext(target);
            this.editMembers(target);
        });    
    }

    private static editMembers(target: HTMLElement) {
        if (!target.hasAttribute("data-edit-members")) return;
        this.boardEditMembersPopup.show();
    }

    private static addMemberList(target: HTMLElement) {
        if (!target.hasAttribute("data-addmember-list")) return;
        this.boardAddMemberListPopup.show();
        const url = target.getAttribute("data-add-members-list");
        const loader = this.boardAddMemberListPopup.content.querySelector<HTMLElement>("[data-loader]");      
        const content = this.boardAddMemberListPopup.content.querySelector<HTMLElement>("#add-member-list");

        loader.style.display = "block";
        content.style.display = "none";
            
        fetch(url, {
            method: "GET",
            credentials: "same-origin"
        }).then(async response => {
            console.log(response)
            if (response.ok) {
                content.style.display = "block";
                loader.style.display = "none";
                const partial = await response.text();
                this.boardAddMemberListPopup.content.querySelector<HTMLElement>("#add-member-list").innerHTML = partial;

            } else
                this.boardAddMemberListPopup.close();
        });

        
    }

    private static addMemberListNext(target: HTMLElement) {
        if (!target.hasAttribute("data-addmember-list-next")) return;

        const inputs: NodeListOf<HTMLInputElement> = document.querySelectorAll("[data-board-id]");

        inputs.forEach(input => {
            if (input.checked) {
                (<HTMLInputElement>document.getElementById('addMemberIdTablero')).value = input.value;
                this.boardAddMemberListPopup.close();
                this.boardAddMemberPopup.show();
            }
        })
    }

    private static addMember(target: HTMLElement) {        
        if (!target.hasAttribute("data-add-member")) return;
        const id = this.board.getAttribute('data-board');
        (<HTMLInputElement>document.getElementById('addMemberIdTablero')).value = id;
        this.boardEditPopup.close();
        this.boardAddMemberPopup.show();        
    }

    private static editBoard(target: HTMLElement) {
        if (!target.hasAttribute("data-edit-board")) return;
        this.boardEditPopup.show();        
        const url = target.getAttribute("data-edit-board");
        const loader = document.querySelector<HTMLElement>("#boardEditPopup [data-loader]");                
       
        fetch(url, {
            method: "POST",
            credentials: "same-origin"
        }).then(async response => {
            if (response.ok) {
                loader.style.display = "none";
                const partial = await response.text(); 
                this.getBoardModal(partial);
                const popup = document.getElementById("boardEditPopup");
                manageKeyAllow(popup);
            } else
                this.boardEditPopup.close();                                         
        })       
    }

    private static getBoardModal(partial: string) {
        const modal = document.getElementById("edit-board-modal");
        modal.innerHTML = partial;
        MaterialControl.initialize(this.container);
        const input = modal.querySelector<HTMLInputElement>("input[type=text]");
        if (!input) return;
        
        input.focus();        
        this.editBoardSubmit();
    }

    private static editBoardSubmit() {
        const form = document.querySelector<HTMLFormElement>("#form-edit-board");
        const names = document.querySelectorAll("[data-board-label]");
        $.validator.unobtrusive.parse(form);

        form.addEventListener("submit", (e) => {
            e.preventDefault();
            if (!$("#form-edit-board").valid()) return;

            const url = form.action;
            const data = new FormData(form);
            const btn = form.querySelector<HTMLButtonElement>("button[type=submit]");
            btn.classList.add("btn-loading");

            fetch(url, {
                method: "POST",
                credentials: "same-origin",
                body: data
            }).then(async response => {
                if (response.ok) {                   
                    const data = await response.json();
                    names.forEach(n => n.innerHTML = data.nombre);                    
                    btn.classList.remove("btn-loading");
                }
                this.boardEditPopup.close();
            })
        });
    }

    private static deleteBoard(target: HTMLElement) {
        if (!target.hasAttribute("data-remove-list")) return;
        this.boardEditPopup.close();      

        const confirm = () => {
            const url = target.getAttribute("data-remove-list");
            const container = document.querySelector<HTMLElement>("#my-account-content");
            const loader = document.querySelector<HTMLElement>("#my-account-loader");

            container.style.display = "none";
            loader.style.display = "block";

            fetch(url, {
                method: "DELETE",                
                credentials: "same-origin"
            }).then(response => location.reload());
        };

        const title = target.getAttribute("data-remove-title");
        const info = target.getAttribute("data-remove-info");
        const btn = target.getAttribute("data-remove-btn");
        Popup.showCancelConfirm(title, info, btn, confirm);
    }

    private static exitBoard(target: HTMLElement) {
        if (!target.hasAttribute("data-exit-list")) return;
        this.boardEditPopup.close();

        const confirm = () => {
            const url = target.getAttribute("data-exit-list");
            const container = document.querySelector<HTMLElement>("#my-account-content");
            const loader = document.querySelector<HTMLElement>("#my-account-loader");

            container.style.display = "none";
            loader.style.display = "block";

            fetch(url, {
                method: "DELETE",
                credentials: "same-origin"
            }).then(response => location.reload());
        };

        const title = target.getAttribute("data-exit-title");
        const info = target.getAttribute("data-exit-info");
        const btn = target.getAttribute("data-exit-btn");
        Popup.showCancelConfirm(title, info, btn, confirm);
    }

    private static deleteMember(target: HTMLElement) {
        if (target.hasAttribute("data-delete-member-action")) {
            const title = target.getAttribute("data-title");
            this.boardEditMemberPopup.close();
            this.boardDeleteMemberPopup.title = title;
            this.boardDeleteMemberPopup.show();

            const btn = document.querySelector<HTMLElement>("[data-delete-member]");
            btn.addEventListener("click", () => {                
                const url = target.getAttribute("data-delete-member-action");
                this.boardDeleteMemberPopup.close(); 

                fetch(url, {
                    method: "POST",
                    credentials: "same-origin"
                }).then(async response => {
                    if (response.ok) this.getMembers();                        
                }) 
            });
        }
    }

    private static editMember(target: HTMLElement) {
        if (target.hasAttribute("data-edit-member-action")) {
            this.boardEditMembersPopup.close(); 
            this.boardEditMemberPopup.show();

            const url = target.getAttribute("data-edit-member-action");
            const loader = document.querySelector<HTMLElement>("#boardEditMemberPopup [data-loader]");  

            fetch(url, {
                method: "GET",
                credentials: "same-origin"
            }).then(async response => {
                if (response.ok) {
                    loader.style.display = "none";
                    const container = document.getElementById("board-edit-member");
                    container.innerHTML = await response.text();                    
                    this.editMemberSubmit();
                } else
                    this.boardEditMemberPopup.close();
            });
        }            
    }

    private static editMemberSubmit() {
        const form = document.querySelector<HTMLFormElement>("#form-edit-member");
       
        form.addEventListener("submit", (e) => {
            e.preventDefault();           

            const url = form.action;
            const data = new FormData(form);
            const modal = document.getElementById("edit-board-modal"); 
            const btn = form.querySelector<HTMLButtonElement>("button[type=submit]");            
            btn.classList.add("btn-loading");

            fetch(url, {
                method: "POST",
                credentials: "same-origin",
                body: data
            }).then(async response => {
                if (response.ok) {
                    this.getMembers();
                    this.boardEditMemberPopup.close();
                }                   
            })
        });
    }   

    private static getMembers() {
        const content = document.querySelector<HTMLElement>("#board-members"); 
        const url = content.getAttribute("data-url-members");   

        fetch(url, {
            method: "GET",
            credentials: "same-origin"
        }).then(async response => {
            content.innerHTML = "";  
            const partial = await response.text();
            content.innerHTML = partial;               
        });
    }

    private static resendInvite(target: HTMLElement) {
        if (target.hasAttribute("data-resend-invite")) {     
            const form = document.querySelector<HTMLFormElement>("#resend-invite");

            const input = form.querySelector<HTMLInputElement>("[data-privilegio]");
            const radios: NodeListOf<HTMLInputElement> = document.querySelectorAll("[data-filter-value]");
            radios.forEach(r => {
                if (r.checked) input.value = r.value;
            });

            const data = new FormData(form);
            this.boardEditMemberPopup.close();

            fetch(form.action, {
                method: "POST",
                body: data,
                credentials: "same-origin"
            }).then(async response => {
                if (response.ok) {
                    const content = document.querySelector<HTMLElement>('#board-add-member-success');
                    content.innerHTML = await response.text();
                    this.boardInvitationSentPopup.show();
                }
            });
        }   
    }

    private static rejectInvite(target: HTMLElement) {
        if (target.hasAttribute("data-reject-invite-action")) {

            const confirm = () => {                
                const url = target.getAttribute("data-reject-invite-action");
                
                fetch(url, {
                    method: "GET",
                    credentials: "same-origin"
                }).then(response => location.reload());
            };

            const title = target.getAttribute("data-reject-title");
            const info = target.getAttribute("data-reject-info");
            const btn = target.getAttribute("data-reject-btn");
            Popup.showCancelConfirm(title, info, btn, confirm);
        }
    }

    private static removeFavorite(target: HTMLElement) {
        if (!target.hasAttribute("data-favorite")) return;

        target.classList.add("favorite-loading");
        const url = target.getAttribute("data-url-remove-fav");
        fetch(url, {
            method: "POST",
            credentials: "same-origin"
        })
        .then(async response => {
            if (response.ok)
                target.closest("[data-card-fav]").remove();
        });        
    }    

    private static closePopups(target: HTMLElement) {
        if (target.hasAttribute("data-popup-close")) {
            this.boardEditPopup.close();
            this.addCommentPopup.close();
            this.editCommentPopup.close();
            this.deleteCommentPopup.close();            
            this.boardEditMemberPopup.close();            
            this.boardCreatePopup.close();
            this.boardAddMemberListPopup.close()
        }                   
    }

    private static createBoard() {
        const container = document.querySelector("#my-account-content");
        container.addEventListener("click", e => {
            const btn = e.target as HTMLElement;
            if (btn.hasAttribute("data-board-create-popup"))
                this.boardCreatePopup.show();
        }); 
    }

    private static initPopups() {  
        const boardCreatePopupEventDelegate = (type: PopupEvents, data: any) => {
            if (type == PopupEvents.Show) {
                const popup = document.getElementById("boardCreatePopup");
                manageKeyAllow(popup);
            } else if (type == PopupEvents.Fail) {
                const popup = document.getElementById("boardCreatePopup");
                manageKeyAllow(popup);
            }

            if (type == PopupEvents.Success)
                this.boardCreatePopup.close();
        }

        const boardEditPopupEventDelegate = (type: PopupEvents, data: any) => {
            if (type == PopupEvents.Show) {
                const loader = document.querySelector<HTMLElement>("#boardEditPopup [data-loader]");
                loader.style.display = "block";
            }
            if (type == PopupEvents.Close) {                
                const content = document.querySelector<HTMLElement>("#edit-board-modal"); 
                content.innerHTML = "";
            }
        }

        const boardAddMemberPopupEventDelegate = (type: PopupEvents, data: any) => {
            if (type == PopupEvents.Success) {
                this.boardAddMemberPopup.close();
                (document.querySelector("#boardInvitationSentPopup .popup-title") as HTMLElement).innerHTML = "Invitación enviada";
                this.boardInvitationSentPopup.show();

                const boardItemPage = document.querySelector("[data-board]");
                if (boardItemPage)    this.getMembers();
            }

            if (type == PopupEvents.Fail) {
                this.boardAddMemberPopup.close();
                (document.querySelector("#boardInvitationSentPopup .popup-title") as HTMLElement).innerHTML = "Error al enviar la invitación";
                this.boardInvitationSentPopup.show();
                const loader = document.querySelector<HTMLElement>("#board-add-member-success [data-loader]");
                loader.style.display = "none";
            } 
        }

        const boardInvitationSentPopupEventDelegate = (type: PopupEvents, data: any) => {
            if (type == PopupEvents.Success) {
                (document.getElementById("board-add-member-success") as HTMLElement).innerHTML = '<i class="fa fa-refresh fa-spin fa-2x fa-fw"></i>';
                this.boardInvitationSentPopup.close();
            }
        }

        const boardEditMemberPopupEventDelegate = (type: PopupEvents, data: any) => {
            if (type == PopupEvents.Show) {
                const loader = document.querySelector<HTMLElement>("#boardEditMemberPopup [data-loader]");
                loader.style.display = "block";
            }
            if (type == PopupEvents.Close) {
                const content = document.querySelector<HTMLElement>("#board-edit-member");
                content.innerHTML = "";
            }                   
        }      
    
        this.boardEditPopup.event((t, e, d) => boardEditPopupEventDelegate(t, d));
        this.boardCreatePopup.event((t, e, d) => boardCreatePopupEventDelegate(t, d));       
        this.boardAddMemberPopup.event((t, e, d) => boardAddMemberPopupEventDelegate(t, d));
        this.boardEditMemberPopup.event((t, e, d) => boardEditMemberPopupEventDelegate(t, d));
        this.boardInvitationSentPopup.event((t, e, d) => boardInvitationSentPopupEventDelegate(t, d));       
    }
}

class IDResponse {
    id: number
}