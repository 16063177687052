import { Common } from "../common";

class Cascade {
    url: string;
    destination: string;
    autoselect: boolean;

    private elementDest: HTMLSelectElement;
    
    constructor(private element: HTMLSelectElement) {
        //if (element.hasAttribute("data-cascade-binded")) return;

        this.url = element.getAttribute("data-cascade-url");
        this.destination = element.getAttribute("data-cascade-dest");
        this.autoselect = element.hasAttribute("data-cascade-autoselect");

        this.elementDest = document.querySelector(`#${this.destination}`) as HTMLSelectElement;

        //this.element.setAttribute("data-cascade-binded", "true");
    }

    init(): void {
        this.element.addEventListener("change", e => {
            let value = this.element.value;

            if (value && value != "") {
                let url = this.getUrlWith(value);

                this.destDisable = true;

                fetch(url, { method: "GET", credentials: "same-origin" }).then(response => {
                    if (response.ok) return response.json();
                    return [];
                }).then(data => this.loadDestination(data));
            } else {
                this.loadDestination([]);
            }
        });
    }

    private set destDisable(value: boolean) {
        if (value) {
            this.elementDest.setAttribute("disabled", "disabled");

            this.elementDest.classList.remove("input-validation-error");

            let spanValidation = this.elementDest
                .parentElement
                .querySelector("[class^=field-validation]");

            if (spanValidation) {
                spanValidation.classList.remove("field-validation-error");
                spanValidation.classList.add("field-validation-valid");

                spanValidation.innerHTML = "";
            }
        } else {
            this.elementDest.removeAttribute("disabled");
        }
    }

    private get destDisable(): boolean {
        return this.elementDest.hasAttribute("disabled");
    }

    private loadDestination(data: SelectListItem[]): void {
        this.elementDest.innerHTML = null;
        
        let fragment = document.createDocumentFragment();
        let itemsCount = 0;

        for (let item of data) {
            let option = document.createElement("option");

            option.value = item.Value;
            option.innerText = item.Text;
            option.disabled = item.Disabled;
            option.selected = item.Selected;

            fragment.appendChild(option);

            if (item.Value) itemsCount++;
        }

        if (fragment.childElementCount == 0) {
            this.destDisable = true;
        } else {
            this.destDisable = false;

            this.elementDest.appendChild(fragment);

            if (this.autoselect && itemsCount == 1) this.selectFirstValidItem(this.elementDest);
        }

        let changeEvent = Common.createEvent("change");
        this.elementDest.dispatchEvent(changeEvent);
    }

    private selectFirstValidItem(selectEle: HTMLSelectElement) {
        for (var i = 0; i < selectEle.options.length; i++) {
            let option = selectEle.options[i] as HTMLOptionElement;

            option.selected = false;

            if (option.value) option.selected = true;
        }
    }

    private getUrlWith(value: string): string {
        let endWith = this.url.substr(-1, 1);

        if (endWith == "/") {
            return `${this.url}${value}`;
        }
        else {
            return `${this.url}/${value}`;
        }
    }
}

class SelectListItem {
    Disabled: boolean;
    Group: string;
    Selected: boolean;
    Text: string;
    Value: string;
}

export class CascadeDropdown {
    static initialize(selector?: string) {
        selector = selector ? `#${selector}` : null;

        let container = selector ? document.querySelector(selector) : document;
        let eles = container.querySelectorAll("[data-cascade-url]") || [];

        for (var i = 0; i < eles.length; i++) {
            let ele = eles[i] as HTMLSelectElement;
            let cascade = new Cascade(ele);

            cascade.init();
        }
    }
}
