import { Common } from "./common";
import { MyAccount } from "./my-account";
import { Popup, PopupEvents } from "./common/popup";
import { MaterialControl } from "./common/material-control";

export class MyAccountDelete {
    static container: HTMLElement;
    static updateMotivoPopup: Popup;

    public static initialize() {
        this.container = document.getElementById("my-account-content");

        if (this.container && !this.container.hasAttribute("delete-binds")) {
            this.container.setAttribute("delete-binds", "false");

            this.updateMotivoPopup = new Popup("updateMotivoPopup");
            this.listeners();
        }
    }

    private static listeners() {
        document.querySelector('body').addEventListener('click', e => {
            const target = e.target as HTMLElement;

            if (target.hasAttribute("data-delete-off"))
                this.removeDelete(target);

            if (target.hasAttribute("data-update-motivo"))
                this.updateMotivo(target);

            if (target.hasAttribute("data-delete-motivo"))
                this.deleteMotivo(target);

            if (target.hasAttribute("data-popup-close"))
                this.updateMotivoPopup.close();

        });
    }

    private static deleteMotivo(target: HTMLElement) {
        const aviso = this.updateMotivoPopup.content.querySelector<HTMLInputElement>("#IdAviso");
        const url = target.getAttribute("data-delete-url");
        this.updateMotivoPopup.close();

        const confirm = () => {
            fetch(url, {
                method: "POST",
                credentials: "same-origin"

            }).then(async response => {
                if (response.ok) {
                    const partial = await response.text();
                    const notes = document.querySelector(`#delete-${aviso.value}`);
                    notes.innerHTML = partial;
                }
            })
        };

        const title = target.getAttribute("data-delete-title");
        const info = target.getAttribute("data-delete-info");
        const btn = target.getAttribute("data-delete-btn");
        Popup.showCancelConfirm(title, `"${info}"`, btn, confirm);
    }

    private static updateMotivo(target: HTMLElement) {
        const url = target.getAttribute("data-update-motivo");
        const modal = document.querySelector("#update-motivo-modal");

        fetch(url, {
            method: "GET",
            credentials: "same-origin"
        }).then(async response => {
            if (response.ok) {
                const partial = await response.text();
                modal.innerHTML = partial;
                this.updateMotivoPopup.show();
                MaterialControl.initialize(this.container);

                const txtarea = modal.querySelector<HTMLTextAreaElement>("textarea");
                txtarea.focus();
                this.submitMotivo();
            }
        })
    }

    private static submitMotivo() {
        const form = document.querySelector<HTMLFormElement>("#form-motivo");
        const aviso = form.querySelector<HTMLInputElement>("#IdAviso");

        form.addEventListener("submit", (e) => {
            e.preventDefault();
            if (!$("#form-motivo").valid()) return;
            const url = form.action;
            const data = new FormData(form);

            fetch(url, {
                method: "POST",
                credentials: "same-origin",
                body: data
            }).then(async response => {
                if (response.ok) {
                    const partial = await response.text();
                    const notes = document.querySelector(`#delete-${aviso.value}`);
                    notes.innerHTML = partial;
                    this.updateMotivoPopup.close();
                }
            })
        });
    }

    private static removeDelete(target: HTMLElement) {

        const pageSize = parseInt((<HTMLInputElement>document.getElementById("PageSize")).value);
        var totalCount = parseInt((<HTMLInputElement>document.getElementById("TotalCount")).value);
        var paginador = document.querySelector('.pagination--links') as HTMLElement;

        const icon = target.querySelector<HTMLElement>("[data-icon]");
        const spinner = target.querySelector<HTMLElement>("[data-loader]");
        icon.classList.add("hide");
        spinner.classList.add("show");

        const idAviso = parseInt(target.getAttribute("data-id-aviso"));
        const idVisibilidad = parseInt(target.getAttribute("data-id-visibilidad"));

        Common.ToggleDelete(idAviso, idVisibilidad, () => {
            target.closest("[data-card-delete]").classList.add("hide");

            if ((totalCount - document.getElementsByClassName("card-delete hide").length) <= pageSize)
                if (paginador != null) paginador.style.display = "none";

            if (document.getElementsByClassName("card-delete hide").length ==
                document.getElementsByClassName("card-delete").length) {
                MyAccount.routingTo(window.location.pathname);
            }
        });
    }
}