
export interface IEasingFunction {
    (t: number, f: number, c: number, d: number): number;
}

export class Animate {
    private interval = 16; // 60 fps
    private timeId = 0;
    private startTime: number = 0;
    private update: (value: number) => void;
    private end: (value: number) => void;
    private change: number = 0;

    constructor(
        public from?: number,
        public to?: number,
        public duration?: number,
        public easing: IEasingFunction = Easings.Linear) {
    }

    start(update: (value: number) => void, end?: (value: number) => void): void {
        this.startTime = +new Date();
        this.change = this.to - this.from;

        this.update = update;
        this.end = end;

        this.update(this.from);

        this.doAnimation();
    }

    stop(): void {
        this.clearAnimation();
    }

    private loop(): void {
        let time = +new Date() - this.startTime;

        this.update(this.easing(time, this.from, this.change, this.duration));

        if (time >= this.duration) {
            this.update(this.to);
            if(this.end) this.end(this.to);
        } else {
            this.doAnimation();
        }
    }

    private doAnimation() {
        this.timeId = window.setTimeout(() => this.loop(), this.interval);
    }

    private clearAnimation() {
        clearTimeout(this.timeId);
    }
}

export class Easings extends Animate {
    static Linear(t, b, c, d) {
        return c * t / d + b;
    }

    static EaseInQuad(t, b, c, d) {
        return c * (t /= d) * t + b;
    }

    static EaseOutQuad(t, b, c, d) {
        return -c * (t /= d) * (t - 2) + b;
    }

    static EaseInOutQuad(t, b, c, d) {
        if ((t /= d / 2) < 1) return c / 2 * t * t + b;
        return -c / 2 * ((--t) * (t - 2) - 1) + b;
    }

    static EaseInCubic(t, b, c, d) {
        return c * (t /= d) * t * t + b;
    }

    static EaseOutCubic(t, b, c, d) {
        return c * ((t = t / d - 1) * t * t + 1) + b;
    }

    static EaseInOutCubic(t, b, c, d) {
        if ((t /= d / 2) < 1) return c / 2 * t * t * t + b;
        return c / 2 * ((t -= 2) * t * t + 2) + b;
    }

    static EaseInQuart(t, b, c, d) {
        return c * (t /= d) * t * t * t + b;
    }

    static EaseOutQuart(t, b, c, d) {
        return -c * ((t = t / d - 1) * t * t * t - 1) + b;
    }

    static EaseInOutQuart(t, b, c, d) {
        if ((t /= d / 2) < 1) return c / 2 * t * t * t * t + b;
        return -c / 2 * ((t -= 2) * t * t * t - 2) + b;
    }

    static EaseInQuint(t, b, c, d) {
        return c * (t /= d) * t * t * t * t + b;
    }

    static EaseOutQuint(t, b, c, d) {
        return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
    }

    static EaseInOutQuint(t, b, c, d) {
        if ((t /= d / 2) < 1) return c / 2 * t * t * t * t * t + b;
        return c / 2 * ((t -= 2) * t * t * t * t + 2) + b;
    }

    static EaseInSine(t, b, c, d) {
        return -c * Math.cos(t / d * (Math.PI / 2)) + c + b;
    }

    static EaseOutSine(t, b, c, d) {
        return c * Math.sin(t / d * (Math.PI / 2)) + b;
    }

    static EaseInOutSine(t, b, c, d) {
        return -c / 2 * (Math.cos(Math.PI * t / d) - 1) + b;
    }

    static EaseInExpo(t, b, c, d) {
        return (t == 0) ? b : c * Math.pow(2, 10 * (t / d - 1)) + b;
    }

    static EaseOutExpo(t, b, c, d) {
        return (t == d) ? b + c : c * (-Math.pow(2, -10 * t / d) + 1) + b;
    }

    static EaseInOutExpo(t, b, c, d) {
        if (t == 0) return b;
        if (t == d) return b + c;
        if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
        return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
    }

    static EaseInCirc(t, b, c, d) {
        return -c * (Math.sqrt(1 - (t /= d) * t) - 1) + b;
    }

    static EaseOutCirc(t, b, c, d) {
        return c * Math.sqrt(1 - (t = t / d - 1) * t) + b;
    }

    static EaseInOutCirc(t, b, c, d) {
        if ((t /= d / 2) < 1) return -c / 2 * (Math.sqrt(1 - t * t) - 1) + b;
        return c / 2 * (Math.sqrt(1 - (t -= 2) * t) + 1) + b;
    }

    static EaseInElastic(t, b, c, d) {
        var p = 0;
        var a = c;
        if (t == 0) return b;
        if ((t /= d) == 1) return b + c;
        if (!p) p = d * .3;
        if (a < Math.abs(c)) {
            a = c;
            var s = p / 4;
        }
        else var s = p / (2 * Math.PI) * Math.asin(c / a);
        return -(a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b;
    }

    static EaseOutElastic(t, b, c, d) {
        var p = 0;
        var a = c;
        if (t == 0) return b;
        if ((t /= d) == 1) return b + c;
        if (!p) p = d * .3;
        if (a < Math.abs(c)) {
            a = c;
            var s = p / 4;
        }
        else var s = p / (2 * Math.PI) * Math.asin(c / a);
        return a * Math.pow(2, -10 * t) * Math.sin((t * d - s) * (2 * Math.PI) / p) + c + b;
    }

    static EaseInOutElastic(t, b, c, d) {
        var p = 0;
        var a = c;
        if (t == 0) return b;
        if ((t /= d / 2) == 2) return b + c;
        if (!p) p = d * (.3 * 1.5);
        if (a < Math.abs(c)) {
            a = c;
            var s = p / 4;
        }
        else var s = p / (2 * Math.PI) * Math.asin(c / a);
        if (t < 1) return -.5 * (a * Math.pow(2, 10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p)) + b;
        return a * Math.pow(2, -10 * (t -= 1)) * Math.sin((t * d - s) * (2 * Math.PI) / p) * .5 + c + b;
    }

    static EaseInBack(t, b, c, d, s) {
        if (s == undefined) s = 1.70158;
        return c * (t /= d) * t * ((s + 1) * t - s) + b;
    }

    static EaseOutBack(t, b, c, d, s) {
        if (s == undefined) s = 1.70158;
        return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
    }

    static EaseInOutBack(t, b, c, d, s) {
        if (s == undefined) s = 1.70158;
        if ((t /= d / 2) < 1) return c / 2 * (t * t * (((s *= (1.525)) + 1) * t - s)) + b;
        return c / 2 * ((t -= 2) * t * (((s *= (1.525)) + 1) * t + s) + 2) + b;
    }

    static EaseInBounce(t, b, c, d) {
        return c - Easings.EaseOutBounce(d - t, 0, c, d) + b;
    }

    static EaseOutBounce(t, b, c, d) {
        if ((t /= d) < (1 / 2.75)) {
            return c * (7.5625 * t * t) + b;
        } else if (t < (2 / 2.75)) {
            return c * (7.5625 * (t -= (1.5 / 2.75)) * t + .75) + b;
        } else if (t < (2.5 / 2.75)) {
            return c * (7.5625 * (t -= (2.25 / 2.75)) * t + .9375) + b;
        } else {
            return c * (7.5625 * (t -= (2.625 / 2.75)) * t + .984375) + b;
        }
    }

    static EaseInOutBounce(t, b, c, d) {
        if (t < d / 2) return Easings.EaseInBounce(t * 2, 0, c, d) * .5 + b;
        return Easings.EaseOutBounce(t * 2 - d, 0, c, d) * .5 + c * .5 + b;
    }

}
