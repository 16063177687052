import { Common } from "../components/common";
declare var googletag: any;

export class BannerExpandable {    
    static initialize() {        
        googletag.cmd.push(() => {
            let flag = true;
            googletag.pubads().addEventListener('slotVisibilityChanged', event => {
                const container = document.querySelector("[data-banner-expandable]");
                if (!container) return;
                   
                const iframe = container.querySelector("iframe"); 
                const iframeBody = iframe.contentDocument.body;
                const btn = iframeBody.querySelector(".banner-btn1") as HTMLElement;

                if (!btn) return;
                if (flag) {
                    setTimeout(() => {
                        const ev = Common.createEvent("click");
                        btn.dispatchEvent(ev);
                    }, 5500);

                    flag = false;
                } 

                this.on(iframeBody, ".banner-btn1", "click", e => {
                    container.classList.add('has-expanded' );
                });

                this.on(iframeBody, ".banner-btn2, .banner-btn3", "click", e => {
                    container.classList.remove('has-expanded');
                }); 
            });
        });
    }

    private static on(container: Element, selector: string, event: string, callback: any) {
        const eles = container.querySelectorAll(selector);
        for (var i = 0; i < eles.length; i++) {
            let ele = eles.item(i);

            const hasBinded = ele.hasAttribute("data-binded");

            if (!hasBinded) {
                ele.addEventListener(event, callback);
                ele.setAttribute("data-binded", "true");
            } 
        }
    }
}
