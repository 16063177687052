export class Chat {
    static initialize() {
        let $chatBody = $("[data-chat-body]");

        if ($chatBody.length === 0) {
            return;
        }

        let positionChatBody = $chatBody.position();
        let $chatSubmit = $("[data-chat-submit]");
        let $chatForm = $("[data-chat-form]");
        let $chatFormContainer = $("[data-chat-form-container]");
        let $chatLoading = $(".loader-container > [data-loader]");
        let $backButton = $("[data-back-button]");
        let $fromMailNavigation = $(".isFromEmail");
        let $fromChatNavigation = $(".isfromChat");
        let chatLoadingWrapper = document.getElementById('chat-loader-in-conversation-form');
        
        $chatSubmit.on("click", (e) => {           
            if ($chatForm.valid()) {
                $chatSubmit.prop("disabled", "disabled");
                chatLoadingWrapper.style.height = "42px";
                $chatLoading.show();     
                $chatForm.submit();
            }
            e.preventDefault();            
        });
        //Si viene desde mail muestro el texto anterior (buscar propiedades -> mensajes)
        //si viene desde chat, muestro (cuenta -> mis mensajes)
        if (window.history && window.history.length > 1) {
            $fromMailNavigation.remove();
            $fromChatNavigation.show();
        } else {
            $fromChatNavigation.remove();
            $fromMailNavigation.show();            
        }


        $backButton.on("click", (e) => {
            window.history.back();
            e.preventDefault();
        });

       
        let cssFixedForm = function(){
            if ($(window).width() <= 425) {
                $(window).on('scroll', cssFixedFormScroll);
            } else {
                $chatFormContainer.removeClass("sm-chat-form-fixed");
                $(window).off('scroll', cssFixedFormScroll);
            }
        }

        let cssFixedFormScroll = function(){
            let currentScrollTop = $(window).scrollTop();

            if (currentScrollTop >= positionChatBody.top - 300) {
                $chatFormContainer.removeClass("sm-chat-form-fixed");
            } else {
                $chatFormContainer.addClass("sm-chat-form-fixed");
            }
        }

        $(window).on("load", cssFixedForm);
        $(window).on("resize", cssFixedForm);       
    }
}