

export class RangeSliders {
    static initialize() {
        // Area Range
        $("#area-range").each(function () {
            const me = $(this);
            const dataMin = parseInt(me.attr("data-min"));
            const dataMax = parseInt(me.attr("data-max"));
            var dataUnit = me.attr("data-unit");
            me.append("<input type='text' class='first-slider-value'disabled/><input type='text' class='second-slider-value' disabled/>");
            me.slider({
                range: true,
                min: dataMin,
                max: dataMax,
                step: 10,
                values: [dataMin, dataMax],
                slide: function (event, ui) {
                    $(this).children(".first-slider-value").val(ui.values[0] + " " + dataUnit);
                    $(this).children(".second-slider-value").val(ui.values[1] + " " + dataUnit);
                }
            });
            me.children(".first-slider-value").val(me.slider("values", 0) + " " + dataUnit);
            me.children(".second-slider-value").val(me.slider("values", 1) + " " + dataUnit);
        });
        // Price Range
        $("#price-range").each(function () {
            const me = $(this);
            const dataMin = parseInt(me.attr("data-min"));
            const dataMax = parseInt(me.attr("data-max"));
            var dataUnit = me.attr("data-unit");
            me.append("<input type='text' class='first-slider-value' disabled/><input type='text' class='second-slider-value' disabled/>");
            me.slider({
                range: true,
                min: dataMin,
                max: dataMax,
                values: [dataMin, dataMax],
                slide: function (event, ui) {
                    $(this).children(".first-slider-value").val(dataUnit + ui.values[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
                    $(this).children(".second-slider-value").val(dataUnit + ui.values[1].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
                    $(this).parent().find("[data-hidden-first-slider-value]").val(ui.values[0]);
                    $(this).parent().find("[data-hidden-second-slider-value]").val(ui.values[1]);
                }
            });
            me.children(".first-slider-value").val(dataUnit + me.slider("values", 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
            me.children(".second-slider-value").val(dataUnit + me.slider("values", 1).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
            me.parent().find("[data-hidden-first-slider-value]").val(me.slider("values", 0));
            me.parent().find("[data-hidden-second-slider-value]").val(me.slider("values", 1));
        });
    }
}