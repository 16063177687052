

export class Masonry {
    static initialize() {
        // Agent Profile Alignment
        $(window).on("load resize", () => {
            $(".agents-grid-container").masonry({
                itemSelector: ".grid-item",
                columnWidth: ".grid-item",
                percentPosition: true
            });
            var agentAvatarHeight = $(".agent-avatar img").height();
            var agentContentHeight = $(".agent-content").innerHeight();
            if (agentAvatarHeight < agentContentHeight) {
                $(".agent-page").addClass("long-content");
            }
            else {
                $(".agent-page").removeClass("long-content");
            }
        });
    }
}