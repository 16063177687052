export class Line{
    p1: number[];
    p2: number[];

    constructor(p1: number[], p2: number[]) {
        this.p1 = p1;
        this.p2 = p2;
    }

    rise(): number {
        return this.p2[1] - this.p1[1];
    }

    run(): number {
        return this.p2[0] - this.p1[0];
    }

    slope(): number{
        return this.rise() / this.run();
    }
    
    yIntercept(){
        return this.p1[1] - (this.p1[0] * this.slope());
    }
    
    isVertical(){
        return !isFinite(this.slope());
    }

    isHorizontal(){
        return this.p1[1] == this.p2[1];
    }

    perpendicularDistance = function(point: number[]){
        if (this.isVertical()) {
            return this.perpendicularDistanceVertical(point);
        }
        else if (this.isHorizontal()) {
            return this.perpendicularDistanceHorizontal(point);
        }
        else {
            return this.perpendicularDistanceHasSlope(point);
        }
    }

    private perpendicularDistanceHorizontal(point: number[]){
        return Math.abs(this.p1[1] - point[1]);
    }

    private perpendicularDistanceVertical = function(point: number[]){
        return Math.abs(this.p1[0] - point[0]);
    }

    private perpendicularDistanceHasSlope = function(point: number[]){
        var slope = this.slope();
        var y_intercept = this.yIntercept();

        return Math.abs((slope * point[0]) - point[1] + y_intercept) / Math.sqrt((Math.pow(slope, 2)) + 1);
    }
}
