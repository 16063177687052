declare var dataLayer: any;

export const pushEventoContacto = (medio: MedioContacto) =>
{
    let event = {
        'event': 'contacto',
        'medio': medio.toString().toLowerCase()
    }

    dataLayer.push(event);
};

export const pushEventoGenerico = (gtm: GtmEvent) =>
{
    let event = {
        'event': gtm.name,
        'category': gtm.category,
        'action': gtm.action,
        'label': gtm.label
    }

    dataLayer.push(event);
};

export enum MedioContacto
{
    Telefono = "telefono",
    Texto = "texto",
    Whatsapp = "whatsapp"
}

export class GtmEvent
{
    name: string;
    category: string;
    action: string;
    label: string;
}