export class Facebook {

    static SearchEvent(ids: Array<string>) {
        let w = <any>window;
        w.fbq('track', 'Search', {
            content_type: 'home_listing',
            content_ids: ids
        });
    }

    static ViewEvent(id) {
        let w = <any>window;
        w.fbq('track', 'ViewContent', {
            content_type: 'home_listing',
            content_ids: id
        });
    }

    static PurchaseEvent(idAviso, price, moneda) {
        let w = <any>window;
        w.fbq('track', 'Purchase', {
            content_ids: [idAviso],
            content_type: 'home_listing',
            value: price,
            currency: moneda });
    }
}