import { Common } from ".";
import { Popup, PopupEvents, StickyPosition } from "./popup";
import { Ficha } from "../ficha";
import { MaterialControl } from "./material-control";

export class Favourites {
    favouritePopup: Popup;
    boardStickyAddMemberPopup: Popup;
    boardsTutorialPopup: Popup;

    private get createBoardEle(): HTMLElement {
        return document.querySelector("[data-create-board]") as HTMLElement;
    }

    private get closeBoardEle(): HTMLElement {
        return document.querySelector("[data-close-board]") as HTMLElement;
    }

    private get boardNameEle(): HTMLInputElement {
        return document.querySelector("[data-board-name]") as HTMLInputElement;
    }

    private set createBoardBoxEditMode(value: boolean) {
        let box = document.querySelector("[data-create-board-box]") as HTMLElement;
        let createNewBoard = document.querySelector("[data-create-new-board]") as HTMLInputElement;
        let stickyAddMember = document.querySelector("[data-show-sticky-add-member]") as HTMLInputElement;

        if (value) {
            box.classList.add("mode-edit");
        } else {
            box.classList.remove("mode-edit");
        }

        if (stickyAddMember) {
            stickyAddMember.value = value ? "true" : "false";
        }

        createNewBoard.value = value ? "true" : "false";
    }

    static initialize(prefixSelector?: string) {
        let favourite = new Favourites();

        favourite.favouriteButtonInitialize(prefixSelector);
    }

    private callbackLogin(idAviso: number) {
        const btn = document.querySelector(`[data-favourite-button-id="${idAviso}"]`);
        this.clickFavBtnInner(btn);
    }

    private favouriteButtonInitialize(prefixSelector?: string) {
        let ids = [];
        let selector = (prefixSelector) ? `#${prefixSelector}` : "";
        const buttons = document.querySelectorAll(`${selector} [data-favourite-button]`);

        if (buttons.length === 0) return;
        const url = buttons[0].getAttribute("data-favourite-button-check-url")

        for (let i = 0; i < buttons.length; i++) 
            ids.push(buttons[i].getAttribute("data-favourite-button-id"));        

        $.getJSON(url, $.param({idavisos: ids}, true), data => {
            if (data.length == 0) return;
            data.forEach(item => {
                const btn = document.querySelector(`[data-favourite-button-id="${item.IdAviso}"]`);
                this.changeFavStatus(item.Favorito, btn);
            });
        });        

        if (!document.body.hasAttribute("data-favourite-bind")) {
            document.body.addEventListener("click", e => this.clickFavBtn(e));
            document.body.setAttribute("data-favourite-bind", "true");
        }

        var chks = document.querySelectorAll(".chkBoard"), i;
        for (i = 0; i < chks.length; ++i) {
            chks[i].removeEventListener("change", this.updateFavCounter);
            chks[i].addEventListener("change", this.updateFavCounter);
        }
    }

    private changeFavStatus(isFavourite: boolean, button: Element, reverse?: boolean) {
        if (reverse) {
            isFavourite = !(button.getAttribute("data-favourite-current-status") === "true");
        }

        if (isFavourite) {
            button.classList.add("active");

            const chk = document.querySelector("[name='Tableros[0].HasProperty']");

            if (chk) chk.setAttribute("checked", "checked");
        } else {
            button.classList.remove("active");
        }

        Ficha.checkInitializeComments();
        
        button.setAttribute("data-favourite-current-status", isFavourite.toString());
    }

    private clickFavBtnInner(btn: Element) {
        Common.showLogin(() => this.initializePopup(btn));
    }

    private clickFavBtn(e: Event) {
        let btn = e.target as Element;
        if (!btn.hasAttribute("data-favourite-button")) {
            if (btn.firstElementChild != null && btn.firstElementChild.hasAttribute("data-favourite-button")) //Firefox
                btn = btn.firstElementChild;
            else
                return;
        }
        
        e.preventDefault();
        this.clickFavBtnInner(btn);
    }

    private initializePopup(btn: Element) {
        this.favouritePopup = new Popup("favouritePopup");
        this.boardStickyAddMemberPopup = new Popup("boardStickyAddMemberPopup");
        this.favouritePopup.show();

        let favouritePopupEventDelegate = (type: PopupEvents, data: any) => {

            if (type == PopupEvents.BeforeSubmit) {
                const checks = document.querySelectorAll<HTMLInputElement>(".chkBoard");
                const myFavourites = checks[0];

                this.changeFavStatus(myFavourites.checked, btn);
            }

            if (type == PopupEvents.Success) {
                const chks = document.querySelectorAll(".chkBoard");
                const boardId = chks[chks.length - 1].getAttribute("board");

                this.favouritePopup.close();

                let inputTableroNuevo = document.getElementById("NombreTableroNuevo") as HTMLInputElement;
                if (inputTableroNuevo) inputTableroNuevo.value = "";
                
                if (document.getElementById('showBoardStickyAddMember') &&
                    (document.getElementById('showBoardStickyAddMember') as HTMLInputElement).value == "true") {

                    // En el listing hay 20 botones de favorito
                    const idFavorito = btn.getAttribute("data-favourite-button-id");
                    const stickyPos = btn.getAttribute("data-sticky-position");

                    if (stickyPos) {
                        switch (stickyPos) {
                            case "right":
                                this.boardStickyAddMemberPopup.stickyPosition = StickyPosition.Right;
                                break;
                            default:
                                this.boardStickyAddMemberPopup.stickyPosition = StickyPosition.Bottom;
                                break;
                        }
                    }

                    this.boardStickyAddMemberPopup.stickySelector = `[data-favourite-button-id="${idFavorito}"]`;
                    
                    (<HTMLInputElement>document.getElementById('addMemberIdTablero')).value = boardId;

                    this.boardStickyAddMemberPopup.show(false);
                }
            }
            if (type == PopupEvents.Close) {
                if (!document.querySelector("#propiedad-comentarios")) {
                    (document.querySelector('#favouritePopup .popup-content form') as HTMLElement).innerHTML = '<div class="text-center"><i class="fa fa-refresh fa-spin fa-2x fa-fw"></i></div>';
                }
            }
        }

        let boardStickyAddMemberPopupEventDelegate = (type: PopupEvents, data: any) => {
            if (type == PopupEvents.BeforeSubmit)
                this.boardStickyAddMemberPopup.close();
        }

        //if (!document.querySelector("#propiedad-comentarios")) {
            //No estoy en ficha

            let idAviso = btn.getAttribute("data-favourite-button-id");
            let url = btn.getAttribute("data-favourite-boards-url") + "?idAviso=" + idAviso;

            fetch(url, {
                method: "GET",
                credentials: "same-origin"
            }).then(async response => {
                if (response.ok) {
                    let popupFormEle = document.querySelector('#favouritePopup .popup-content form') as HTMLFormElement;

                    popupFormEle.innerHTML = await response.text();
                    MaterialControl.initialize(popupFormEle);

                    this.initializePopupButtons();
                    document.querySelector("#favouritePopup [data-popup-close]").addEventListener("click", e => {
                        this.favouritePopup.close();
                    })
                }

            });
        //} else {
        //    this.initializePopupButtons();
        //}

        if (document.querySelector(".btn-add-member-close")) {
            document.querySelector(".btn-add-member-close").addEventListener("click", e => {
                this.boardStickyAddMemberPopup.close();
            })
        }

        this.favouritePopup.event((t, e, d) => favouritePopupEventDelegate(t, d));
        this.boardStickyAddMemberPopup.event((t, e, d) => boardStickyAddMemberPopupEventDelegate(t, d));
    }

    private initializePopupButtons() {
        if (!this.createBoardEle || !this.closeBoardEle) return;

        this.createBoardEle.addEventListener("click", e => {
            e.preventDefault();

            this.createBoardBoxEditMode = true;

            this.boardNameEle.value = "";
            this.boardNameEle.focus();
        });

        this.closeBoardEle.addEventListener("click", e => {
            e.preventDefault();

            this.createBoardBoxEditMode = false;
        });
    }

    private updateFavCounter(e: Event) {
        let chk = e.target as HTMLInputElement;

        let id = chk.getAttribute('board');
        let counter = document.getElementById('boardCount-' + id);
        let count = parseInt(counter.getAttribute("data-count"));

        if (!chk.checked && count == 0) return;

        chk.checked ? count++ : count--;

        counter.setAttribute("data-count", count.toString());
        counter.textContent = `(${count})`;
    }
}