import { MapDrawLayer } from "./map-draw-layer";

const PADDING_COORDINATES = 50;

export class Map {
    private url: string;
    private attribution: string;
    private leafMap: L.Map;
    private marker: L.Marker;
    private defaultLatLng: L.LatLngExpression = [0, 0];
    
    private enableInteractive: boolean;
    private innerAvailable: boolean;
    private zoomControl: L.Control;
    private markerDragEndFn: (lat: number, lng: number) => void;
    private markerGroup: L.FeatureGroup;
    private drawLayer: MapDrawLayer;
    private innerEnableDraw: boolean = false;
    private innerDrawEnd: (points: L.LatLng[]) => void;

    maxZoom = 18;
    minZoom = 2;
    zoomPosition: L.ControlPosition = "bottomright";

    constructor(public container: Element) {
        this.url = container.getAttribute("data-url");
        this.attribution = `${container.getAttribute("data-attribution")} <a href='http://openstreetmap.org'>OpenStreetMap</a>`;
    }

    init(): void {
        this.leafMap = L.map(this.container as HTMLElement, {
            zoomControl: false,
            attributionControl: false
        });

        this.leafMap.setView(this.defaultLatLng, this.minZoom);

        let tileLayer = L.tileLayer(this.url, {
            minZoom: this.minZoom,
            maxZoom: this.maxZoom
        });

        this.leafMap.addLayer(tileLayer);
        L.control.attribution({ prefix: this.attribution }).addTo(this.leafMap );
    }

    addControl(control: L.Control): L.Map {
        return this.leafMap.addControl(control);
    }

    removeControl(control: L.Control): L.Map {
        return this.leafMap.removeControl(control);
    }

    addMassiveMarkers(markers: L.Marker[]): void {
        if (this.markerGroup) {
            this.leafMap.removeLayer(this.markerGroup);
        }
        
        this.markerGroup = L.featureGroup(markers);
        this.markerGroup.addTo(this.leafMap);
    }

    invalidateSize(): void {
        this.leafMap.invalidateSize();
    }

    clearMassiveMarkers(): void {
        if (!this.markerGroup) return;

        this.leafMap.removeLayer(this.markerGroup);
        this.markerGroup = null;
    }

    setMarker(latitude: number, longitude: number, draggable: boolean = false): void {
        if (this.marker) {
            this.marker.setLatLng([latitude, longitude]);
        } else {
            let markerHtml = L.DomUtil.create("div", "");

            let markerOpts: L.MarkerOptions = {
                icon: L.divIcon({
                    className: "simple-map-marker leaflet-zoom-animated",
                    html: markerHtml.outerHTML
                })
            };

            this.marker = L.marker([latitude, longitude], markerOpts);
            this.marker.addTo(this.leafMap);
        }

        if (draggable) {
            this.marker.dragging.enable();

            this.marker.on("dragend", e => {
                if (this.markerDragEndFn) {
                    let marker = e.target as L.Marker;
                    let latlng = marker.getLatLng();

                    this.markerDragEndFn(latlng.lat, latlng.lng);
                }
            });
        } else {
            this.marker.dragging.disable();
        }
    }

    removeMarker(): void {
        if (this.marker) {
            this.leafMap.removeLayer(this.marker);
            this.marker = null;
        }
    }

    setView(latitude: number, longitude: number, zoom: number = 18): void {
        this.leafMap.setView([latitude, longitude], this.maxZoom);
    }

    setViewToMarkerBounds(): void {
        if (this.markerGroup) {
            let bounds = this.markerGroup.getBounds();

            if (bounds.isValid())
                this.leafMap.fitBounds(bounds);
        }
    }

    setViewByDensity(): void {
        if (!this.markerGroup) return;

        let values: number[][] = [];

        this.markerGroup.eachLayer(layer => {
            let marker = <L.Marker> layer;
            let latlng = marker.getLatLng();

            values.push([latlng.lat, latlng.lng]);
        });

        if (values.length == 1) {
            let value = values[0];
            this.setView(value[0], value[1]);

            return;
        }

        let dbscan = new DBSCAN();
        let clusters = dbscan.run(values, .02, 2);
        let maxCluster = [];

        for (let cluster of clusters) {
            if (cluster.length > maxCluster.length) {
                maxCluster = cluster;
            }
        }

        let minLat, maxLat, minLng, maxLng;

        for (let index of maxCluster) {
            let value = values[index];
            let lat = value[0];
            let lng = value[1];

            if (!minLat || minLat > lat) minLat = lat;
            if (!maxLat || maxLat < lat) maxLat = lat;

            if (!minLng || minLng > lng) minLng = lng;
            if (!maxLng || maxLng < lng) maxLng = lng;
        }

        let bounds = L.latLngBounds([minLat, minLng], [maxLat, maxLng]); // SW, NE

        this.leafMap.fitBounds(bounds);
    }

    onMoveEnd(fn: (e: L.Event) => void) {
        this.leafMap.on("moveend", fn);
    }

    onPopupOpen(fn: (e: L.Event) => void) {
        this.leafMap.on("popupopen", fn);
    }

    onPopupClose(fn: (e: L.Event) => void) {
        this.leafMap.on("popupclose", fn);
    }

    onMarkerDragEnd(fn: (lat: number, lng: number) => void) {
        this.markerDragEndFn = fn;
    }

    onDrawEnd(fn: (points: L.LatLng[]) => void) {
        this.innerDrawEnd = fn;
    }

    set enableDraw(value: boolean) {
        this.innerEnableDraw = value;

        if (value) {
            if (!this.drawLayer) {
                this.drawLayer = new MapDrawLayer(this.leafMap);
            }

            this.drawLayer.init();

            this.drawLayer.onEnd = points => {
                if (this.innerDrawEnd) this.innerDrawEnd(points);
            };
        } else {
            this.drawLayer.destroy();
        }
    }

    get enableDraw(): boolean {
        return this.innerEnableDraw;
    }

    set enable(value: boolean) {
        let events = ["dragging", "touchZoom", "doubleClickZoom", "scrollWheelZoom", "boxZoom", "keyboard"];

        this.enableInteractive = value;

        events.forEach(eventName => {
            let fn = this.leafMap[eventName];

            if (value) {
                fn.enable();
            } else {
                fn.disable();
            }
        });

        this.enableZoom = value;
    }

    get enable(): boolean {
        return this.enableInteractive;
    }
    
    set enableZoom(value: boolean) {
        if (value && !this.zoomControl) {
            this.zoomControl = L.control.zoom({
                position: this.zoomPosition
            });

            this.leafMap.addControl(this.zoomControl);
        } else if (!value && this.zoomControl) {
            this.leafMap.removeControl(this.zoomControl)
            this.zoomControl = null;
        }
    }

    get enableZoom(): boolean {
        return this.zoomControl != null;
    }

    set available(value: boolean) {
        this.innerAvailable = value;

        if (value) {
            this.container.classList.remove("map-not-available");
            this.enable = true;
        } else {
            this.container.classList.add("map-not-available");
            this.enable = false;
        }
    }

    get available(): boolean {
        return this.innerAvailable;
    }

    get bounds(): L.LatLng[] {
        let bounds = this.leafMap.getBounds(),
            nw = bounds.getNorthWest().wrap(),
            se = bounds.getSouthEast().wrap();

        /* Agrega un padding interno en el mapa para los puntos.
         * Basicamente crea un rectangulo mas pequeño de los que se esta viendo para evitar que los markers se salgan de la pantalla
         */
        let nwPoint = this.leafMap.latLngToLayerPoint(nw),
            sePoint = this.leafMap.latLngToLayerPoint(se);

        nwPoint.x += PADDING_COORDINATES;
        nwPoint.y += PADDING_COORDINATES;
        
        sePoint.x -= PADDING_COORDINATES;
        sePoint.y -= PADDING_COORDINATES;

        let nePoint = L.point(sePoint.x, nwPoint.y);
        let swPoint = L.point(nwPoint.x, sePoint.y);

        nw = this.leafMap.layerPointToLatLng(nwPoint).wrap();
        se = this.leafMap.layerPointToLatLng(sePoint).wrap();

        let ne = this.leafMap.layerPointToLatLng(nePoint).wrap();
        let sw = this.leafMap.layerPointToLatLng(swPoint).wrap();

        let midUpperPoint = L.point((nwPoint.x + nePoint.x) / 2, nwPoint.y);
        let midDownPoint = L.point(midUpperPoint.x, swPoint.y);

        let midUpper = this.leafMap.layerPointToLatLng(midUpperPoint).wrap();
        let midDown = this.leafMap.layerPointToLatLng(midDownPoint).wrap();

        return [nw, midUpper, ne, se, midDown, sw];
    }
}
