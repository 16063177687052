import { Carousels } from "./common/carousels";
import { ImageLazyies } from "./common/image-lazies";
import { SearchTypeButtons } from "./common/search-type-buttons";
import { MyAccountData } from "./my-account-data";
import { Tabs } from "./common/tabs";
import { Favourites } from "./common/favourites";
import { Maps } from "./common/maps";
import { CascadeDropdown } from "./common/cascade-dropdown";
import { CustomSelect } from "./common/custom-select";
import { NumericUpDown } from "./common/numeric-updown";
import { TabStep } from "./common/tab-step";
import { DisableElement } from "./common/disable-element";
import { AutocompleteInput } from "./common/autocomplete-input";
import { createDomainSuggest } from "./common/domain-suggest";
import { KeyAllow } from "./common/key-allow";
import { Popup } from "./common/popup";
import { MyAccountUser } from "./my-account-user";
import { MyAccountBoards } from "./my-account-boards";
import { MyAccountProperty } from "./my-account-property";
import { ReCaptcha } from "./common/recaptcha";
import { MaterialControl } from "./common/material-control";
import { MessageBoards } from "./my-message-boards";
import { Footer } from "./common/footer";

export class Common {
    static initialize(selector?: string) {
        Carousels.initialize(selector);
        ImageLazyies.initialize(selector);
        SearchTypeButtons.initialize(selector);
        Tabs.initialize(selector);
        TabStep.initialize(selector);
        Favourites.initialize(selector);
        Maps.initialize();
        CascadeDropdown.initialize(selector);
        NumericUpDown.initialize(selector);
        DisableElement.initialize(selector);
        CustomSelect.initialize();
        MyAccountProperty.initialize()
        MyAccountUser.initialize();
        MyAccountData.initialize();
        MyAccountBoards.initialize();
        AutocompleteInput.initialize();
        KeyAllow.initialize(selector);
        //ReCaptcha.initialize();
        MaterialControl.initialize(selector);
        MessageBoards.initialize();
        Footer.initialize();

        this.manageSvgs();
        this.saveLocalStorage("[data-user]", "data-user-name", "userName");
        this.saveLocalStorage("[data-user]", "data-user-phone", "userPhone");
        this.showAlertFromDataTag();
        this.makeLinksClickeable();
        this.initDomainSuggest();        
    }

    static manageSvgs() {
        const svgs = document.querySelectorAll("[data-svg]");

        svgs.forEach(ele => {
            const url = ele.getAttribute("data-svg");

            fetch(url).then(response => {
                if (response.ok) return response.text();
                console.error("Error al cargar el svg", url);
            }).then(data => ele.innerHTML = data)
                .catch(err => console.log(err));
        });
    }

    static initDomainSuggest() {
        const domainSuggestInputs = document.querySelectorAll<HTMLInputElement>("[data-domain-suggest]");
        createDomainSuggest(domainSuggestInputs);

        domainSuggestInputs.forEach(input => input.removeAttribute("data-domain-suggest"));
    }

    static showLogin(fn: () => void) {
        const handleClose = () => {
            document.removeEventListener("closeLogin", handleClose);
            document.removeEventListener("succesLogin", handleSuccess);
            document.removeEventListener("alreadyLogged", handleSuccess);
        };

        const handleSuccess = () => {
            handleClose();

            fn();
        };

        document.addEventListener("closeLogin", handleClose);
        document.addEventListener("succesLogin", handleSuccess);
        document.addEventListener("alreadyLogged", handleSuccess);

        const showLoginEvent = new CustomEvent("showLogin");
        document.dispatchEvent(showLoginEvent);
    }

    private static makeLinksClickeable()
    {
        $("[data-link-href]").each(function () 
        {
            var me = $(this);
            var href = me.data("link-href");
            var target = me.attr("target");

            me.on('click', function () 
            {             
                if (target && target == "_blank") 
                {
                    window.open(href);
                }
                else 
                {
                    location.href = href;
                }                
            });
        });
    }

    private static showAlertFromDataTag() {
        let alert = document.querySelector("[data-alert]");
        if (alert) {
            let title = alert.getAttribute("data-alert-title");
            let message = alert.getAttribute("data-alert-message");

            Popup.showAlert(title, message);
            
            alert.parentElement.removeChild(alert);
        }
    }
    
    static reloadFormValidator(form?: HTMLFormElement) {
        if (form) {
            $.validator.unobtrusive.parse(form);
            return;
        }

        let forms = document.querySelectorAll("[data-form-ajax]");

        for (var i = 0; i < forms.length; i++) {
            let form = forms[i];

            $.validator.unobtrusive.parse(form);
        }
    }
    
    static validateForm(form: HTMLElement): boolean {
        let validationInfo = $(form).data("unobtrusiveValidation");

        return !validationInfo || !validationInfo.validate || validationInfo.validate();
    }

    static get isMobile(): boolean {
        return navigator.userAgent.indexOf("Mobi") != -1;
    }

    static createEvent(eventName: string): Event {
        if (typeof (Event) === 'function') {
            return new Event(eventName, { bubbles: true, cancelable: true });
        } else {
            var event = document.createEvent('Event');
            event.initEvent(eventName, true, true);
            return event;    
        }
    }
    
    static createElementFromTemplate(selector: string): DocumentFragment {
        let template = document.querySelector(selector);

        // Esto es una negrada simplemente porque no esta la propiedad "content"
        // como DocumentFragment en los typings
        let eleAny = template as any;
        let fragment = eleAny.content as DocumentFragment

        return document.importNode(fragment, true) as DocumentFragment;
    }

    public static ToggleDelete(idAviso: number, idVisibilidad: number, fn: (result: ToggleDeleteResponse) => void) {
        let url = document.body.getAttribute("data-ignorado-url")

        let sp = new URLSearchParams();

        sp.append("idAviso", idAviso.toString());
        sp.append("idVisibilidad", idVisibilidad.toString());
        
        const params = sp.toString(); 

        url = `${url}?${params}`;

        fetch(url, {
            method: "POST"
        }).then<ToggleDeleteResponse>(response => {
            if (response.ok) return response.json();
        }).then(data => fn(data));
    }
    
    public static saveLocalStorage(dataAttribute: string, attribute: string, key: string) {
        let value = document.querySelector(dataAttribute).getAttribute(attribute);

        if (value) {
            localStorage.setItem(key, value);
        }
    }

    public static saveLocalStorageFromValue(id: string, key: string) {
        let value = (<HTMLInputElement>document.getElementById(id)).value;        

        if (value) {
            localStorage.setItem(key, value);
        }
    }
    public static getKeyToLocalStorage(key: string) {
        return localStorage.getItem(key);
    }
    
    public static setCookie(key: string, id: string, exdays: number = 365)
    {
        let value = (<HTMLInputElement>document.getElementById(id)).value;

        if (value) {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = key + "=" + value + ";" + expires + ";path=/";
        }
    }
}

export class ToggleDeleteResponse {
    Add: boolean;
    Delete: boolean;
    IsAuthenticated: boolean;
}

export class LoginResponse {
    isAuthenticated: boolean;
    returnUrl: string;
    errorMsg: string;
    returnCallback: () => void;
}

export class RegisterResponse {
    isRegistered: boolean;
    usuario: Usuario;
    errorMsg: string;  
}

export class Usuario {
    Username: string;
    Nombre: string;
    Telefono: string;
}
