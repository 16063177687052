
import { Common } from "../common";

export class CustomSelect {
    private container: Element;
    private customSelect: Element;
    private customSelectSpan: HTMLSpanElement;
    private customOptions: HTMLOListElement;

    static initialize() {
        let selects = document.querySelectorAll("[data-custom-select]");

        for (var i = 0; i < selects.length; i++) {
            let select = selects[i];
            let nextSibling = select.nextElementSibling;

            if (nextSibling && nextSibling.classList.contains("custom-select-container"))
                continue;

            let cs = new CustomSelect(select);
            cs.init();
        }
    }

    constructor(private ele: Element) {
    }

    init() {
        if (!Common.isMobile) {
            this.createCustomSelect();
            this.events();
        } else {
            let parent = this.ele.parentElement;

            if (!parent.classList.contains("select-wrapper")) {
                let wrapper = document.createElement("div");
                wrapper.classList.add("select-wrapper");

                this.wrap(this.ele, wrapper);
            }
        }
    }

    private wrap(el: Element, wrapper: Element) {
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
    }

    private events(): void {
        let labelFor: Element;
        
        if (this.select.id) {
            labelFor = document.querySelector(`label[for=${this.select.id}]`);
        }
        
        if (labelFor) {
            labelFor.addEventListener("click", e => {
                if (this.disabled) return;
                
                e.preventDefault();
                e.stopPropagation();

                this.hideAllMenus();

                this.customSelect && (this.customSelect as HTMLElement).focus();
            });
        }

        document.addEventListener("click", e => {
            let target = e.target as HTMLElement;
            if (target != this.customSelect) this.visibleMenu = false;
        });

        this.customSelect.addEventListener("focus", () => this.hideAllMenus());

        this.customSelect.addEventListener("click", () => {
            if (this.disabled) return;

            this.visibleMenu = !this.visibleMenu;
        });

        this.customSelect.addEventListener("keydown", (e: KeyboardEvent) => {
            switch (e.which) {
                case 37: // Izquierda
                case 38: // Up
                    e.preventDefault();
                    this.moveUp(e);

                    break;
                case 39: // Derecha
                case 40: // Abajo
                    e.preventDefault();
                    this.moveDown(e);

                    break;
                case 9:  // Tab
                case 13: // Enter
                    if (this.visibleMenu) {
                        if (this.selectOriginal.value != this.selectCustom) {
                            let option = this.getOptionByValue(this.selectCustom);

                            this.selectOriginal = option;
                            this.dispatchChangeEvent();
                        }
                        
                        this.visibleMenu = false;
                    }
                    
                    break;
                case 27: // Esc
                    if (this.selectOriginal.value != this.selectCustom) {
                        this.text = this.selectOriginal.text;
                        this.selectCustom = this.selectOriginal.value;
                    }

                    this.visibleMenu = false;
                    break;
            }
        });
        
        this.customOptions.addEventListener("click", e => {
            let target = e.target as HTMLElement;

            if (target) {
                let value = target.getAttribute("data-value");
                let option = this.getOptionByValue(value);

                if (option) {
                    this.selectOriginal = option;
                    this.selectCustom = option.value;

                    this.visibleMenu = false;
                    this.dispatchChangeEvent();
                }
            }
        });

        // Observo los cambios de atributos y de las opciones
        var observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                let type = mutation.type;
                let target = mutation.target as HTMLElement;

                if (type == "attributes") {
                    let attrName = mutation.attributeName;
                    let value = target.getAttribute(attrName);

                    switch (attrName) {
                        case "disabled":
                            this.disabled = value ? true : false;
                            break;
                        case "class":
                            this.customSelect.className = value;

                            this.customSelect.classList.add("custom-select");
                            this.disabled = this.select.disabled;

                            break;
                        case "selected":
                            this.selectOriginal = target as HTMLOptionElement;
                            this.dispatchChangeEvent();
                            break;
                    }
                }

                if (type == "childList") {
                    this.setCustomOptions();

                    if (this.select.options.length == 0) this.text = "";
                }
            });
        });

        // configuration of the observer:
        var config: MutationObserverInit = { attributes: true, childList: true, characterData: true, subtree: true };

        // pass in the target node, as well as the observer options
        observer.observe(this.select, config);
    }

    private createCustomSelect(): void {
        this.container = document.createElement("div");
        this.customSelect = document.createElement("div");
        this.customSelectSpan = document.createElement("span") as HTMLSpanElement;
        this.customOptions = document.createElement("ol") as HTMLOListElement;

        this.container.classList.add("custom-select-container");

        this.customSelect.className = this.select.className;
        this.customSelect.classList.add("custom-select");

        this.customSelect.appendChild(this.customSelectSpan);
        this.container.appendChild(this.customSelect);
        this.container.appendChild(this.customOptions);

        this.setCustomOptions();
        
        /* Esto es porque jquery validation no agrega las clases de estilo
         * de error cuando el elemento es display: none
         */
        this.select.style.visibility = "hidden";
        this.select.style.position = "absolute";
        this.select.style.width = "auto";

        this.select.insertAdjacentElement("afterend", this.container);

        this.disabled = this.select.disabled;
    }

    private setCustomOptions(): void{
        this.customOptions.innerHTML = "";
        
        for (var i = 0; i < this.select.options.length; i++) {
            let option = this.select.options[i] as HTMLOptionElement;
            let li = document.createElement("li");
            let span = document.createElement("span");

            span.innerText = option.text;
            span.setAttribute("data-value", option.value);
            
            li.appendChild(span);
            this.customOptions.appendChild(li);

            if (option.selected) {
                this.selectOriginal = option;
                this.selectCustom = option.value;
            }
        }

        if (this.select.options.length == 0) {
            this.selectOriginal = null;
            this.selectCustom = null;
        }
    }

    private getOptionByValue(value: string): HTMLOptionElement {
        for (var i = 0; i < this.select.options.length; i++) {
            let option = this.select.options[i] as HTMLOptionElement;

            if (option.value == value) return option;
        }

        return null;
    }

    private dispatchChangeEvent(): void {
        // Solo para jquery validation
        this.select.click();
        
        let changeEvent = Common.createEvent("change");
        this.select.dispatchEvent(changeEvent);
    }

    private moveUp(e: KeyboardEvent): void {
        if (e.altKey) {
            this.visibleMenu = !this.visibleMenu;
            return;
        }

        let selected = this.selectCustom;
        let index = this.getOptionByValue(selected).index - 1;

        if (index < 0) index = 0;

        let next = this.select.options.item(index);

        this.selectCustom = next.value;

        if (this.visibleMenu) {
            this.text = next.text;
        } else {
            this.selectOriginal = next;
            this.dispatchChangeEvent();
        }
    }

    private moveDown(e: KeyboardEvent): void {
        if (e.altKey) {
            this.visibleMenu = !this.visibleMenu;
            return;
        }

        let count = this.select.options.length;
        let selected = this.selectCustom;
        let index = this.getOptionByValue(selected).index + 1;

        if (index > count - 1) index = count - 1;

        let next = this.select.options.item(index);

        this.selectCustom = next.value;

        if (this.visibleMenu) {
            this.text = next.text;
        } else {
            this.selectOriginal = next;
            this.dispatchChangeEvent();
        }
    }
    
    private set selectOriginal(option: HTMLOptionElement) {
        for (var i = 0; i < this.select.selectedOptions.length; i++) {
            let item = this.select.selectedOptions[i] as HTMLOptionElement;
            item.selected = false;
        }

        if (option) {
            this.text = option.text;
            this.select.selectedIndex = option.index;
        } else {
            this.text = "";
        }
    }

    private get selectOriginal(): HTMLOptionElement {
        if (this.select.selectedOptions.length > 0) {
            return this.select.selectedOptions[0] as HTMLOptionElement;
        }

        return null;
    }

    private set selectCustom(value: string) {
        let lis = this.customOptions.children;
        
        for (var i = 0; i < lis.length; i++) {
            let item = lis[i].firstElementChild as HTMLSpanElement;
            let dataValue = item.getAttribute("data-value");
            
            if (value == dataValue) {
                item.classList.add("custom-selected");
                this.scrollIntoView(item);
            } else {
                item.classList.remove("custom-selected");
            }
        }
    }

    private get selectCustom(): string {
        let item = this.selectCustomItem;

        return item ? item.getAttribute("data-value") : null;
    }

    private get selectCustomItem(): HTMLSpanElement {
        let lis = this.customOptions.children;

        for (var i = 0; i < lis.length; i++) {
            let item = lis[i].firstElementChild as HTMLSpanElement;

            if (item.classList.contains("custom-selected")) {
                return item;
            }
        }

        return null;
    }

    private scrollIntoView(ele: HTMLElement): void {
        let scrollTop = this.customOptions.scrollTop;
        let height = this.customOptions.offsetHeight;
        let itemTop = ele.offsetTop;
        let itemHeight = ele.offsetHeight;
        
        if (itemTop + itemHeight >= scrollTop + height) {
            let diff = (itemTop + itemHeight) - height;
            this.customOptions.scrollTop = diff;
        }

        if (itemTop <= scrollTop) 
            this.customOptions.scrollTop = itemTop;

    }

    private hideAllMenus(): void {
        let menus = document.querySelectorAll(".custom-select-container ol");

        for (var i = 0; i < menus.length; i++) {
            let menu = menus[i] as HTMLElement;

            menu.style.display = "none";
        }

        this.container.classList.remove("custom-select-opened");
    }

    private set enableFocus(value: boolean) {
        if (value) {
            this.customSelect.setAttribute("tabindex", "0");
        } else {
            this.customSelect.removeAttribute("tabindex");
        }
    }

    private get enableFocus(): boolean {
        return this.customSelect.hasAttribute("tabindex");
    }

    private set disabled(value: boolean) {
        if (value) {
            this.customSelect.classList.add("custom-select-disabled");
            this.visibleMenu = false;
            this.enableFocus = false;
        } else {
            this.enableFocus = true;
            this.customSelect.classList.remove("custom-select-disabled");
        }
    }

    private get disabled(): boolean {
        return this.customSelect.classList.contains("custom-select-disabled");
    }
    
    private set visibleMenu(value: boolean) {
        this.customOptions.style.display = value ? "block" : "none";

        if (value) {
            this.container.classList.add("custom-select-opened");
        } else {
            this.container.classList.remove("custom-select-opened");
        }
        
        if (value && this.selectCustomItem) {
            this.scrollIntoView(this.selectCustomItem);
        }
    }

    private get visibleMenu(): boolean {
        return this.customOptions.style.display == "block";
    }

    private set text(value: string) {
        if (!value || value == "") {
            this.customSelectSpan.innerHTML = "&nbsp;";
        } else {
            this.customSelectSpan.textContent = value;
        }
    }

    private get text(): string {
        return this.customSelectSpan.textContent;
    }

    private get select(): HTMLSelectElement {
        return this.ele as HTMLSelectElement;
    }
}
