import { Popup } from "./common/popup";
import { Notifications, NotificationType } from "./notifications";


export class MyAccountUser {
    static tabs: HTMLElement;
    static container: HTMLElement;

    public static initialize() {
        this.container = document.querySelector<HTMLElement>("#my-account-content");
        const page = document.querySelector<HTMLElement>("[data-user-info-page]");

        if (!page) return;

        this.tabs = this.container.querySelector<HTMLElement>("[data-tabs]");

        if (!this.container.hasAttribute("user-binds")) {
            document.addEventListener("click", (e: Event) => {
                const target = e.target as HTMLElement;

                if (target.hasAttribute("data-delete-account"))
                    this.deleteAccount(target);

                const callWhenAttr = (attrs: string[], fn: () => void) => {
                    const has = attrs.some(attr => target.hasAttribute(attr));
                    if (!has) return;

                    e.preventDefault();
                    fn();
                };

                const tabs = [
                    "data-tab-datos",
                    "data-tab-anunciante",
                    "data-tab-notificaciones",
                    "data-tab-privacidad"
                ];

                callWhenAttr(tabs, () => this.handleTabClick(target as HTMLButtonElement));
            })
        }

        this.container.setAttribute("user-binds", "false");
    }

    private static deleteAccount(target: HTMLElement) {
        const title = target.getAttribute("data-delete-title");
        const url = target.getAttribute("data-delete-account");
        const message = target.getAttribute("data-delete-message");        

        Popup.showCancelConfirm(title, message, "Eliminar", () => {
            const loader = document.querySelector("#privacy-loader");
            target.classList.toggle("hide");
            loader.classList.toggle("hide");

            const userInfoDiv = document.querySelector('.user-info') as HTMLElement;
            if (userInfoDiv)
                userInfoDiv.classList.add('disabled');
                       
            fetch(url, {
                    method: "POST",
                    credentials: "same-origin"
                })
                .then(response => {
                    if (response.ok) {
                        const msg = target.getAttribute("data-delete-success");
                        Notifications.show(msg, NotificationType.Success);
                        setTimeout(() => {
                            document.location.href = "/";
                        }, 3000);                     
                    }                      
                    else {
                        const msg = target.getAttribute("data-delete-error");
                        Notifications.show(msg, NotificationType.Danger);
                        target.classList.toggle("hide");
                        loader.classList.toggle("hide");  
                    }
                }) 
        })
    }

    private static handleTabClick(target: HTMLButtonElement) {
        const tab = target.getAttributeNames()
            .find(x => x.startsWith("data-tab"));

        this.setActiveTab(tab)

        const dataUrl = target.getAttribute("data-url");
        window.history.pushState({ activeTab: tab }, null, dataUrl);        
    };

    private static setActiveTab(id: string) {
        const activeBox = this.tabs.querySelector<HTMLElement>(".active");
        const stepBox = activeBox.querySelector(".step-box");

        activeBox.classList.remove("active"); 
        const tab = this.tabs.querySelector<HTMLElement>(`[${id}]`);
        tab.parentElement.classList.add("active");

        const current = document.querySelector<HTMLElement>(`#${id}`);
        const content = document.querySelectorAll(`[data-info-content]`);
        content.forEach(c => c.classList.add("hide"));        

        current.classList.remove("hide");
    };
}