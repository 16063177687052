

export class BackgroundStyle {
    static initialize() {
        this.inlineCss();
        this.parallaxBg();
        this.setAnchor();
        this.setParallax();

        $(window).resize(this.fullscreenFix);
        this.fullscreenFix();


        $(window).resize(this.backgroundResize);
        $(window).focus(this.backgroundResize);
        this.backgroundResize();

        if (!$("html").hasClass("touch")) {
            $(window).resize(this.parallaxPosition);
            $(window).scroll(this.parallaxPosition);
            this.parallaxPosition();
        }
    }

    private static inlineCss() {
        // Common Inline CSS
        $(".some-classes, section.fullwidth, .img-box-background,.property-slider .item, .fullwidth-property-slider .item, .fullwidth-home-slider .item, .address-container").each(function () {
            const me = $(this);
            const attrImageBg = me.attr("data-background-image");
            const attrColorBg = me.attr("data-background-color");
            if (attrImageBg) {
                me.css("background-image", `url(${attrImageBg})`);
            }
            if (attrColorBg) {
                me.css("background", `${attrColorBg}`);
            }
        });
    }
    private static parallaxBg() {
        $(".parallax").prepend('<div class="parallax-overlay"></div>');
        $(".parallax").each(function () {
            const me = $(this);
            const attrImage = me.attr("data-background");
            const attrColor = me.attr("data-color");
            const attrOpacity = me.attr("data-color-opacity");
            if (attrImage) {
                me.css("background-image", `url(${attrImage})`);
            }
            if (attrColor) {
                me.find(".parallax-overlay").css("background-color", `${attrColor}`);
            }
            if (attrOpacity) {
                me.find(".parallax-overlay").css("opacity", `${attrOpacity}`);
            }
        });
    }

    private static setAnchor() {
        // Slide to anchor
        $("#titlebar .listing-address").on("click",
            function (e) {
                e.preventDefault();
                $("html, body").animate({
                    scrollTop: $($(this).attr("href")).offset().top - 40
                }, 600);
            });
    }

    /*----------------------------------------------------*/
    /*  Parallax
    /*----------------------------------------------------*/

    private static setParallax() {
        /* detect touch */
        if ("ontouchstart" in window) {
            document.documentElement.className = document.documentElement.className + " touch";
        }
        if (!$("html").hasClass("touch")) {
            /* background fix */
            $(".parallax").css("background-attachment", "fixed");
        }
    }

    /* fix vertical when not overflow
    call fullscreenFix() if .fullscreen content changes */
    private static fullscreenFix() {
        var h = $("body").height();
        // set .fullscreen height
        $(".content-b").each(function () {
            const me = $(this);
            if (me.innerHeight() > h) {
                me.closest(".fullscreen").addClass("overflow");
            }
        });
    }

    /* resize background images */
    private static backgroundResize() {
        var windowH = $(window).height();
        $(".parallax").each(function (i) {
            const path = $(this);
            // variables
            const contW = path.width();
            const contH = path.height();
            var imgW = parseInt(path.attr("data-img-width"));
            var imgH = parseInt(path.attr("data-img-height"));
            const ratio = imgW / imgH;

            // overflowing difference
            var diff = 100;
            diff = diff ? diff : 0;
            // remaining height to have fullscreen image only on parallax
            var remainingH = 0;
            if (path.hasClass("parallax") && !$("html").hasClass("touch")) {
                //var maxH = contH > windowH ? contH : windowH;
                remainingH = windowH - contH;
            }
            // set img values depending on cont
            imgH = contH + remainingH + diff;
            imgW = imgH * ratio;
            // fix when too large
            if (contW > imgW) {
                imgW = contW;
                imgH = imgW / ratio;
            }
            //
            path.data("resized-imgW", imgW);
            path.data("resized-imgH", imgH);
            path.css("background-size", imgW + "px " + imgH + "px");
        });
    }
    /* set parallax background-position */
    private static parallaxPosition() {
        var heightWindow = $(window).height();
        var topWindow = $(window).scrollTop();
        var bottomWindow = topWindow + heightWindow;
        var currentWindow = (topWindow + bottomWindow) / 2;
        $(".parallax").each(function (i) {
            const path = $(this);
            const height = path.height();
            var top = path.offset().top;
            var bottom = top + height;
            // only when in range
            if (bottomWindow > top && topWindow < bottom) {
                //var imgW = path.data("resized-imgW");
                const imgH = path.data("resized-imgH");
                // min when image touch top of window
                const min = 0;
                // max when image touch bottom of window
                const max = -imgH + heightWindow;
                // overflow changes parallax
                const overflowH = height < heightWindow ? imgH - height : imgH - heightWindow; // fix height on overflow
                top = top - overflowH;
                bottom = bottom + overflowH;
                // value with linear interpolation
                // var value = min + (max - min) * (currentWindow - top) / (bottom - top);
                let value = 0;
                if ($(".parallax").is(".titlebar")) {
                    value = min + (max - min) * (currentWindow - top) / (bottom - top) * 2;
                }
                else {
                    value = min + (max - min) * (currentWindow - top) / (bottom - top);
                }
                // set background-position
                let orizontalPosition = path.attr("data-oriz-pos");
                orizontalPosition = orizontalPosition ? orizontalPosition : "50%";
                $(this).css("background-position", orizontalPosition + " " + value + "px");
            }
        });
    }
}
